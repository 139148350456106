import React from "react";
import { DefaultEditor } from "react-simple-wysiwyg";
import Button from "@mui/material/Button";
import HttpService from "../../services/HttpService";
import * as processHtml from "../../services/processHtml";
import AuthService from "../../services/AuthService";
import CustomModal from "./CustomModal";
import PromptLogin from "./PromptLogin";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  AddModerator,
  Close,
  MailOutlined,
  MailOutlineOutlined,
  ReplyAll,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import FindUser from "./FindUser";
import CustomToast from "./CustomToast";

const AddMember = (props: any) => {
  const { feed_back, platform, button_text, group_id, class_style } = props;
  console.log("add_member::", props);
  const [member_added, setMemberAdded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [meta, setMeta] = React.useState<any>({});
  const cur_user = AuthService.getCurrentUser();
  const [user_logged, setUserLogged] = React.useState(false);
  React.useEffect(() => {
    if (cur_user) {
      setUserLogged(true);
    }
  }, [cur_user]);
  React.useEffect(() => {
    if (platform) {
      setMeta({ ...meta, platform: platform });
    }
    if (group_id) {
      setMeta({ ...meta, group_id: group_id });
    }
  }, [platform, group_id]);
  React.useEffect(() => {
    if (meta?.member) {
      setMemberAdded(true);
    }
  }, [meta]);

  const { decodeHtml, truncateWord } = processHtml;
  const onToastClose = () => {
    setToast({ onopen: false, onclose: onToastClose });
  };
  const [toast, setToast] = React.useState<any>({
    onopen: false,
    onclose: onToastClose,
    message: "",
  });
  const handleInputChange = React.useCallback(
    (event: any) => {
      const targ = event.target;
      const name = targ.name;
      const value = targ.value;
      console.log(name, value);
      setMeta({ ...meta, [name]: value });
      console.log(meta);
    },
    [meta]
  );
  const [content, setContent] = React.useState<any>(null);
  const onHtmlChange = (e: any) => {
    setContent(e.target.value);
    console.log(content);
  };

  const handleSubmit = (e: any) => {
    console.log("meta::", meta);
    if (!meta.member?.id || !meta.member) {
      setToast({
        ...toast,
        onopen: true,
        onclose: onToastClose,
        severity: "error",
        message: "Please select a member..",
      });
      return;
    }

    setLoading(true);
    setLoaded(false);

    let obj = {
      ...meta,
      summary: content,
      platform: platform,
      group_id: group_id,
    };

    console.log(obj);

    HttpService.postHeader("add_member_to", obj)
      .then(
        (resp) => {
          console.log(resp);
          if (resp.status === 1) {
            togForm();
            setTimeout(() => {
              setToast({ ...toast, onopen: false });
              feed_back(resp.data);
            }, 3000);
          }
          setToast({ ...toast, onopen: true, message: resp.message });
        },
        (error) => {
          setToast({ ...toast, onopen: true, message: error.message });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //postTicket
  };

  const [show_form, setShow] = React.useState<boolean>(false);

  const togForm = (): void => {
    setShow(!show_form);
  };
  const add_member = (data: any) => {
    setMemberAdded(true);
    setMeta({ ...meta, user_id: data.id, member: data });
  };
  return (
    <React.Fragment>
      <Tooltip
        title={button_text && button_text !== "" ? button_text : "Add a member"}
      >
        {button_text && button_text !== "" ? (
          <Button onClick={togForm} sx={class_style}>
            <AddModerator />
            <span className="pl5 sm-hide-inline">{button_text}</span>
          </Button>
        ) : (
          <IconButton onClick={togForm} color="success">
            <AddModerator />
          </IconButton>
        )}
      </Tooltip>

      {cur_user && user_logged && show_form && (
        <Dialog open={show_form}>
          <div className="dialog-header">
            <span className="title-head">
              <AddModerator />
              {button_text && button_text !== "" ? (
                <span className="spacer pl5">{button_text}</span>
              ) : (
                <span className="spacer pl5">Add Member</span>
              )}
            </span>
            <span className="spacer"></span>
            <Link onClick={togForm} to={`#`}>
              <Close />
            </Link>
          </div>
          <DialogContent sx={{ p: "0" }}>
            <div className={`alert-container`}>
              <div className="flex flex-col px20">
                {!member_added && (
                  <div className="px10 pb20 pt10">
                    <FindUser
                      feed_back={add_member}
                      class_style={{ width: "100%", minWidth: "350px" }}
                      button_text="Click to add member "
                      input_text_placeholder="Enter member  name or email address"
                    />
                  </div>
                )}
                {member_added && (
                  <div className="mb10">
                    <div className={`input iconed`}>
                      <label>Name</label>
                      <input
                        type="text"
                        disabled={loading}
                        className="form-control"
                        defaultValue={meta?.member?.name}
                        name="name"
                        onChange={handleInputChange}
                        placeholder={"Member Name"}
                      />
                    </div>{" "}
                    <div className={`input iconed`}>
                      <label>Position</label>
                      <input
                        type="text"
                        disabled={loading}
                        className="form-control"
                        name="position"
                        onChange={handleInputChange}
                        placeholder={"Member Position"}
                      />
                    </div>
                    <div className="flex flex-row-resp align-items-center">
                      <div className={`input spacer flex flex-col flex-grow`}>
                        <label>Start</label>
                        <input
                          type="text"
                          disabled={loading}
                          className="form-control"
                          name="start"
                          onChange={handleInputChange}
                          placeholder={"Start Period"}
                        />
                      </div>
                      <div
                        className={`input spacer ml5 flex flex-col flex-grow`}
                      >
                        <label>End</label>
                        <input
                          type="text"
                          disabled={loading}
                          className="form-control"
                          name="end"
                          onChange={handleInputChange}
                          placeholder={"End Period"}
                        />
                      </div>
                    </div>
                    <div className={`input iconed`}>
                      <label>Currently Active?</label>
                      <select
                        disabled={loading}
                        className="form-control"
                        name="is_active"
                        onChange={handleInputChange}
                        placeholder={"Current?"}
                        defaultValue="0"
                      >
                        <option value="1">Active</option>
                        <option value="0">Not Active</option>
                      </select>
                    </div>
                    <div className="mb10">
                      <DefaultEditor
                        disabled={loading}
                        className="form-control"
                        value={content}
                        placeholder="Summary of position"
                        onChange={onHtmlChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </DialogContent>
          {member_added && (
            <DialogActions className="dialog-actions">
              <div className="flex align-items-center">
                <span className="px10">
                  <Button
                    type="submit"
                    size="medium"
                    variant="contained"
                    disabled={loading}
                    onClick={handleSubmit}
                  >
                    {loading ? "Working..." : " Save Member "}
                  </Button>
                </span>
                <span className="spacer"></span>
                <span className="px20"></span>
              </div>
            </DialogActions>
          )}
        </Dialog>
      )}
      {toast.onopen && <CustomToast data={toast} />}
    </React.Fragment>
  );
};

export default React.memo(AddMember);
