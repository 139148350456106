import {
  Add,
  DeleteForever,
  DeleteOutlineOutlined,
  ReplyAll,
} from "@mui/icons-material";
import { Breadcrumbs, Button, Card, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import SendMessage from "../../templates/SendMessage";
import SendSMS from "../../templates/SendSMS";

const MessagesLayout = (props: any) => {
  const { children, page_data } = props;
  const navigate = useNavigate();

  const launchNew = (data: any) => {
    return navigate(`/account/messages/a/new`);
  };

  const closeReply = () => setReply({ ...reply, onopen: false });
  const [reply, setReply] = React.useState<any>({
    onopen: false,
    onclose: closeReply,
  });
  const launchReplyModal = (data: any) => {
    setReply({ ...reply, onopen: true, onclose: closeReply });
  };
  const deleteAll = (id: any) => {
    alert(id);
  };
  const go_to_mail = (data: any) => {
    return navigate(`/account/messages/p/${data.id}`);
  };
  return (
    <React.Fragment>
      <section className="account-page-container">
        <Card>
          <div className="page-topper">
            <Breadcrumbs>
              <Link to="/account">Dashboard</Link>
              {!page_data?.is_param && <span>Messages</span>}
              {page_data?.is_param && (
                <Link to="/account/messages">Messages</Link>
              )}
            </Breadcrumbs>
            <div className="flex align-items-center flex-row-resp">
              <h1 className="spacer">{page_data?.title}</h1>
              <span className="pxy10">
                {!page_data?.is_compose && (
                  <SendMessage
                    parent={null}
                    mode="new"
                    feed_back={go_to_mail}
                  />
                )}
                {page_data?.is_thread && (
                  <Tooltip title="Delete Conversation">
                    <IconButton onClick={() => deleteAll(page_data?.id)}>
                      <DeleteOutlineOutlined />
                    </IconButton>
                  </Tooltip>
                )}

                {page_data?.is_thread && page_data?.receiver && (
                  <>
                    <SendMessage
                      receiver={page_data?.receiver}
                      parent={page_data?.parent}
                      feed_back={page_data?.feed_back}
                      mode="reply"
                    />
                    <SendSMS receiver={page_data?.receiver} />
                  </>
                )}
              </span>
            </div>
          </div>
          <section className="page-detail-container">{children}</section>
        </Card>
      </section>
    </React.Fragment>
  );
};

export default MessagesLayout;
