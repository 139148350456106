import React from "react";
import { DefaultEditor } from "react-simple-wysiwyg";
import Button from "@mui/material/Button";
import HttpService from "../../services/HttpService";
import * as processHtml from "../../services/processHtml";
import AuthService from "../../services/AuthService";
import CustomModal from "./CustomModal";
import PromptLogin from "./PromptLogin";
import {
  Avatar,
  Card,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AddAPhotoOutlined,
  BrowseGalleryOutlined,
  Close,
  MailOutlineOutlined,
  ReplyAll,
  Search,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import CustomToast from "./CustomToast";
import EditGalleryItem from "./EditGalleryItem";
import ConfirmModal from "./ConfirmModal";

const PopulateGallery = (props: any) => {
  const {
    feed_back,
    item,
    class_style,
    button_text,
    gallery_id,
    is_meeting,
    is_event,
    is_profile,
  } = props;

  const [gallery, setGallery] = React.useState<any>({ photos: [] });
  const [isParam, setParam] = React.useState<any>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [gallery_loaded, setGalLoaded] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [caption, setCaption] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [id, setId] = React.useState<any>(false);
  const [banner_id, setBannerId] = React.useState<any>(false);
  const [preview_image, setPreview] = React.useState<any>(null);
  const [file, setFile] = React.useState<any>(null);
  const [new_file, setNewFile] = React.useState<any>(0);
  const onToastClose = () => {
    setToast({ onopen: false, onclose: onToastClose });
  };
  const [toast, setToast] = React.useState<any>({
    onopen: false,
    onclose: onToastClose,
    message: "",
  });
  const [show_form, setShow] = React.useState<boolean>(false);
  const [meta, setMeta] = React.useState<any>(new FormData());
  React.useEffect(() => {
    console.log("::params::", props);
    if (gallery_id && !loaded && show_form) {
      fetchGallery(gallery_id);
    }
  }, [gallery_id, show_form]);

  const fetchGallery = (id: any) => {
    console.log(id, ":: fetching gallery");
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("gallery_details", { id: id })
      .then(
        (result) => {
          setGalLoaded(true);
          if (result.status === 1) {
            let pix: any[] = [];
            for (let i = 0; i < result.data.photos.length; i++) {
              let fa = result.data.photos[i];
              fa.is_file_changed = false;
              fa.new_file = false;
              fa.fresh = false;
              fa.preview_file =
                process.env.REACT_APP_SERVER_ROOT + result.data.photos[i].src;
              const formData = new FormData();
              fa.form_data = formData;
              pix.push(fa);
            }
            let newOb = { ...result, photos: pix };
            console.log(newOb);
            setGallery(newOb);
            if (!caption) {
              setDescription(result.data.description);
              setCaption(result.data.caption);
              setId(result.data.id);
              setBannerId(result.data.photos[0].id);
              setPreview(
                process.env.REACT_APP_SERVER_ROOT + result.data.photos[0].src
              );
            }
            console.log("gallery:::", gallery);
          } else {
            console.log("gallery list empty:::");
          }
        },
        (error) => {
          setGallery({ photos: [] });
        }
      )
      .finally(() => {
        endHttp();
      }); //fetch
  }; //doAjax

  const handleInputChange = React.useCallback(
    (event: any) => {
      const targ = event.target;
      const name = targ.name;
      const value = targ.value;
      if (name === "title") {
        setCaption(value);
      }
    },
    [gallery]
  );

  const onHtmlChange = (e: any) => {
    setDescription(e.target.value);
    console.log(description);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let formData = new FormData();
    for (let i = 0; i < gallery.photos.length; i++) {
      if (gallery.photos[i]["new_file"]) {
        formData.append("files[]", gallery.photos[i]["form_data"]);
        formData.append("ids[]", gallery.photos[i]["id"]);
        formData.append("gallery_id", gallery.id);
        console.log(gallery.photos[i]["form_data"]);
      }
    }
    formData.append("mode", "populate");
    formData.append("id", id);
    console.log(formData);
    setLoading(true);
    setLoaded(false);
    HttpService.postFormHeader("populate_gallery", formData)
      .then(
        (resp) => {
          console.log(resp);
          setToast({ ...toast, onopen: true, message: resp.message });
          if (resp.status === 1) {
            setTimeout(() => {
              //setToast({ ...toast, onopen: false });
              //navigate(`/admin/gallery/p/${galleryid}`);
            }, 3000);
          }
        },
        (error) => {
          setToast({ ...toast, onopen: true, message: error.message });
        }
      )
      .finally(() => {
        endHttp();
      }); //postTicket
  };

  const handleFileChange = (e: any, index: number) => {
    console.log("changed::: ", e, index);
    let glx = { ...gallery };
    let fname = e.target.name;
    let flx = e.target.files[0];
    flx.item_id = glx["photos"][index]["id"];
    flx.gallery_id = gallery.id;
    glx["photos"][index]["form_data"] = flx;
    glx["photos"][index]["new_file"] = true;
    glx["photos"][index].preview_file = URL.createObjectURL(flx); // Would see a path?
    setGallery({ ...glx });
    //console.log(glx);
  };

  const addMore = () => {
    let glx = { ...gallery };
    const formData = new FormData();
    glx["photos"].push({
      id: 0,
      preview_image: "",
      new_file: false,
      is_file_changed: false,
      form_data: formData,
      fresh: true,
    });
    setGallery({ ...glx });
  };
  const closeEdit = (): void => {
    setEdit({ ...edit, onopen: false });
  };

  const [edit, setEdit] = React.useState<any>({
    modal: { onopen: false, onclose: closeEdit, title: "Edit gallery Item" },
  });

  const editItem = (item: any) => {
    let glx = { ...gallery };
    setEdit({
      ...edit,
      modal: { ...edit.modal, onopen: true },
      ...item,
      gallery_id: id,
    });
  };

  const closeDelModal = () => {
    setDelModal({ ...del_modal, onopen: false });
  };
  const [del_modal, setDelModal] = React.useState<any>({
    onopen: false,
    onclose: closeDelModal,
    title: "Delete gallery item",
  });

  const lauchDelItem = (item: any) => {
    setDelModal({
      ...del_modal,
      id: item.id,
      message: "Are you sure you want to remove this item?",
      onopen: true,
      onclose: closeDelModal,
      onaccept: delete_gallery_item,
    });
  };

  const delete_gallery_item = (item: any) => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("remove_gallery_item", { id: item.id })
      .then(
        (resp) => {
          console.log("del resp::", resp);
          if (resp.status === 1) {
            setToast({ ...toast, onopen: true, message: resp.message });
          }
          setTimeout(() => {
            setDelModal({
              ...del_modal,
              onopen: false,
              onclose: closeDelModal,
            });
            setToast({ ...toast, onopen: false, onclose: onToastClose });
          }, 3000);
        },
        (error) => {
          setToast({ ...toast, onopen: true, message: "Error" });
        }
      )
      .finally(() => {
        endHttp();
      }); //fetch
  };

  const togForm = (): void => {
    setShow(!show_form);
  };

  const endHttp = () => {
    setLoaded(true);
    setLoading(false);
  };

  const startNew = (item: any) => {
    HttpService.postHeader("start_gallery", { item_id: item.id })
      .then(
        (res) => {},
        (error) => {}
      )
      .finally(() => {
        endHttp();
      });
  };
  return (
    <React.Fragment>
      <Tooltip
        title={
          button_text && button_text !== "" ? button_text : "Populate Gallery"
        }
      >
        {button_text && button_text !== "" ? (
          <Button onClick={togForm} sx={class_style}>
            <AddAPhotoOutlined />
            <span className="pl5">{button_text}</span>
          </Button>
        ) : (
          <IconButton onClick={togForm} color="success">
            <AddAPhotoOutlined />
          </IconButton>
        )}
      </Tooltip>
      <Dialog open={show_form} fullWidth>
        <div className="dialog-header">
          <span className="title-head">
            <AddAPhotoOutlined />
            {button_text && button_text !== "" ? (
              <span className="spacer pl5">{button_text}</span>
            ) : (
              <span className="spacer pl5">Populate Gallery</span>
            )}
          </span>
          <span className="spacer"></span>
          <Link onClick={togForm} to={`#`}>
            <Close />
          </Link>
        </div>

        <Divider />
        <DialogContent sx={{ p: "0" }}>
          <div className="alert-container">
            <div className="px10 pb10">
              {gallery_loaded && (
                <Card sx={{ p: "20px", m: "0" }}>
                  <div className="gallery-grid z-high">
                    {gallery?.photos.map((item: any, index: number) => (
                      <div className="gal-pop" key={index}>
                        <span className="qedit">
                          <a onClick={() => editItem(item)}>
                            <i className="fas fa-edit"></i>
                          </a>
                          <a onClick={() => lauchDelItem(item)}>
                            <i className="fas fa-trash"></i>
                          </a>
                        </span>
                        <img src={item.preview_file} alt={`Pic ${index}`} />
                        <div className="pic-sel">
                          <input
                            type="file"
                            name={`pic_num[${index}]`}
                            onChange={(e) => handleFileChange(e, index)}
                          />
                        </div>
                      </div>
                    ))}

                    <div className="gal-pop">
                      <Button size="large" onClick={addMore}>
                        ADD MORE
                      </Button>
                    </div>
                  </div>

                  <div className="pxy10"></div>
                </Card>
              )}

              {(is_meeting || is_event) && item && item?.id && !gallery_id && (
                <div className="gal-pop">
                  <Button size="large" onClick={startNew}>
                    Initiate Gallery
                  </Button>
                </div>
              )}
            </div>
          </div>
        </DialogContent>
        {gallery_loaded && (
          <DialogActions>
            <div className="flex align-items-center">
              <Button
                type="submit"
                size="large"
                variant="contained"
                disabled={loading || gallery?.photos.length < 1}
                onClick={handleSubmit}
              >
                {loading ? "Working..." : " Save "}
              </Button>
            </div>
          </DialogActions>
        )}
      </Dialog>

      {edit.onopen && <EditGalleryItem data={edit} />}
      {del_modal.onopen && <ConfirmModal data={del_modal} loading={loading} />}
      {toast.onopen && <CustomToast data={toast} />}
    </React.Fragment>
  );
};

export default React.memo(PopulateGallery);
