import * as React from "react";
import {
  Routes,
  Route,
  useNavigate,
  Link,
  useLocation,
} from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import AuthService from "../../services/AuthService";
import AccountHeader from "./AccountHeader";
import AccountSidePanel from "./AccountSidePanel";
import Four0FourPane from "../public/Four0Four/Four0FourPane";
import Dashboard from "./Dashboard/Dashboard";
import Messages from "./Messages/Messages";
import Settings from "./Settings/Settings";
import { CircularProgress, CssBaseline, LinearProgress } from "@mui/material";
import "../dashboard.css";
import "../skeleton.css";
import MemberShip from "./MemberShip/MemberShip";
import Events from "./Events/Events";
import Meetings from "./Meetings/Meetings";
import Transactions from "./Transactions/Transactions";
import Financials from "./Financials/Financials";
import Clearance from "./Clearance/Clearance";
import Members from "./Members/Members";
import NonIndigenes from "./NonIndigenes/NonIndigenes";
import Cases from "./Cases/Cases";
import Articles from "./Articles/Articles";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function Account() {
  const [user_logged, setUserLogged] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [to_verify, setToVerify] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [resending, setResending] = React.useState(false);
  const [user_verified, setUserVerified] = React.useState(false);
  const [loading_text, setLoadingText] = React.useState(
    "Please enter the verification code sent your phone or email"
  );
  const [verify_data, setVerifyData] = React.useState<any>({});
  const [checked_session, setChecked] = React.useState<boolean>(false);
  const [session_checked, setSessionChecked] = React.useState(false);
  const usr = AuthService.getCurrentUser();
  let navigate = useNavigate();
  let location = useLocation();
  const [hide_sections, setHideSections] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (location?.state?.hide_sections) {
      setHideSections(true);
    }
  }, [location]);
  React.useEffect(() => {
    AuthService.checkSession().then(
      (res) => {
        setChecked(true);
        if (!res.status || res.status === false) {
          AuthService.logout();
          setRedirect(true);
          setUserVerified(false);
          return navigate("/login");
        } else if (res.status && usr.is_verified === 0) {
          setToVerify(true);
          setVerifyData({ ...verify_data, user: usr.email });
          return navigate("/verify-account", { state: verify_data });
          setUserVerified(false);
        } else if (res.status && usr.is_verified === 1) {
          setUserVerified(true);
        } /**/
        if (res.status) {
          setUserLogged(true);
          setUserVerified(true);
        }

        setSessionChecked(true);
      },
      (error) => {
        AuthService.logout();
        return navigate("/login");
      }
    );
  }, []);

  const goVerify = () => {
    return navigate("/verify-account");
  };
  const doLogout = () => {
    AuthService.logout();
    setTimeout(() => {
      console.log("Session Cleared...");
      return navigate("/");
    }, 300);
  };

  //const [isLogged, setIsLogged] = React.useState(logx);
  const [cur_slug, setSlug] = React.useState<any | string>(null);

  const page = useLocation()["pathname"];
  const pgarr = page.split("/");
  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (pgarr.length == 4) {
      setSlug(pgarr[3]);
    }
  }, []);

  const [togged, setTogged] = React.useState<boolean>(false);
  const togNav = () => {
    setTogged(!togged);
  };

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexGrow: "1 1",
          width: "100%",
          minHeight: "100vh",
          padding: "0 !important",
          backgroundColor: "#f5f5f5",
        }}
      >
        <CssBaseline />
        <AccountHeader
          open={open}
          toggleDrawer={toggleDrawer}
          DrawerHeader={DrawerHeader}
          doLogout={doLogout}
          usr={usr}
          togNav={togNav}
          location={location}
        />
        {session_checked && user_logged && (
          <>
            <AccountSidePanel
              onopen={open}
              onclose={handleDrawerClose}
              DrawerHeader={DrawerHeader}
              doLogout={doLogout}
            />
            <main className="dashboard-cover">
              <DrawerHeader />
              <Routes>
                <Route path="" element={<Dashboard />}></Route>
                <Route path="/" element={<Dashboard />}></Route>
                <Route
                  path="/village-accounts//*"
                  element={<Financials />}
                ></Route>
                <Route path="/clearance/*" element={<Clearance />}></Route>
                <Route path="/dashboard" element={<Dashboard />}></Route>
                <Route path="/events//*" element={<Events />}></Route>
                <Route path="/meetings//*" element={<Meetings />}></Route>
                <Route path="/members//*" element={<Members />}></Route>
                <Route path="/cases//*" element={<Cases />}></Route>
                <Route path="/articles//*" element={<Articles />}></Route>
                <Route
                  path="/non-indigenes//*"
                  element={<NonIndigenes />}
                ></Route>
                <Route
                  path="/transactions/*"
                  element={<Transactions />}
                ></Route>
                <Route path="/messages/*" element={<Messages />}></Route>
                <Route path="/membership/*" element={<MemberShip />}></Route>
                <Route
                  path="/settings/*"
                  element={<Settings user={usr} />}
                ></Route>
                <Route path="*" element={<Four0FourPane home="/account" />} />
              </Routes>
            </main>
          </>
        )}

        {session_checked && !user_logged && (
          <div className="full-main-height inline-flex flex-col align-items-center pxy20">
            <span className="inline-block boldest txt-lg">NOT LOGGED IN</span>
          </div>
        )}

        {!session_checked && (
          <div className="full-main-height inline-flex flex-col align-items-center pxy20">
            <Box sx={{ width: "30%" }}>
              <LinearProgress />
            </Box>
            <div className="py20">
              <h3>Loading... </h3>
            </div>
          </div>
        )}
      </Box>
    </React.Fragment>
  );
}
