import React from "react";
import HttpService from "../services/HttpService";

const useFetchExecutives = (props: any) => {
  const { slug } = props;
  const [executives, setGroup] = React.useState<any[]>([]);
  const [loading_executives, setLoading] = React.useState(false);
  const [loaded_executives, setLoaded] = React.useState(false);

  React.useEffect(() => {
    listexecutives();
  }, []);

  const listexecutives = () => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("get_grouped_listing", { slug: slug })
      .then(
        (result) => {
          console.log("executives::", result);
          if (result.status === 1) {
            setGroup(result.data);
          }
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  return { executives, loading_executives, loaded_executives };
};

export default useFetchExecutives;
