import React from "react";
import { Link } from "react-router-dom";
import PlaceHolder from "../../templates/PlaceHolder";
import Button from "@mui/material/Button";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HttpService from "../../../services/HttpService";
import * as processHtml from "../../../services/processHtml";
import ArticlesListTemplate from "../../templates/ArticlesListTemplate";
import Header from "../../Header/Header";
import PageIntro from "../PageIntro/PageIntro";
import Footer from "../../Footer/Footer";
import { Card } from "@mui/material";
import ArticlesLayout from "./ArticlesLayout";

const ArticleList = (props: any) => {
  const [offset, setOffset] = React.useState<number>(0);
  const [articles, setArticle] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [page_data, setPageDate] = React.useState<any>({});

  React.useEffect(() => {
    setPageDate({
      ...page_data,
      page_slug: "",
      page_title: "All Articles",
      page_summary: "All Dianaokwu  Articles",
    });
    listArticle(offset);
  }, []);

  const listArticle = (offset: number) => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("all_articles", {
      offset: offset,
      limit: 6,
      mode: "all",
    })
      .then(
        (result) => {
          console.log("list_e::", result);
          if (result.status === 1) {
            let newRes = [...articles, ...result.data];
            setArticle(newRes);
          }
        },
        (error) => {
          console.log("err::", error);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const loadMore = () => {
    const newOffset = offset + 6;
    setOffset(newOffset);
    listArticle(newOffset);
  };

  return (
    <React.Fragment>
      <ArticlesLayout page_data={page_data}>
        {loaded && (
          <ArticlesListTemplate
            loading={loading}
            loaded={loaded}
            articles={articles}
            max_grid={4}
          />
        )}
        {/**/}
        {loading && <PlaceHolder type="articles" max_grid={4} />}
        <div className="flex justify-content-center align-items-center py20">
          <button disabled={loading} className="more" onClick={loadMore}>
            {loading ? "Loading..." : "Load More"}
          </button>
        </div>
      </ArticlesLayout>
    </React.Fragment>
  );
};

export default ArticleList;
