import React from "react";
import { Link, useParams } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import CustomModal from "../../templates/CustomModal";
import DurationPipe from "../../../pipes/DurationPipe";
import Currency from "../../../pipes/Currency";
import {
  CommentOutlined,
  DirectionsOutlined,
  Image,
  InfoOutlined,
  MoneyOutlined,
  TimerOutlined,
  VideoCameraFrontOutlined,
} from "@mui/icons-material";

import HttpService from "../../../services/HttpService";
import * as processHtml from "../../../services/processHtml";
import SeoModule from "../../../services/SeoModule";
import AuthService from "../../../services/AuthService";

import PlaceHolder from "../../templates/PlaceHolder";
import Header from "../../Header/Header";
import { IconButton, Tooltip, Typography } from "@mui/material";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import { useTheme } from "@mui/material/styles";
import PostCommentTemplate from "../../templates/PostCommentTemplate";
import Comments from "../../templates/Comments";
import ShareTemplate from "../../templates/ShareTemplate";
import LikeTemplate from "../../templates/LikeTemplate";
import DatePipe from "../../../pipes/DatePipe";
import Loading from "../../templates/Loading";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const EventDetail = () => {
  let params: any = useParams();
  const { decodeHtml } = processHtml;
  const cur_user = AuthService.getCurrentUser();
  const [events, setEvent] = React.useState<any>({ related_events: [] });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<any>("");
  const [isParam, setParam] = React.useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    console.log("::params::", params);
    const isParam = params.url ? true : false;
    setParam(isParam);
    if (isParam) {
      fetchEvent(params.url);
    }
  }, [params]);

  const fetchEvent = (url: string) => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("event_details", { url: url })
      .then(
        (result) => {
          console.log(result);
          if (result.status === 1) {
            setEvent(result.data);
          } else {
            const now = new Date().getTime() / 1000;
            document.title = "Event not found";
            setEvent({
              id: 1,
              title: "Event does not exist",
              content: `The event you are looking for does not exist. 
              It might not be published yet, deleted or you typed a wrong events url.`,
              create_time: now,
              related_events: [],
            });
          }
        },
        (error) => {
          setEvent({ related_events: [] });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const onToastClose = () => {
    setToast({ onopen: false, onclose: onToastClose });
  };
  const [toast, setToast] = React.useState<any>({
    onopen: false,
    onclose: onToastClose,
    message: "",
  });

  const [meta, setMeta] = React.useState<any>({});
  const handleInputChange = React.useCallback(
    (event: any) => {
      const targ = event.target;
      const name = targ.name;
      const value = targ.value;
      console.log(name, value);
      setMeta({ ...meta, [name]: value });
      console.log(meta);
    },
    [meta]
  );

  const onHtmlChange = (e: any) => {
    setMessage(e.target.value);
    console.log(message);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    if (!cur_user["isLogged"]) {
      formData.append("email", meta.email);
      formData.append("name", meta.name);
    } else {
      const xname = cur_user["firstname"] + " " + cur_user["surname"];
      formData.append("email", cur_user["email"]);
      formData.append("name", xname);
    }
    formData.append("comment", message);
    formData.append("table", "events");
    formData.append("event_id", events.id);
    formData.append("mode", "save-comment");
    console.log(formData);
    setLoading(true);
    setLoaded(false);
    HttpService.postFormHeader("post_comment", formData)
      .then(
        (resp) => {
          console.log(resp);
          setToast({ ...toast, onopen: true, message: resp.message });
          if (resp.status === 1) {
            setTimeout(() => {
              setToast({ ...toast, onopen: false });
            }, 3000);
          }
        },
        (error) => {
          setToast({ ...toast, onopen: true, message: error.message });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //postTicket
  };

  const [show_comment, setShow] = React.useState<boolean>(false);

  const togComment = (): void => {
    setShow(!show_comment);
  };

  const closeBook = () => {
    setBook({ onopen: false });
    setPayStart(false);
  };
  const [book, setBook] = React.useState<any>({
    onopen: false,
    onclose: closeBook,
    message: "",
  });
  const [pay_start, setPayStart] = React.useState(false);
  const launchBooking = () => {};
  const [value, setValue] = React.useState<any>(0);
  const theme = useTheme();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };
  const update_coms = (load: any) => {
    if (load) {
      let new_arr = [...events.comments];
      new_arr.unshift(load);
      setEvent({ ...events, comments: new_arr });
    } else {
      console.log("Empty Com::", load);
    }
  };
  return (
    <React.Fragment>
      <SeoModule
        title={events.title}
        description={events.summary}
        name={"Dianaokwu Uka Oye"}
        page_image={process.env.REACT_APP_SERVER_ROOT + events.photo}
        page_url={process.env.REACT_APP_SERVER_ROOT + events.url}
        type="Event"
      />

      <section className="page-main">
        <div className="home-overlay"></div>
        <Header />
      </section>
      <section className="container py20">
        <Card sx={{ borderRadius: "0" }}>
          {loaded && (
            <Card sx={{ borderRadius: "0" }}>
              <div className="page-head bga">
                <div className="flex flex-row-resp align-items-center">
                  <div className="inline-block pxy20 spacer">
                    <Breadcrumbs
                      aria-label="breadcrumb"
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Link to="/">Home</Link>
                      <Link to="/events">Events</Link>
                      <Link to={`/events/${events.category_url}`}>
                        {events.category_title}
                      </Link>
                    </Breadcrumbs>
                    <h2 className="mt10">{events.title}</h2>
                    <span className="date-class">
                      <i className="fas fa-calendar pr5"></i>
                      <DatePipe value={events.start_time * 1000} />
                    </span>
                  </div>

                  <span className="px20 sm-hide-inline">
                    <Avatar
                      sx={{ width: "50px", height: "50px" }}
                      variant="circular"
                      alt={`${events.title} `}
                      src={process.env.REACT_APP_SERVER_ROOT + events?.thumb}
                    />
                  </span>
                </div>
              </div>

              <div className="pxy0 bg-differ">
                <div className=" pxy15">
                  <div className="relative">
                    <div className="highlighted-container">
                      <div className="highlight-banner">
                        <img
                          src={
                            process.env.REACT_APP_SERVER_ROOT + events.picture
                          }
                          alt="events banner"
                        />
                      </div>
                    </div>
                    <Divider />
                    <div className="highlight-details">
                      <div className="h2 flex align-items-center">
                        <i className="fas fa-map-marker-alt mr5"></i>
                        {events.location}
                      </div>
                      <div className="flex meta-span align-items-center">
                        <span className="spacer">
                          <Tooltip
                            arrow
                            placement="top-start"
                            title={`${events.comment_num} comments`}
                          >
                            <IconButton className="react-button">
                              <CommentOutlined
                                className="react-icon"
                                sx={{ color: "#fff" }}
                              />
                            </IconButton>
                          </Tooltip>
                          <span className="count-span white-color">
                            {events.comment_num}
                          </span>
                        </span>

                        <span className="txt px5"></span>
                        <span className="spacer"></span>
                        <span className="flex align-items-center">
                          <span>
                            <LikeTemplate
                              item={events}
                              item_label={"event"}
                              is_white
                              show_dislike
                            />
                          </span>
                          <span>
                            <ShareTemplate
                              item={events}
                              item_label={"event"}
                              is_white={true}
                            />
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <Divider />
                <Card sx={{ m: "0", p: "0", maxWidth: "100%" }}>
                  <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 0, sm: 2, md: 2 }}
                    sx={{
                      display: "flex",
                      backgroundColor: "#fafafa",
                    }}
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <ListItem divider>
                        <ListItemIcon>
                          <MoneyOutlined sx={{ fontSize: "40px" }} />
                        </ListItemIcon>
                        <ListItemText>
                          <div className="bolder">Physical Attendance</div>
                          <div className="">
                            <Currency value={events.physical_price} />
                          </div>
                        </ListItemText>
                      </ListItem>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <ListItem divider>
                        <ListItemIcon>
                          <DirectionsOutlined sx={{ fontSize: "40px" }} />
                        </ListItemIcon>
                        <ListItemText>
                          <div className="bolder">Venue Directions</div>
                          <div>
                            <a href="/">Open Map</a>
                          </div>
                        </ListItemText>
                      </ListItem>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <ListItem divider>
                        <ListItemIcon>
                          <TimerOutlined sx={{ fontSize: "40px" }} />
                        </ListItemIcon>
                        <ListItemText>
                          <div className="bolder">Event Duration</div>
                          <div>
                            <DurationPipe
                              start={events.start_time * 1000}
                              end={events.end_time * 1000}
                            />
                          </div>
                        </ListItemText>
                      </ListItem>
                    </Grid>
                  </Grid>
                  <Divider />

                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      width: "100%",
                      maxWidth: "100%",
                    }}
                  >
                    <Tabs
                      onChange={handleChange}
                      value={value}
                      aria-label="lab API tabs example"
                      variant="scrollable"
                      scrollButtons="auto"
                      selectionFollowsFocus
                      sx={{
                        [`& .${tabsClasses.scrollButtons}`]: {
                          "&.Mui-disabled": { opacity: 0.3 },
                        },
                      }}
                    >
                      <Tab
                        {...a11yProps(0)}
                        label={
                          <span className="flex align-items-center">
                            <InfoOutlined />
                            <h3 className="pl5 boldest  sm-hide-inline-block">
                              Description
                            </h3>
                          </span>
                        }
                        wrapped
                      />

                      <Tab
                        {...a11yProps(1)}
                        label={
                          <span className="flex align-items-center">
                            <VideoCameraFrontOutlined />
                            <h3 className="pl5 boldest sm-hide-inline-block">
                              Photos & Videos
                            </h3>
                          </span>
                        }
                      />
                      <Tab
                        {...a11yProps(2)}
                        label={
                          <span className="flex align-items-center">
                            <i className="fas fa-comments"></i>
                            <h3 className="pl5 boldest">
                              <span className=" sm-hide-inline-block">
                                Comments
                              </span>{" "}
                              ({events?.comments.length})
                            </h3>
                          </span>
                        }
                      />
                    </Tabs>
                  </Box>
                  <div className="tabs-container">
                    <TabPanel value={value} index={0}>
                      <p
                        className="article-details-container"
                        dangerouslySetInnerHTML={{
                          __html: decodeHtml(events.description),
                        }}
                      ></p>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      Photos & Videos
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      {/**/}
                      <PostCommentTemplate
                        item={events}
                        update_coms={update_coms}
                        id={events?.id}
                        index={events?.id}
                        item_label={"event"}
                      />
                      <Comments
                        data={events?.comments}
                        platform={"detail_page"}
                      />
                    </TabPanel>
                  </div>
                </Card>
              </div>
            </Card>
          )}
          {loading && <Loading loading_text="Loading Event..." />}
        </Card>
      </section>

      {toast.onopen && <CustomModal data={toast} />}
    </React.Fragment>
  );
};

export default EventDetail;
