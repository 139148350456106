import { Breadcrumbs, Button, Card } from "@mui/material";
import React from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import HttpService from "../../../services/HttpService";
import AuthService from "../../../services/AuthService";
import MeetingsListTemplate from "../../templates/MeetingsListTemplate";
import PlaceHolder from "../../templates/PlaceHolder";
import Loading from "../../templates/Loading";
const usr = AuthService.getCurrentUser();
const MeetingsList = () => {
  const [offset, setOffset] = React.useState<number>(0);
  const [meetings, setMeetings] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    listMeetings(0);
  }, []);

  const listMeetings = (offset: number) => {
    setLoading(true);
    setLoaded(false);
    console.log("Loading meetings..");
    HttpService.postHeader("all_meetings", { offset: offset, limit: 24 })
      .then(
        (result) => {
          console.log(result);
          if (Array.isArray(result.data)) {
            let newRes = [...meetings, ...result.data];
            setMeetings(result.data);
          } else {
            setMeetings([]);
          }
        },
        (error) => {
          setMeetings([]);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const loadMore = () => {
    const newOffset = offset + 20;
    setOffset(newOffset);
    listMeetings(newOffset);
  };

  const [default_subject, setDefSub] = React.useState("");
  const [cur_tab, setTab] = React.useState<number>(0);
  const doStep = (index: number) => {
    setTab(index);
  };

  return (
    <React.Fragment>
      <section className="account-page-container">
        <Card>
          <div className="page-topper">
            <Breadcrumbs>
              <Link to="/account">Dashboard</Link>
              <span>Meetings</span>
            </Breadcrumbs>
            <h1>Meetings</h1>
          </div>

          <section className="contain py20">
            <div className="pxy20">
              {loaded && (
                <MeetingsListTemplate
                  loading={loading}
                  loaded={loaded}
                  meetings={meetings}
                  max_grid={3}
                />
              )}
              {loading && <PlaceHolder type="articles" max_grid={3} />}
              <div className="flex justify-content-center align-items-center py20">
                <Button
                  disabled={loading}
                  size="large"
                  variant="contained"
                  onClick={loadMore}
                >
                  {loading ? "Loading..." : "Load More"}
                </Button>
              </div>
            </div>
          </section>
        </Card>
      </section>
    </React.Fragment>
  );
};

export default MeetingsList;
