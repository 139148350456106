import React from "react";
import { Routes, Route, Link, useParams } from "react-router-dom";
import Expenditure from "./Expenditure";
import Income from "./Income";
import Levies from "./Levies";
import { Breadcrumbs, Card } from "@mui/material";
import { Settings } from "@mui/icons-material";

const Financials = () => {
  const params = useParams();
  const isParam = params["*"] ? true : false;
  let urpath = params["*"];

  console.log("urpath:", urpath);
  const get_obj = (links: any[]) => {
    let res = null;
    links.map(function (route) {
      if (route.path === urpath) {
        res = route;
      }
    });
    return res;
  };
  React.useEffect(() => {
    //getMember(params.*);
  }, [urpath]);

  const tabs = [
    { path: "expenditure", title: "Expenditure", icon: "fa-money-check-alt" },
    { path: "income", title: "Income", icon: " fa-solid fa-coins " },
    { path: "assets", title: "Village Assets", icon: "fa-landmark" },
  ];
  type rts = {
    title: string;
    icon?: string;
    path: string;
    route?: object;
  };
  const obj_path: any = get_obj(tabs);
  let defor = urpath && urpath.length > 0 ? obj_path : tabs[0];
  console.log(obj_path, typeof urpath, defor);
  const [default_tab, setDefTab] = React.useState<rts | null>(defor);

  const [tab, setTab] = React.useState<any>("");

  React.useEffect(() => {
    if (defor?.path) {
      setTab(defor.path);
    }
  }, []);

  const [isTogged, setIsTogged] = React.useState(false);
  const toggleNav = () => {
    const cur = isTogged;
    setIsTogged(!cur);
  };

  const doTab = (item: any) => {
    setTab(item?.path);
    setDefTab(item);
    toggleNav();
  };
  return (
    <React.Fragment>
      <section className="account-page-container">
        <Card>
          <div className="page-topper">
            <Breadcrumbs>
              <Link to="/account">Dashboard</Link>
              <span>Financial Account</span>
            </Breadcrumbs>
            <h1>{default_tab?.title}</h1>
          </div>
          <section className="page-detail-container pxy10">
            <div className={`nav-cover`}>
              <span className="def-sub" onClick={toggleNav}>
                <span className="spacer">
                  <i className={`fas ${default_tab?.icon} pr5 txt-sm`}></i>
                  {default_tab?.title}
                </span>
                <span className="mobile-nav">
                  <button onClick={toggleNav} className="mnav dark">
                    <i
                      className={`fas fa-${
                        !isTogged ? "chevron-down" : "chevron-up"
                      }`}
                    ></i>
                  </button>
                </span>
              </span>
              <ul className={`category-tab   ${isTogged ? "flex" : "none"}`}>
                {tabs.map((item, index) => (
                  <li key={item.title}>
                    <Link
                      to={`/account/village-accounts/${item.path}`}
                      onClick={() => doTab(item)}
                      className={item.path === urpath ? "active" : ""}
                    >
                      <i className={`fas ${item.icon} pr10 txt-sm`}></i>
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <Routes>
              <Route path="" element={<Expenditure />} />
              <Route path="/" element={<Expenditure />} />
              <Route path="/expenditure" element={<Expenditure />} />
              <Route path="/income" element={<Income />} />
              <Route path="/levies" element={<Levies />} />
            </Routes>
          </section>
        </Card>
      </section>
    </React.Fragment>
  );
};

export default Financials;
