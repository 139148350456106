import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HttpService from "../../../services/HttpService";
import CustomModal from "../../templates/CustomModal";
import { DefaultEditor } from "react-simple-wysiwyg";

const GalleryEdit = (props: any) => {
  console.log("New GAllery details Renders");
  let val = props.data;
  let navigate = useNavigate();
  let params: any = useParams();
  const [gallery, setGallery] = React.useState<any>({});
  const [isParam, setParam] = React.useState<any>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [staffs_fetched, setStaffsFetched] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [caption, setCaption] = React.useState<string>("");
  const [type, setType] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [id, setId] = React.useState<any>(false);
  const [banner_id, setBannerId] = React.useState<any>(false);
  const [loading_text, setText] = React.useState<string>("Save Now");
  const [preview_image, setPreview] = React.useState<any>(null);
  const [file, setFile] = React.useState<any>(null);
  const [new_file, setNewFile] = React.useState<any>(0);
  const onToastClose = () => {
    setToast({ onopen: false, onclose: onToastClose });
  };
  const [toast, setToast] = React.useState<any>({
    onopen: false,
    onclose: onToastClose,
    message: "",
  });
  const [meta, setMeta] = React.useState<any>(new FormData());
  React.useEffect(() => {
    console.log("::params::", params);
    const isParam = params.galleryId ? true : false;
    setParam(isParam);
    if (isParam) {
      fetchGallery(params.galleryId);
    }
  }, []);

  const fetchGallery = (id: any) => {
    console.log(id);
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("gallery_details", { id: id })
      .then(
        (result) => {
          setLoading(false);
          console.log(":::", result);
          if (!caption) {
            setDescription(result.data.description);
            setCaption(result.data.caption);
            setId(result.data.id);
            setType(result.data.type);
            setBannerId(result.data.photos[0].id);
            setPreview(
              process.env.REACT_APP_SERVER_ROOT + result.data.photos[0].src
            );
          }
        },
        (error) => {
          setGallery({});
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const handleInputChange = React.useCallback(
    (event: any) => {
      const targ = event.target;
      const name = targ.name;
      const value = targ.value;
      if (name === "title") {
        setCaption(value);
      }
    },
    [gallery]
  );

  const onHtmlChange = (e: any) => {
    setDescription(e.target.value);
    console.log(description);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("caption", caption);
    formData.append("mode", "edit");
    formData.append("banner", file);
    formData.append("type", type);
    formData.append("is_new_file", new_file);
    formData.append("description", description);
    formData.append("id", id);
    formData.append("banner_id", banner_id);
    setLoading(true);
    setLoaded(false);
    setToast({
      ...toast,
      onopen: true,
      onclose: onToastClose,
      message: "Updating gallery. Please wait...",
    });
    HttpService.postFormHeader("edit_gallery", formData)
      .then(
        (resp) => {
          console.log(resp);
          setToast({
            ...toast,
            onopen: true,
            onclose: onToastClose,
            message: resp.message,
          });
          if (resp.status === 1) {
            setTimeout(() => {
              setToast({ ...toast, onopen: false });
              navigate(`/admin/gallery/p/${resp.uid}`);
            }, 3000);
          }
        },
        (error) => {
          setToast({ ...toast, onopen: true, message: error.message });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //postTicket
  };

  const handleFileChange = (e: any) => {
    const formData = new FormData();
    let fname = e.target.name;
    let flx = e.target.files[0];
    formData.append("file", flx);
    console.log(flx, formData);
    setFile(flx);
    console.log(flx);
    setPreview(URL.createObjectURL(flx)); // Would see a path?
    setNewFile(1);
  };
  return (
    <>
      <div
        className="page-top-intro flex px10 py10 align-items-center 
      justify-caption-center"
      >
        <h3 className="py0 my0 px0 mx0">
          <i className="fas fa-gallerypaper"></i> Edit Gallery
        </h3>
        <div className="spacer"></div>
        <div></div>
      </div>
      <Divider />

      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          padding: "10px",
          width: "100%",
        }}
      >
        <Link to={`/admin/gallery`}>Gallery</Link>
        <Link to={`/admin/gallery/p/${id}`}>{caption}</Link>
      </Breadcrumbs>
      <div className="px10 pb10">
        <Card sx={{ p: "0", m: "0" }}>
          <div style={{ padding: "20px" }}>
            <div className={loading ? " input iconed " : " input "}>
              <label>Gallery Title</label>
              <input
                type="text"
                className="input-form-control"
                name="caption"
                disabled={loading}
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
                placeholder={"Gallery Title "}
              />
              {loading && (
                <span className="input-icon">
                  <i className="fas fa-refresh fa-spin"></i>
                </span>
              )}
            </div>
            <div className="input">
              <label>Gallery Type</label>
              <select
                name="type"
                className="input-form-control"
                value={type}
                disabled={loading}
                defaultValue={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value={"image"}>Pictures Only</option>
                <option value={"video"}>Videos Only</option>
                <option value={"both"}>Videos & Pictures</option>
              </select>
            </div>
            <input
              type="hidden"
              className="input-form-control"
              name="id"
              value={id}
              onChange={handleInputChange}
            />
            <input
              type="hidden"
              className="input-form-control"
              name="banner_id"
              value={banner_id}
              onChange={handleInputChange}
            />
            <div className="banner-section">
              {preview_image && (
                <div className="image_preview">
                  <img className="" src={preview_image} alt="preview Image" />
                </div>
              )}
              <div className={loading ? " input iconed " : " input "}>
                <label>Change Banner Image</label>
                <input
                  type="file"
                  className="input-form-control"
                  name="file"
                  onChange={handleFileChange}
                />
              </div>
            </div>
            <div className="mb10">
              <DefaultEditor
                className="input-form-control"
                value={description}
                disabled={loading}
                placeholder="Gallery Description"
                onChange={onHtmlChange}
              />
            </div>
            <Button
              type="submit"
              size="large"
              variant="contained"
              disabled={loading}
              onClick={handleSubmit}
            >
              {loading ? "Working..." : " Save "}
            </Button>
            {/**/}{" "}
          </div>
        </Card>
      </div>

      <CustomModal data={toast} />
    </>
  );
};

export default GalleryEdit;
