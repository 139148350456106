import React from "react";
import {
  GroupOutlined,
  Groups2Outlined,
  Groups3Outlined,
  History,
  Leaderboard,
  Man2Outlined,
  Man3Outlined,
  Man4Outlined,
  ManOutlined,
  Pages,
  PieChartOutline,
  Timelapse,
} from "@mui/icons-material";
import {
  Breadcrumbs,
  Card,
  Grid,
  Icon,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import IchieDiokwu from "./IchieDiokwu";
import Executives from "./Executives";
import Nze from "./Nze";
import Eboani from "./Eboani";
import IchieDimechem from "./IchieDimechem";
import Committees from "./Committees";
import Constitution from "./Constitution";

const AboutHome = () => {
  const sections: any[] = [
    {
      path: "history",
      title: "History",
      icon: <History />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "kindreds",
      title: "Kindreds",
      icon: <GroupOutlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "quarters",
      title: "Quarters",
      icon: <PieChartOutline />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "heritage",
      title: "Heritage",
      icon: <Timelapse />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "past-leaders",
      title: "Past Leaders",
      icon: <Leaderboard />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "ichie-diokwu",
      title: "Ichie Diokwu",
      icon: <Man3Outlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "executives",
      title: "Executives",
      icon: <Groups2Outlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "nze",
      title: "Ndi Nze",
      icon: <Man4Outlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "ichie-eboani",
      title: "Ndi Ichie Eboani",
      icon: <Man2Outlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "ichie-dimechem",
      title: "Ichie Dimechem",
      icon: <ManOutlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "committees",
      title: "Committees",
      icon: <Groups3Outlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "constitution",
      title: "Constitution",
      icon: <Pages />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
  ];

  return (
    <div className="account-page-container">
      <Card>
        <div className="page-topper">
          <Breadcrumbs>
            <Link to="/admin">Dashboard</Link>
            <span>About Dianaokwu</span>
          </Breadcrumbs>
          <h1> About Dianaokwu</h1>
        </div>
        <section className="page-detail-container">
          <div className="pxy20">
            <Grid container spacing={1.5}>
              {sections.map((item) => (
                <Grid item xs={12} sm={6} md={4} key={item.path}>
                  <div className="bga ">
                    <ListItem
                      className="grid-card"
                      component={NavLink}
                      to={`/admin/about/${item.path}`}
                    >
                      <ListItemIcon>
                      {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <div style={{ lineHeight: "1" }}>{item.title}</div>
                        }
                     ></ListItemText>
                    </ListItem>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </section>
      </Card>
    </div>
  );
};

export default AboutHome;
