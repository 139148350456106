import React from "react";
import { Routes, Route } from "react-router-dom";
import AboutHome from "./AboutHome";
import Kindreds from "./Kindreds/Kindreds";
import Quarters from "./Quarters";
import History from "./History";
import PastLeaders from "./PastLeaders";
import Heritage from "./Heritage";
import Eboani from "./Eboani";
import IchieDimechem from "./IchieDimechem";
import Executives from "./Executives";
import Constitution from "./Constitution";
import Nze from "./Nze";
import Committees from "./Committees";
import IchieDiokwu from "./IchieDiokwu";

const About = (props: any) => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="" element={<AboutHome />} />
        <Route path="/kindreds/*" element={<Kindreds />} />
        <Route path="/quarters" element={<Quarters />} />
        <Route path="/history" element={<History />} />
        <Route path="/heritage" element={<Heritage />} />
        <Route path="/past-leaders" element={<PastLeaders />} />
        <Route path="/ichie-eboani" element={<Eboani />} />
        <Route path="/ichie-diokwu" element={<IchieDiokwu />} />
        <Route path="/ichie-dimechem" element={<IchieDimechem />} />
        <Route path="/executives" element={<Executives />} />
        <Route path="/constitution" element={<Constitution />} />
        <Route path="/committees/*" element={<Committees />} />
        <Route path="/nze" element={<Nze />} />
      </Routes>
    </React.Fragment>
  );
};

export default About;
