import React from "react";
import Button from "@mui/material/Button";
import CustomModal from "../../templates/CustomModal";
import HttpService from "../../../services/HttpService";
import {
  Avatar,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
} from "@mui/material";
import { CameraAltOutlined } from "@mui/icons-material";
import AuthService from "../../../services/AuthService";
const UploadPicture = (props: any) => {
  console.log(props);
  let { user, onopen, onClose, handler, severity } = props;

  const [status_bg, setStatusBg] = React.useState<string>("bg-info");
  const [status_color, setStatusColor] = React.useState<string>("color-info");
  const [status_icon, setStatusIcon] = React.useState<string>("info-circle");

  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>(null);
  const modalClose = () => setModal({ ...modal_data, onopen: false });
  const [modal_data, setModal] = React.useState<any>({
    onopen: false,
    onclose: modalClose,
  });

  const dts = user?.dob ? new Date(user?.dob) : new Date();
  const [fetched, setFetched] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (user) {
      setData({
        ...user,
        dob: dts,
      });
      setFetched(true);
    }
  }, []);

  const [preview_image, setPreview] = React.useState<any>(null);
  const [file, setFile] = React.useState<any>(null);
  const [new_file, setNewFile] = React.useState<any>(0);

  const handleFileChange = (e: any) => {
    console.log(e.target.name, e.target.files);

    const formData = new FormData();
    let fname = e.target.name;
    let flx = e.target.files[0];
    formData.append("file", flx);
    console.log(flx, formData);
    setFile(flx);
    console.log(flx);
    setPreview(URL.createObjectURL(flx)); // Would see a path?
    setNewFile(1);
  };
  const submit_handler = () => {
    console.log(data);
    setLoading(true);
    setLoaded(false);
    data.mode = "upload_picture";
    const formData = new FormData();
    formData.append("picture", file);
    formData.append("is_new_file", new_file);
    formData.append("user_id", user.id);
    HttpService.postHeader("change_picture", formData)
      .then(
        (result) => {
          console.log(result);
          const severity =
            result.status === 1
              ? "success"
              : result.status === 0
              ? "error"
              : "info";
          setStatusBg(severity ? "bg-" + severity : "bg-info");
          setStatusIcon(
            severity === "success"
              ? "check-circle"
              : severity === "error"
              ? "exclamation-triangle"
              : "info-circle"
          );
          setStatusColor(severity ? "color-" + severity : "color-info");
          setModal({
            ...modal_data,
            onopen: true,
            message: result.message,
            severity: severity,
            onclose: modalClose,
          });
          console.log("::|", result);
          if (result.status === 1) {
            AuthService.updateUser(result.user, true);
          }
        },
        (error) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  };

  return (
    <React.Fragment>
      <div className={`head-area ${status_bg}`}>
        <i className={`fas fa-${status_icon}`}></i>
      </div>
      <DialogContent sx={{ p: "0" }}>
        <div className="alert-container">
          <div className={`message-area ${status_color}`}>
            <div className="flex flex-col justify-content-center align-items-center pxy10">
              <h3 className="mb10">
                <i className="fas fa-image"></i> Upload Picture
              </h3>

              <div className="avatar-pane flex flex-col justify-content-center pxy10">
                <Avatar
                  src={
                    preview_image ||
                    process.env.REACT_APP_SERVER_ROOT + user.thumb
                  }
                  sx={{ width: 200, height: 200 }}
                ></Avatar>
              </div>
              <div className={"max-width-input"}>
                <label>Select Picture</label>
                <input
                  type="file"
                  className="custom-input-file"
                  name="file"
                  onChange={handleFileChange}
                />
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      <Divider />
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "column",
          alignContent: "stretch",
          p: "0",
        }}
      >
        <div className="button-area">
          <div className="flex align-items-center">
            <span className="spacer">
              <Button
                fullWidth
                size="large"
                onClick={() => props.handler(false)}
                sx={{ paddingY: "13px", color: "orange" }}
              >
                <span className="px10"> EXIT</span>
              </Button>
            </span>
            <Divider orientation="vertical" />
            <span className="spacer">
              <Button
                fullWidth
                disabled={loading}
                onClick={submit_handler}
                sx={{ paddingY: "13px", color: "green" }}
              >
                {loading ? "Working..." : "Update Picture"}
              </Button>
            </span>
          </div>
        </div>
      </DialogActions>

      <CustomModal data={modal_data} />
    </React.Fragment>
  );
};

export default UploadPicture;
