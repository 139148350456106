import React from "react";
import { Link, useParams } from "react-router-dom";
import { Breadcrumbs, Card, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { DefaultEditor } from "react-simple-wysiwyg";
import HttpService from "../../../services/HttpService";
import CustomModal from "../../templates/CustomModal";

const EditMeeting = () => {
  const params = useParams();
  const isParam = params.mId ? true : false;
  let [loading, setLoading] = React.useState<any>(false);
  let [loaded, setLoaded] = React.useState<any>(false);
  let [meeting, setMeeting] = React.useState<any>({ designation: "0" });

  const [title, setTitle] = React.useState<any>("");
  const [venue, setVenue] = React.useState<any>("");
  const [start_time, setStartTime] = React.useState<any>("");
  const [end_time, setEndTime] = React.useState<any>("");
  const [description, setDesc] = React.useState<any>("");
  let [id, setId] = React.useState<any>(0);

  React.useEffect(() => {
    getmeeting(params.mId);
  }, [params?.mId]);

  const getmeeting = (id: any) => {
    setLoading(true);
    setLoaded(false);
    HttpService.post({ id: id }, "meeting_details")
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          if (result.status === 1) {
            setMeeting(result.data);
            setTitle(result.data.title);
            setVenue(result.data.venue);
            setStartTime(result.data.start_time);
            setEndTime(result.data.end_time);
            setDesc(result.data.description);
            setId(result.data.id);
            setPreview(process.env.REACT_APP_SERVER_ROOT + result.data.picture);
          } else {
            setMeeting({});
          }
        },
        (error) => {
          setMeeting({});
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const onHtmlChange = (e: any) => {
    setDesc(e.target.value);
  };

  const handleInputChange = React.useCallback(
    (e: any) => {
      console.log(e.target);
      const name = e.target.title;
      setMeeting({ ...meeting, [name]: e.target.value });
    },
    [meeting]
  );

  const [file, setFile] = React.useState<any>(null);
  const [new_file, setNewFile] = React.useState<any>(0);
  const [preview_image, setPreview] = React.useState<any>(null);
  const handleFileChange = (e: any) => {
    const formData = new FormData();
    let fname = e.target.title;
    let flx = e.target.files[0];
    formData.append("file", flx);
    console.log(flx, formData);
    setFile(flx);
    console.log(flx);
    setPreview(URL.createObjectURL(flx)); // Would see a path?
    setNewFile(1);
  };
  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
    title: "New meeting",
  });

  const handleSubmit = () => {
    console.log("SUBMITTING");
    setLoading(true);
    setLoaded(false);

    const formData = new FormData();
    formData.append("page", "admin");
    formData.append("id", id);
    formData.append("title", title);
    formData.append("venue", venue);
    formData.append("start_time", start_time);
    formData.append("end_time", end_time);
    formData.append("banner", file);
    formData.append("description", description);

    HttpService.postFormHeader("edit_meeting", formData)
      .then((resp) => {
        console.log(resp);
        const severity =
          resp.status === 1
            ? "success"
            : resp.status === 0
            ? "error"
            : resp.status === 44
            ? "error"
            : "info";
        setModal({
          ...modal,
          onopen: true,
          message: resp.message,
          severity: severity,
        });
        if (resp.status === 1) {
        }
      })
      .catch((err) => {
        console.log(err);
        setModal({
          ...modal,
          onopen: true,
          severity: "error",
          message: err.message,
        });
      })
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };
  return (
    <div className="account-page-container">
      <Card>
        <div className="page-topper">
          <Breadcrumbs>
            <Link to="/admin">Dashboard</Link>
            <Link to="/admin/meetings">Meetings</Link>
            <Link to={`/admin/meetings/p/${meeting?.url}`}>
              {meeting?.title}
            </Link>
          </Breadcrumbs>
          <h1>Edit {meeting?.title}</h1>
        </div>
        <section className="page-detail-container">
          <div className="py30 px20">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <div
                  className={`input mb0 pr5 spacer ${loading ? "iconed" : ""}`}
                >
                  <label>Meeting Title</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder={"Meeting Title"}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer ${loading ? "iconed " : ""}`}>
                  <label>Venue</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="venue"
                    value={venue}
                    onChange={(e) => setVenue(e.target.value)}
                    placeholder={"venue "}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer ${loading ? "iconed" : ""}`}>
                  <label>Start Time</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="start_time"
                    value={start_time}
                    onChange={(e) => setStartTime(e.target.value)}
                    placeholder={"Start time "}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer ${loading ? "iconed " : ""}`}>
                  <label>End Time</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="end_time"
                    value={end_time}
                    onChange={(e) => setEndTime(e.target.value)}
                    placeholder={"End time "}
                  />
                </div>
              </Grid>
            </Grid>

            <div className="banner-section">
              {preview_image && (
                <div className="image_preview">
                  <img className="" src={preview_image} alt="preview Image" />
                </div>
              )}
              <div
                className={
                  loading
                    ? " input mb0 iconed mt20 pt10"
                    : " input mb0 mt20 pt10"
                }
              >
                <label>Attach meeting's Banner</label>
                <input
                  type="file"
                  className="form-control"
                  name="file"
                  onChange={handleFileChange}
                  placeholder={"picture"}
                />
              </div>
            </div>

            <div className="mb10">
              <DefaultEditor
                className="form-control"
                placeholder="meeting's descriptiongraphy"
                value={description}
                onChange={onHtmlChange}
              />
            </div>
            <Button
              type="submit"
              size="large"
              variant="contained"
              disabled={loading}
              onClick={handleSubmit}
            >
              {loading ? "Working..." : " Add meeting"}
            </Button>
          </div>
        </section>
      </Card>
      {modal.onopen && <CustomModal data={modal} />}
    </div>
  );
};

export default EditMeeting;
