import React from "react";
import { Routes, Route } from "react-router-dom";
import CreateMeeting from "./CreateMeeting";
import EditMeeting from "./EditMeeting";
import MeetingsList from "./MeetingsList";
import MeetingDetail from "./MeetingDetail";

const Meetings = (props: any) => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="" element={<MeetingsList />} />
        <Route path="/" element={<MeetingsList />} />
        <Route path="/p/:url" element={<MeetingDetail />} />
        <Route path="/e/:mId" element={<EditMeeting />} />
        <Route path="/a/new" element={<CreateMeeting />} />
      </Routes>
    </React.Fragment>
  );
};

export default Meetings;
