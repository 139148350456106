import React from "react";
import { Link, useParams } from "react-router-dom";
import {
  Breadcrumbs,
  ButtonGroup,
  Card,
  Dialog,
  Grid,
  IconButton,
  LinearProgress,
  Slide,
  Tooltip,
} from "@mui/material";
import HttpService from "../../../services/HttpService";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import CustomModal from "../../templates/CustomModal";
import DurationPipe from "../../../pipes/DurationPipe";
import Currency from "../../../pipes/Currency";
import {
  AddModerator,
  CameraAltOutlined,
  CommentOutlined,
  Delete,
  DirectionsOutlined,
  Edit,
  MoneyOutlined,
  StreamOutlined,
  TimerOutlined,
  VideoCameraFrontOutlined,
} from "@mui/icons-material";

import PlaceHolder from "../../templates/PlaceHolder";

import TabContext from "@mui/lab/TabContext";
import { Image, InfoOutlined } from "@mui/icons-material";

import * as processHtml from "../../../services/processHtml";
import SeoModule from "../../../services/SeoModule";
import AuthService from "../../../services/AuthService";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import PostCommentTemplate from "../../templates/PostCommentTemplate";
import DatePipe from "../../../pipes/DatePipe";

const Transition = React.forwardRef(function Transition(props: any, ref: any) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const EventDetail = () => {
  const params = useParams();
  const { decodeHtml } = processHtml;
  const isParam = params.mId ? true : false;
  let [loading, setLoading] = React.useState<any>(false);
  let [loaded, setLoaded] = React.useState<any>(false);
  let [event, setEvent] = React.useState<any>({ designation: "0" });

  const [preview, setPreview] = React.useState<any>("");
  const [title, setTitle] = React.useState<any>("");
  const [venue, setVenue] = React.useState<any>("");
  const [start_time, setStartTime] = React.useState<any>("");
  const [end_time, setEndTime] = React.useState<any>("");
  const [description, setDesc] = React.useState<any>("");
  let [id, setId] = React.useState<any>(0);

  React.useEffect(() => {
    getevent(params.mId);
  }, [params?.mId]);

  const getevent = (id: any) => {
    setLoading(true);
    setLoaded(false);
    HttpService.post({ id: id }, "event_details")
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          if (result.status === 1) {
            setEvent(result.data);
            setTitle(result.data.title);
            setVenue(result.data.venue);
            setStartTime(result.data.start_time);
            setEndTime(result.data.end_time);
            setDesc(result.data.description);
            setId(result.data.id);
            setPreview(process.env.REACT_APP_SERVER_ROOT + result.data.picture);
          } else {
            setEvent({});
          }
        },
        (error) => {
          setEvent({});
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
    title: "New event",
  });

  const [value, setValue] = React.useState<any>(0);
  const theme = useTheme();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const goLink = (link: string) => {};
  const launchDel = (id: any) => {};

  const closeLiveData = () => setLiveData({ ...live_data, onopen: false });
  const [live_data, setLiveData] = React.useState<any>({
    onopen: false,
    onclose: closeLiveData,
  });
  const launchLive = () => {
    setLiveData({ ...live_data, onopen: true });
  };

  const sendLive = (roomId: string) => {
    setLoading(true);
    setLoaded(false);
    let stream_id = roomId;
    const formData = new FormData();
    formData.append("is_event", "1");
    formData.append("is_meeting", "0");
    formData.append("live_id", event?.id);
    formData.append("stream_id", stream_id);
    formData.append("meeting_id", "0");
    formData.append("event_id", event?.id);
    formData.append("category_id", event?.category_id);
    HttpService.postFormHeader("create_live", formData)
      .then(
        (res) => {
          console.log("local_saved_live: ", res);
        },
        (error) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  const startLive = () => {
    setLoading(true);
    setLoaded(false);
    const token = process.env.REACT_APP_VIDEOSDK_TOKEN;
    HttpService.startLive(token)
      .then(
        (res) => {
          console.log("remote live::", res);
          const { roomId } = res;
          sendLive(roomId);
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  return (
    <React.Fragment>
      <div className="account-page-container">
        <div className="page-topper">
          <Breadcrumbs>
            <Link to="/admin">Dashboard</Link>
            <Link to="/admin/events">Events</Link>
            <span>{title || "--"}</span>
          </Breadcrumbs>
        </div>
        <section className="page-detail-container">
          <section className="container py20">
            <Card sx={{ borderRadius: "0" }}>
              {loaded && (
                <Card sx={{ borderRadius: "0" }}>
                  <div className="page-head bga">
                    <div className="flex flex-row-resp align-items-center">
                      <div className="inline-block pxy20 spacer">
                        <h2 className="mt10">{event.title}</h2>
                        <div>
                          <i className="fas fa-timer"></i>
                          <DatePipe value={event.end_date * 1000} />
                        </div>
                      </div>

                      <span className="px20">
                        <ButtonGroup>
                          <Tooltip title="Edit Event">
                            <IconButton
                              onClick={() => goLink("edit")}
                              color="primary"
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete Event">
                            <IconButton
                              onClick={() => launchDel("edit")}
                              color="error"
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Start Live Stream">
                            <IconButton onClick={launchLive} color="success">
                              <StreamOutlined />
                            </IconButton>
                          </Tooltip>
                        </ButtonGroup>
                      </span>
                    </div>
                  </div>

                  <div className="pxy0 bg-differ">
                    <div className=" pxy15">
                      <div className="relative">
                        <div className="highlighted-container">
                          <div className="highlight-banner">
                            <img
                              src={
                                process.env.REACT_APP_SERVER_ROOT +
                                event.picture
                              }
                              alt="events banner"
                            />
                          </div>
                        </div>
                        <Divider />
                        <div className="highlight-details">
                          <div className="h2 flex align-items-center">
                            <i className="fas fa-map-marker-alt mr5"></i>
                            {event.location}
                          </div>
                          <div className="flex meta-span align-items-center">
                            <span>
                              <i className="fas fa-calendar"></i>{" "}
                              <DurationPipe
                                start={event.start_time * 1000}
                                end={event.end_time * 1000}
                              />
                            </span>

                            <span className="txt px5"></span>
                            <span className="spacer"></span>
                            <ul className="reakticons">
                              <li>
                                <Link to="#" onClick={() => null}>
                                  <i className="fas fa-thumbs-up"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="#" onClick={() => null}>
                                  <i className="fas fa-share-alt"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="#" onClick={() => null}>
                                  <i className="fas fa-comment"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <Divider />
                    <Card sx={{ m: "0", p: "0" }}>
                      <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 0, sm: 2, md: 2 }}
                        sx={{
                          display: "flex",
                          backgroundColor: "#fafafa",
                        }}
                      >
                        <Grid item xs={12} sm={6} md={4}>
                          <ListItem divider>
                            <ListItemIcon>
                              <MoneyOutlined sx={{ fontSize: "40px" }} />
                            </ListItemIcon>
                            <ListItemText>
                              <div className="bolder">Physical Attendance</div>
                              <div className="">
                                <Currency value={event.physical_price} />
                              </div>
                            </ListItemText>
                          </ListItem>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <ListItem divider>
                            <ListItemIcon>
                              <DirectionsOutlined sx={{ fontSize: "40px" }} />
                            </ListItemIcon>
                            <ListItemText>
                              <div className="bolder">Venue Directions</div>
                              <div>
                                <a href="/">Open Map</a>
                              </div>
                            </ListItemText>
                          </ListItem>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <ListItem divider>
                            <ListItemIcon>
                              <TimerOutlined sx={{ fontSize: "40px" }} />
                            </ListItemIcon>
                            <ListItemText>
                              <div className="bolder">Event Duration</div>
                              <div>
                                <DurationPipe
                                  start={event.start_time * 1000}
                                  end={event.end_time * 1000}
                                />
                              </div>
                            </ListItemText>
                          </ListItem>
                        </Grid>
                      </Grid>
                      <Divider />
                      <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <Tabs
                            onChange={handleChange}
                            aria-label="lab API tabs example"
                            variant="scrollable"
                            scrollButtons="auto"
                          >
                            <Tab
                              {...a11yProps(0)}
                              label={
                                <span className="flex align-items-center">
                                  <InfoOutlined />
                                  <h3 className="pl5 boldest">Description</h3>
                                </span>
                              }
                              wrapped
                            />
                            <Tab
                              {...a11yProps(1)}
                              label={
                                <span className="flex align-items-center">
                                  <Image />
                                  <h3 className="pl5 boldest">Pictures</h3>
                                </span>
                              }
                            />
                            <Tab
                              {...a11yProps(2)}
                              label={
                                <span className="flex align-items-center">
                                  <VideoCameraFrontOutlined />
                                  <h3 className="pl5 boldest">Videos</h3>
                                </span>
                              }
                            />
                            <Tab
                              {...a11yProps(3)}
                              label={
                                <span className="flex align-items-center">
                                  <CommentOutlined />
                                  <h3 className="pl5 boldest">Comments</h3>
                                </span>
                              }
                            />
                          </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: decodeHtml(event.description),
                            }}
                          ></div>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                          Item One
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                          Item Two
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                          <PostCommentTemplate
                            item={null}
                            update_coms={null}
                            id={null}
                            index={null}
                          />
                        </TabPanel>
                      </TabContext>
                    </Card>
                  </div>
                </Card>
              )}
              {loading && <PlaceHolder type="event-detail" />}
            </Card>
          </section>
        </section>
      </div>
      {live_data.onopen && (
        <Dialog
          onClose={live_data.onclose}
          open={live_data.onopen}
          aria-labelledby={"Me"}
          TransitionComponent={Transition}
        >
          <div className="alert-container">
            <div className={`head-area info-bg bg-info`}>
              <i className={`fas fa-info-circle`}></i>
            </div>
            <div className={`message-area color-info text-center`}>
              {loading && (
                <div className="pxy10">
                  <LinearProgress />
                </div>
              )}
              <Button
                onClick={startLive}
                size="large"
                variant="contained"
                disabled={loading}
              >
                {loading ? "Starting Live..." : "START LIVE STREAM"}
              </Button>
            </div>
            <div className="button-area">
              <div className="flex align-items-center">
                <span className="spacer">
                  <Button
                    fullWidth
                    size="large"
                    onClick={live_data.onclose}
                    sx={{ paddingY: "13px", color: "orange" }}
                  >
                    <span className="px10">CLOSE</span>
                  </Button>
                </span>
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default EventDetail;
