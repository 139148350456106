import React from "react";
import { useNavigate, Routes, Route, Link } from "react-router-dom";
import MessagesDetails from "./MessagesDetails";
import MessagesList from "./MessagesList";
import MessagesNew from "./MessagesNew";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import Add from "@mui/icons-material/Add";

import MessagesReply from "./MessagesReply";
import { Card } from "@mui/material";

const Messages = (props: any) => {
  const navigate = useNavigate();

  const launchNew = () => {
    return navigate(`/account/messages/a/new`);
  };
  return (
    <React.Fragment>
      {/*  <section className="account-page-container">
        <Card>
          <div className="page-topper">
            <Breadcrumbs>
              <Link to="/account">Dashboard</Link>
              <span>Messages</span>
            </Breadcrumbs>
            <div className="flex align-items-center ">
              <h1 className="spacer">All Messages</h1>
              <span className="px10">
                <Tooltip title="Compose New">
                  <Button variant="contained" size="small" onClick={launchNew}>
                    <Add /> <span className="sm-hide-inline">Compose </span> New
                  </Button>
                </Tooltip>
              </span>
            </div>
          </div>
          <section className="page-detail-container"> */}
      <Routes>
        <Route path="" element={<MessagesList />} />
        <Route path="/" element={<MessagesList />} />
        <Route path="/p/:mId" element={<MessagesDetails />} />
        <Route path="/r/:mId" element={<MessagesReply />} />
        <Route path="/a/new" element={<MessagesNew />} />
      </Routes>
      {/* 
          </section>
        </Card>
      </section> */}
    </React.Fragment>
  );
};

export default Messages;
