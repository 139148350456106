import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  Breadcrumbs,
  Button,
  Card,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import HttpService from "../../../services/HttpService";
import React from "react";
import { Delete, Edit, ToggleOff, ToggleOn } from "@mui/icons-material";
import PlaceHolder from "../../templates/PlaceHolder";
import CustomModal from "../../templates/CustomModal";
import ConfirmModal from "../../templates/ConfirmModal";

const NonIndigenesList = () => {
  const [non_indigenes, setNonIndegens] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    getnon_indigenes();
  }, []);
  const getnon_indigenes = () => {
    setLoading(true);
    setLoaded(false);
    HttpService.post({}, "all_non_indigenes")
      .then(
        (res) => {
          console.log(res);
          if (res.status === 1) {
            setNonIndegens(res.data);
          }
        },
        (err) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  const togView = (index: number, state: boolean) => {
    console.log(index, state);
    const mutd = [...non_indigenes];
    const item = (mutd[index]["is_togged"] = !mutd[index]["is_togged"]);
    setNonIndegens(mutd);
  };

  let navigate = useNavigate();
  const togEdit = (i: any) => {
    return navigate(`/admin/non-indigenes/e/${i.id}`);
  };

  const launchNew = (i: any) => {
    return navigate(`/admin/non-indigenes/a/new`);
  };

  const delete_non_indigene = (item: any) => {
    setLoading(true);
    setLoaded(false);
    setDelModal({
      ...del_modal,
      loading: true,
      message: null,
      loading_message: "Working...",
    });
    HttpService.post({ id: item.id }, "remove_non_indigene")
      .then(
        (resp) => {
          if (resp.status === 1) {
            setModal({
              ...modal,
              onopen: true,
              severity: "success",
              message: resp.message,
            });
          }
          setTimeout(() => {
            setDelModal({
              ...del_modal,
              onopen: false,
              onclose: closeDelModal,
            });
            setModal({ ...modal, onopen: false, onclose: closeModal });
          }, 3000);
        },
        (error) => {
          setModal({
            ...modal,
            onopen: true,
            severity: "error",
            message: error.message,
          });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  };

  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
    title: "Edit non_indigene",
    loading_message: null,
    loading: false,
  });

  const activate_non_indigene = (item: any) => {
    setLoading(true);
    setLoaded(false);
    setDelModal({
      ...del_modal,
      loading: true,
      message: null,
      loading_message: "Working...",
    });
    HttpService.post(item, "activate_non_indigene")
      .then(
        (resp) => {
          if (resp.status === 1) {
            setModal({
              ...modal,
              onopen: true,
              severity: "success",
              message: resp.message,
            });
          } else {
            setModal({
              ...modal,
              onopen: true,
              severity: "error",
              message: resp.message,
            });
          }
          setTimeout(() => {
            setDelModal({
              ...del_modal,
              onopen: false,
              onclose: closeDelModal,
            });
            setModal({ ...modal, onopen: false, onclose: closeModal });
          }, 3000);
        },
        (error) => {
          setModal({
            ...modal,
            onopen: true,
            severity: "error",
            message: error.message,
          });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  };

  const closeDelModal = () => {
    setDelModal({ ...del_modal, onopen: false });
  };
  const [del_modal, setDelModal] = React.useState<any>({
    onopen: false,
    onclose: closeDelModal,
    title: "Delete non_indigene",
  });
  const launchDelete = (item: any) => {
    setDelModal({
      ...del_modal,
      id: item.id,
      loading_message: null,
      loading: false,
      message:
        "Are you sure you want to <strong><u>remove</u></strong> this non_indigene?",
      onopen: true,
      onclose: closeDelModal,
      onaccept: delete_non_indigene,
    });
  };
  const launchActivate = (item: any) => {
    const act = item.is_activated == "1" ? "deactivate" : "activate";
    setDelModal({
      ...del_modal,
      id: item.id,
      action: act,
      title: act + " non_indigene",
      message: `Are you sure you want to <strong><u>${act}</u></strong> this non_indigene?`,
      onopen: true,
      loading_message: null,
      loading: false,
      onclose: closeDelModal,
      onaccept: () => activate_non_indigene({ id: item.id, action: act }),
    });
  };
  return (
    <div className="account-page-container">
      <Card>
        <div className="page-topper">
          <Breadcrumbs>
            <Link to="/admin">Dashboard</Link>
            <span>Non Indigenes</span>
          </Breadcrumbs>
          <h1> All Non Indigenes</h1>
        </div>
        <section className="page-detail-container">
          <div className="flex flex-row border-bottom align-items-center pxy10">
            <span className="spacer"></span>
            <span>
              <Button variant="outlined" size="small" onClick={launchNew}>
                New
              </Button>
            </span>
          </div>

          {non_indigenes.map((item: any, index: number) => (
            <ListItem
              disablePadding
              key={index}
              divider={index < non_indigenes.length - 1 ? true : false}
            >
              <ListItemButton sx={{ py: "1px" }}>
                <ListItemAvatar>
                  <Avatar
                    sx={{ height: "26px", width: "26px" }}
                    variant="circular"
                    src={process.env.REACT_APP_SERVER_ROOT + item.thumb}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography component="div">
                      <div className="flex flex-row align-items-center">
                        <div
                          className="spacer bolder"
                          style={{ lineHeight: "1", color: "#000" }}
                        >
                          <Link to={`/admin/non-indigenes/e/${item.id}`}>
                            {item?.surname + " " + item?.firstname}
                          </Link>
                        </div>
                        <span>
                          <Tooltip title="Edit non indigene">
                            <IconButton
                              sx={{ padding: "1px" }}
                              onClick={() => togEdit(item)}
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Remove non indigene">
                            <IconButton
                              color="warning"
                              sx={{ padding: "1px" }}
                              onClick={() => launchDelete(item)}
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title={
                              item.is_activated == "1"
                                ? "Deactivate non indigene"
                                : "Activate non indigene"
                            }
                          >
                            <IconButton
                              color={
                                item.is_active == "1" ? "default" : "primary"
                              }
                              sx={{ padding: "1px" }}
                              onClick={() => launchActivate(item)}
                            >
                              {item.is_active == "1" ? (
                                <ToggleOff />
                              ) : (
                                <ToggleOn />
                              )}
                            </IconButton>
                          </Tooltip>
                        </span>
                      </div>
                    </Typography>
                  }
                ></ListItemText>
              </ListItemButton>
            </ListItem>
          ))}

          {loading && (
            <div className="px10">
              <PlaceHolder type="list" />
              <Divider />
              <PlaceHolder type="list" />
              <Divider />
              <PlaceHolder type="list" />
              <Divider />
              <PlaceHolder type="list" />
              <Divider />
              <PlaceHolder type="list" />
              <Divider />
              <PlaceHolder type="list" />
              <Divider />
              <PlaceHolder type="list" />
              <Divider />
              <PlaceHolder type="list" />
            </div>
          )}
        </section>
      </Card>
      <CustomModal data={modal} />
      <ConfirmModal data={del_modal} loading={loading} />
    </div>
  );
};

export default NonIndigenesList;
