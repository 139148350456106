import React from "react";
import { DefaultEditor } from "react-simple-wysiwyg";
import Button from "@mui/material/Button";
import HttpService from "../../services/HttpService";
import * as processHtml from "../../services/processHtml";
import AuthService from "../../services/AuthService";
import CustomModal from "./CustomModal";
import PromptLogin from "./PromptLogin";
import {
  Avatar,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Close,
  MailOutlineOutlined,
  ReplyAll,
  Search,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import CustomToast from "./CustomToast";

const FindUser = (props: any) => {
  const {
    feed_back,
    class_style,
    button_text,
    input_text_placeholder,
    selection_mode,
  } = props;
  const onToastClose = () => {
    setToast({ onopen: false, onclose: onToastClose });
  };
  const [toast, setToast] = React.useState<any>({
    onopen: false,
    onclose: onToastClose,
    message: "",
  });

  const [users, setUsers] = React.useState<any>(null);
  const [name, setName] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  const handleSubmit = () => {
    console.log(name);

    if (!name || name === "") {
      setToast({
        ...toast,
        onopen: true,
        onclose: onToastClose,
        severity: "error",
        message: "Please member name or email address..",
      });
      return;
    }
    const formData = new FormData();
    setLoading(true);
    setLoaded(false);

    HttpService.postHeader("find_member", { term: name })
      .then(
        (resp) => {
          console.log("found_data::", resp);
          setToast({ ...toast, onopen: true, message: resp.message });
          if (resp.status === 1) {
            setUsers(resp.data);
            /*  setTimeout(() => {
              togForm();
              setToast({ ...toast, onopen: false });
            }, 3000); */
          } else {
            setUsers(null);
          }
        },
        (error) => {
          setToast({ ...toast, onopen: true, message: error.message });
          setUsers(null);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //postTicket
  };

  const [show_form, setShow] = React.useState<boolean>(false);

  const togForm = (): void => {
    setShow(!show_form);
  };

  const handleName = (event: any) => {
    const targ = event.target;
    const name = targ.name;
    const value = targ.value;
    console.log(name, value);
    setName(value);
    console.log(name);
  };
  const doSelect = (data: any) => {
    let item = {
      id: data.id,
      email: data.email,
      name: data.name,
      mobile: data.mobile,
      thumb: data.thumb,
    };
    feed_back(item);
    if (!selection_mode || selection_mode === "single") {
      togForm();
    }
  };
  return (
    <React.Fragment>
      <Tooltip
        title={
          button_text && button_text !== "" ? button_text : "Search Members"
        }
      >
        <Button onClick={togForm} sx={class_style}>
          <Search />
          {button_text && button_text !== "" ? (
            <span className="pl5">{button_text}</span>
          ) : (
            ""
          )}
        </Button>
      </Tooltip>
      <Dialog open={show_form}>
        <div className="dialog-header">
          <span className="title-head">
            <i className="fas fa-search pr5"></i>
            Find Member
          </span>
          <span className="spacer"></span>
          <Link onClick={togForm} to={`#`}>
            <Close />
          </Link>
        </div>
        <div className={`input  px10 py0 mb10 mt5`}>
          <input
            type="text"
            disabled={loading}
            className="input-form-control py10"
            style={{ padding: "10px 15px !important" }}
            name="name"
            onChange={handleName}
            placeholder={
              input_text_placeholder && input_text_placeholder !== ""
                ? input_text_placeholder
                : "Member name or email"
            }
          />
        </div>
        <Divider />
        <DialogContent sx={{ p: "0" }}>
          <div className="alert-container">
            <div className="mb10 px0 flex-col">
              <Collapse in={users ? true : false}>
                {users &&
                  users.map((item: any) => (
                    <ListItem
                      disablePadding
                      button
                      key={item.email}
                      divider
                      secondaryAction={<span className="date-span">--</span>}
                    >
                      <ListItemButton
                        sx={{ py: "4px" }}
                        onClick={() => doSelect(item)}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{ width: 40, height: 40 }}
                            src={process.env.REACT_APP_SERVER_ROOT + item.thumb}
                            alt={item.name}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <div className="flex align-items-center">
                              <Typography
                                sx={{
                                  textOverflow: "ellipsis !Important",
                                  width: "100%",
                                  lineHeight: "1.1",
                                  fontWeight: "700",
                                  whiteSpace: "nowrap",
                                  pr: "80px",
                                }}
                                component={"h2"}
                              >
                                {item.name}
                              </Typography>
                            </div>
                          }
                          secondary={
                            <Typography
                              sx={{
                                textOverflow: "ellipsis !Important",
                                width: "auto",
                                whiteSpace: "nowrap",
                                pr: "8px",
                                lineHeight: "1.01",
                              }}
                              component={"em"}
                              className="dotted-underlined date-class boldest"
                            >
                              {item.email || "Annonymous"}
                            </Typography>
                          }
                        ></ListItemText>
                      </ListItemButton>
                    </ListItem>
                  ))}
              </Collapse>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="flex align-items-center">
            <Button
              type="submit"
              size="medium"
              variant="contained"
              disabled={loading}
              onClick={handleSubmit}
            >
              {loading ? "Working..." : " Search Member "}
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <CustomToast data={toast} />
    </React.Fragment>
  );
};

export default React.memo(FindUser);
