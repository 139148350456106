import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import Card from "@mui/material/Card";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import SeoModule from "../../../services/SeoModule";
import useFetchPage from "../../../hooks/useFetchPage";
import * as processHtml from "../../../services/processHtml";
import { LinearProgress } from "@mui/material";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import PageIntro from "../PageIntro/PageIntro";
import GalleryList from "./GalleryList";
import GalleryDetail from "./GalleryDetail";
import GalleryCategory from "./GalleryCategory";

const Gallery = () => {
  const { decodeHtml, truncateWord } = processHtml;
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <section className="bg-diff">
        <Routes>
          <Route path="" element={<GalleryList />} />
          <Route path="/" element={<GalleryList />} />
          <Route path="/p/:gId" element={<GalleryDetail />} />
          <Route path="/:catUrl" element={<GalleryCategory />} />
        </Routes>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default Gallery;
