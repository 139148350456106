import {
  Breadcrumbs,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
} from "@mui/material";
import React from "react";
import { Link, NavLink, Route, Routes, useParams } from "react-router-dom";
import EditPage from "./EditPage";
import HttpService from "../../../services/HttpService";
import {
  Add,
  AddAPhotoOutlined,
  ArrowRightAltOutlined,
  Close,
  Edit,
} from "@mui/icons-material";
import { DefaultEditor } from "react-simple-wysiwyg";

import useFetchGroup from "../../../hooks/useFetchGroup";
import MemberListTemplate from "../../templates/MemberListTemplate";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddMember from "../../templates/AddMember";
import useFetchCommittees from "../../../hooks/useFetchCommittees";
import CustomToast from "../../templates/CustomToast";
import CreateGroup from "../../templates/CreateGroup";

//
const Committees = () => {
  const onToastClose = () => {
    setToast({ onopen: false, onclose: onToastClose });
  };
  const [toast, setToast] = React.useState<any>({
    onopen: false,
    onclose: onToastClose,
    message: "",
    platform: "committees",
  });

  return (
    <React.Fragment>
      <div className="page-topper">
        <div className="flex flex-row-resp">
          <div className="spacer">
            <Breadcrumbs>
              <Link to="/admin">Dashboard</Link>
              <Link to="/admin/about">About</Link>
              <Link to="/admin/about/committees">Committees</Link>
            </Breadcrumbs>
            <h1 className="mb20">Committees</h1>
          </div>

          <span className="px20"></span>
        </div>
      </div>
      <div className="account-page-container">
        <Routes>
          <Route path="" element={<HomeView />} />
          <Route path="/" element={<HomeView />} />
          <Route path="/p/:mId" element={<ViewDetail />} />
          <Route path="/e/:mId" element={<EditView />} />
        </Routes>
      </div>

      {toast.onopen && <CreateGroup data={toast} />}
    </React.Fragment>
  );
};

const HomeView = () => {
  const [show_form, setShow] = React.useState<boolean>(false);

  const togForm = React.useCallback((): void => {
    setShow(!show_form);
  }, [show_form]);

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleExpand =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const { group, loading_group, loaded_group } = useFetchGroup({
    slug: "committees",
    group_id: 0,
    has_members: true,
  });

  const closeCreate = () => setCreate({ ...create, onopen: false });
  const [create, setCreate] = React.useState<any>({
    onopen: false,
    platform: "committees",
    onclose: closeCreate,
  });
  const lauchNewCommittee = () => {
    setCreate({
      ...create,
      group_id: group?.group_id,
      onopen: true,
      onclose: closeCreate,
    });
  };
  return (
    <React.Fragment>
      <Card>
        <section className="page-detail-container">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleExpand("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography
                component="div"
                className="spacer flex align-items-center py0"
              >
                <h3 className="spacer py0"> List of Committees</h3>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex flex-col">
                <div className="flex align-items-center">
                  <span className="spacer"></span>
                  <span className="pl10 py10">
                    <Button
                      size="small"
                      onClick={lauchNewCommittee}
                      variant="outlined"
                    >
                      <Add /> New Committee
                    </Button>
                  </span>
                </div>
                <Divider />
                <section>
                  {loaded_group &&
                    group?.members.map((item: any) => (
                      <ListItem
                        key={item.id}
                        component={NavLink}
                        to={`/admin/about/committees/p/${item.id}`}
                        divider
                        disablePadding
                        secondaryAction={
                          <AddMember platform="committees" group_id={item.id} />
                        }
                      >
                        <ListItemButton>
                          <Typography component="h3" sx={{ fontWeight: "700" }}>
                            {item.title}
                          </Typography>
                        </ListItemButton>
                      </ListItem>
                    ))}
                </section>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleExpand("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography component="div">
                <h3>Introduction</h3>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <section className="page-detail-container">
                <EditPage slug="committees" />
              </section>
            </AccordionDetails>
          </Accordion>
        </section>
      </Card>
      {create.onopen && <CreateGroup data={create} />}
    </React.Fragment>
  );
};

const ListView = () => {
  const { group, loading_group, loaded_group } = useFetchGroup({
    slug: "committees",
  });
  return (
    <section className="pt20">
      {loaded_group && (
        <MemberListTemplate
          loading={loading_group}
          loaded={loaded_group}
          members={group}
          max_grid={4}
          list_page="position"
        />
      )}
    </section>
  );
};

const EditView = () => {
  return <React.Fragment>Edit</React.Fragment>;
};
const ViewDetail = () => {
  const param = useParams();
  const [view, setView] = React.useState<any>(null);
  console.log(param);
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const cueHttp = (mode: string) => {
    setLoading(mode === "start" ? true : false);
    setLoaded(mode === "start" ? false : true);
  };
  React.useEffect(() => {
    if (param.mId) {
      get_com(param.mId);
    }
  }, []);
  const get_com = (mid: any) => {
    HttpService.postHeader("get_grouped_listing", {
      slug: "committees",
      group_id: mid,
      has_members: true,
    })
      .then(
        (res) => {
          console.log(res);
          if (res.status === 1) {
            setView(res.data);
          }
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        cueHttp("end");
      });
  };
  return (
    <React.Fragment>
      <section>
        <div className="flex align-items-center">
          <span className="spacer"></span>
          <span className="px10">
            <AddMember platform="committees" group_id={view?.id} />
            <IconButton href={`/admin/about/committees/e/${view?.id}`}>
              <Edit />
            </IconButton>
          </span>
        </div>
        <Divider />

        <div className="pxy20">
          {loaded && (
            <MemberListTemplate
              loading={loading}
              loaded={loaded}
              members={view?.members}
              max_grid={4}
              list_page="position"
            />
          )}
        </div>
      </section>
    </React.Fragment>
  );
};

export default Committees;
