import React from "react";
import { Link, NavLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import * as processHtml from "../../services/processHtml";
import { CommentOutlined } from "@mui/icons-material";
import { Card, Divider, Tooltip } from "@mui/material";
import PlaceHolder from "./PlaceHolder";
import LikeTemplate from "./LikeTemplate";
import ShareTemplate from "./ShareTemplate";
const GalleryListTemplate = (props: any) => {
  const { gallery, loading, loaded, max_grid, base_url } = props;
  const [list, setList] = React.useState<any[]>(gallery);
  const base_link = base_url ? "/" + base_url + "/" : "/";
  const { decodeHtml } = processHtml;
  const togView = (index: number, state: boolean) => {
    console.log(index, state);
    const mutd = [...list];
    const item = (mutd[index]["is_togged"] = !mutd[index]["is_togged"]);
    setList(mutd);
  };

  const ucfirst = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const FILE_URL = process.env.REACT_APP_SERVER_ROOT;
  return (
    <React.Fragment>
      <div className="">
        {loaded && (
          <>
            <Grid container spacing={2}>
              {/**<Grid item xs={12} sm={6} md={3} key={item.id}> */}
              {list.map((item: any, index: number) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={max_grid ? max_grid : 3}
                  key={item.id}
                >
                  <Card>
                    <div className={"gallery-card"}>
                      <Link
                        className="src_pane"
                        to={`${base_link}gallery/p/${item.id}`}
                      >
                        {/*   {item.src && (
                          <img
                            src={FILE_URL + item.src}
                            alt={item.title}
                          />
                        )}
 */}
                        {item.type === "video" && (
                            <div className="iframe-box">
                          <iframe
                            width="560"
                            height="315"
                            src={`https://www.youtube.com/embed/${
                              FILE_URL + item.src
                            }`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe></div>
                        )}
                        {item.type !== "video" && (
                          <img src={FILE_URL + item.src} alt={item.caption} />
                        )}

                        <Divider />
                        <div className="highlight-details">
                          <div className="h3 flex align-items-center">
                            <i className="fas fa-map-marker-alt mr5"></i>
                            {item.caption}
                          </div>
                        </div>
                      </Link>

                      <div className="react-span px10">
                        <span className="spacer">
                          <Tooltip
                            arrow
                            placement="top-start"
                            title={`${item.comment_num} comments`}
                          >
                            <IconButton className="react-button">
                              <CommentOutlined className="react-icon" />
                            </IconButton>
                          </Tooltip>
                          <span className="count-span">{item.comment_num}</span>
                        </span>
                        <span className="pr5">
                          <LikeTemplate item={item} item_label={"gallery"} />
                        </span>
                        <span>
                          <ShareTemplate item={item} item_label={"gallery"} />
                        </span>
                      </div>
                    </div>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        )}

        {loading && <PlaceHolder type="articles" />}
      </div>
    </React.Fragment>
  );
};

export default React.memo(GalleryListTemplate);
