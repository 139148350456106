import {
  Breadcrumbs,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
} from "@mui/material";
import React from "react";
import { Link, NavLink, Route, Routes, useParams } from "react-router-dom";
import EditPage from "./EditPage";
import HttpService from "../../../services/HttpService";
import {
  Add,
  AddAPhotoOutlined,
  ArrowRightAltOutlined,
  Close,
  Edit,
} from "@mui/icons-material";
import { DefaultEditor } from "react-simple-wysiwyg";

import useFetchGroup from "../../../hooks/useFetchGroup";
import MemberListTemplate from "../../templates/MemberListTemplate";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddMember from "../../templates/AddMember";
import CustomToast from "../../templates/CustomToast";
import useFetchExecutives from "../../../hooks/useFetchExecutives";

//
const Executives = () => {
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [meta, setMeta] = React.useState<any>({ name: "" });
  const [description, setDescription] = React.useState<string>("");
  const cueHttp = (mode: string) => {
    setLoading(mode === "start" ? true : false);
    setLoaded(mode === "start" ? false : true);
  };
  const onToastClose = () => {
    setToast({ onopen: false, onclose: onToastClose });
  };
  const [toast, setToast] = React.useState<any>({
    onopen: false,
    onclose: onToastClose,
    message: "",
  });
  const create_new = () => {
    cueHttp("start");
    if (!meta.title || description === "") {
      setToast({
        ...toast,
        onopen: true,
        onclose: onToastClose,
        severity: "error",
        message: "Please supply executives name and description..",
      });
      return;
    }
    const load = { ...meta, description: description };
    HttpService.postHeader("create_executives", load)
      .then(
        (res) => {
          console.log(res);
          const sever = res.status === 1 ? "success" : "error";
          setToast({
            ...toast,
            onopen: true,
            onclose: onToastClose,
            severity: sever,
            message: res.message,
          });
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        cueHttp("end");
      });
  };

  const onHtmlChange = (e: any) => {
    setDescription(e.target.value);
    console.log(description);
  };
  const handleInputChange = React.useCallback(
    (event: any) => {
      const targ = event.target;
      const name = targ.name;
      const value = targ.value;
      console.log(name, value);
      setMeta({ ...meta, [name]: value });
      console.log(meta);
    },
    [meta]
  );

  const [show_form, setShow] = React.useState<boolean>(false);

  const togForm = React.useCallback((): void => {
    setShow(!show_form);
  }, [show_form]);
  return (
    <React.Fragment>
      <div className="page-topper">
        <div className="flex flex-row-resp">
          <div className="spacer">
            <Breadcrumbs>
              <Link to="/admin">Dashboard</Link>
              <Link to="/admin/about">About</Link>
              <Link to="/admin/about/executives">Executives</Link>
            </Breadcrumbs>
            <h1 className="mb20">Executives</h1>
          </div>

          <span className="px20">
            <Button variant="outlined" onClick={togForm} size="small">
              <Add /> New
            </Button>
          </span>
        </div>
      </div>
      <div className="account-page-container">
        <Routes>
          <Route path="" element={<HomeView />} />
          <Route path="/" element={<HomeView />} />
          <Route path="/p/:mId" element={<ViewDetail />} />
          <Route path="/e/:mId" element={<EditView />} />
        </Routes>
      </div>
      <Dialog open={show_form}>
        <div className="dialog-header">
          <span className="title-head">
            <AddAPhotoOutlined />
            <span className="spacer pl5">New Committee</span>
          </span>
          <span className="spacer"></span>
          <Link onClick={togForm} to={`#`}>
            <Close />
          </Link>
        </div>
        <DialogContent sx={{ paddingTop: "0" }}>
          <div className={`alert-container`}>
            <div className="mb10 mt0">
              <div className={`input`}>
                <label>Committe name</label>
                <input
                  type="text"
                  disabled={loading}
                  className="form-control"
                  name="title"
                  onChange={handleInputChange}
                  placeholder={"Enter Committe name..."}
                />
              </div>
              <div className="mb0">
                <DefaultEditor
                  disabled={loading}
                  className="form-control"
                  value={description}
                  placeholder="Description"
                  onChange={onHtmlChange}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="dialog-actions border-top">
          <div className="flex align-items-center">
            <span className="px10 spacer">
              <Button
                type="submit"
                size="medium"
                variant="contained"
                disabled={loading || meta.title === "" || description === ""}
                onClick={create_new}
              >
                {loading ? "Working..." : " Create Committee "}
              </Button>
            </span>
          </div>
        </DialogActions>
      </Dialog>
      {toast.onopen && <CustomToast data={toast} />}
    </React.Fragment>
  );
};

const HomeView = () => {
  const [show_form, setShow] = React.useState<boolean>(false);

  const togForm = React.useCallback((): void => {
    setShow(!show_form);
  }, [show_form]);

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleExpand =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const { executives, loading_executives, loaded_executives } =
    useFetchExecutives("executives");
  return (
    <React.Fragment>
      <Card>
        <section className="page-detail-container">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleExpand("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="div">
                <h3> List of Executives</h3>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex flex-col">
                <div className="flex py5">
                  <span className="spacer"></span>
                  <span className="pl10"></span>
                </div>
                <Divider />
                <section>
                  {executives.map((item: any) => (
                    <ListItem
                      key={item.id}
                      component={NavLink}
                      to={`/admin/about/executives/p/${item.id}`}
                      divider
                      disablePadding
                      secondaryAction={<ArrowRightAltOutlined />}
                    >
                      <ListItemButton>
                        <Typography component="h3" sx={{ fontWeight: "700" }}>
                          {item.title}
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </section>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleExpand("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography component="div">
                <h3>Introduction</h3>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <section className="page-detail-container">
                <EditPage slug="executives" />
              </section>
            </AccordionDetails>
          </Accordion>
        </section>
      </Card>
    </React.Fragment>
  );
};

const ListView = () => {
  const { group, loading_group, loaded_group } = useFetchGroup({
    slug: "executives",
  });
  return (
    <section className="pt20">
      {loaded_group && (
        <MemberListTemplate
          loading={loading_group}
          loaded={loaded_group}
          members={group}
          max_grid={4}
          list_page="position"
        />
      )}
    </section>
  );
};

const EditView = () => {
  return <React.Fragment>Edit</React.Fragment>;
};
const ViewDetail = () => {
  const param = useParams();
  const [view, setView] = React.useState<any>(null);
  console.log(param);
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const cueHttp = (mode: string) => {
    setLoading(mode === "start" ? true : false);
    setLoaded(mode === "start" ? false : true);
  };
  React.useEffect(() => {
    if (param.mId) {
      get_com(param.mId);
    }
  }, []);
  const get_com = (mid: any) => {
    HttpService.postHeader("executives_detail", { id: mid })
      .then(
        (res) => {
          console.log(res);
          if (res.status === 1) {
            setView(res.data);
          }
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        cueHttp("end");
      });
  };
  return (
    <React.Fragment>
      <section>
        <div className="flex align-items-center">
          <span className="spacer"></span>
          <span className="px10">
            <AddMember platform="executives" group_id={view?.id} />
            <IconButton href={`/admin/about/executives/e/${view?.id}`}>
              <Edit />
            </IconButton>
          </span>
        </div>
        <Divider />

        <div className="pxy20">
          {loaded && (
            <MemberListTemplate
              loading={loading}
              loaded={loaded}
              members={view?.members}
              max_grid={4}
              list_page="position"
            />
          )}
        </div>
      </section>
    </React.Fragment>
  );
};

export default Executives;
