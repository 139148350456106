import React from "react";
import { Slide, Dialog } from "@mui/material";
import EditProfile from "../Account/Settings/EditProfile";
import EditPassword from "../Account/Settings/EditPassword";
import UploadPicture from "../Account/Settings/UploadPicture";

const Transition = React.forwardRef(function Transition(props: any, ref: any) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NullCom = () => {
  return <>Null</>;
};

const DetailChange = (data: any) => {
  return <EditProfile {...data} />;
};
const PasswordChange = (data: any) => {
  return <EditPassword {...data} />;
};
const PictureChange = (data: any) => {
  return <UploadPicture {...data} />;
};
const comps: any[] = [DetailChange, PasswordChange, PictureChange];

const CustomComponent = (props: any) => {
  const val = props.data;
  console.log(props);
  const type = val.type;
  const CusComponent = val.type || val.type === 0 ? comps[type] : NullCom;
  console.log(CusComponent);

  const handler = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({ handler: handler });

  React.useEffect(() => {
    if (val) {
      setModal({ ...modal, ...val });
    }
  }, [val]);
  return (
    <Dialog open={modal.onopen} aria-labelledby={"Upload Picture"} id={"md-x"}>
      <CusComponent {...modal} />
    </Dialog>
  );
};

export default React.memo(CustomComponent);
