import React from "react";
import PlaceHolder from "../../templates/PlaceHolder";
import Button from "@mui/material/Button";
import HttpService from "../../../services/HttpService";
import * as processHtml from "../../../services/processHtml";

import Header from "../../Header/Header";
import PageIntro from "../PageIntro/PageIntro";
import Footer from "../../Footer/Footer";
import { Card } from "@mui/material";
import MeetingsListTemplate from "../../templates/MeetingsListTemplate";

const MeetingsList = (props: any) => {
  const { decodeHtml, truncateWord } = processHtml;
  const [offset, setOffset] = React.useState<number>(0);
  const [meetings, setMeetings] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    listMeetings(0);
  }, []);

  const listMeetings = (offset: number) => {
    setLoading(true);
    setLoaded(false);
    console.log("Loading meetings..");
    HttpService.postHeader("all_meetings", { offset: offset, limit: 24 })
      .then(
        (result) => {
          console.log(result);
          if (Array.isArray(result.data)) {
            let newRes = [...meetings, ...result.data];
            setMeetings(result.data);
          } else {
            setMeetings([]);
          }
        },
        (error) => {
          setMeetings([]);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const loadMore = () => {
    const newOffset = offset + 20;
    setOffset(newOffset);
    listMeetings(newOffset);
  };

  const [default_subject, setDefSub] = React.useState("");
  const [cur_tab, setTab] = React.useState<number>(0);
  const doStep = (index: number) => {
    setTab(index);
  };

  return (
    <React.Fragment>
      <section className="page-main">
        <div className="home-overlay"></div>
        <Header />
        <PageIntro
          title="All meetings"
          summary="meetings page"
          loading_page={false}
        />
      </section>
      <section className="container py20">
        <div className="pxy20">
          {loaded && (
            <MeetingsListTemplate
              loading={loading}
              loaded={loaded}
              meetings={meetings}
              max_grid={4}
            />
          )}
          {loading && <PlaceHolder type="articles" max_grid={4} />}
          <div className="flex justify-content-center align-items-center py20">
            <Button
              disabled={loading}
              size="large"
              variant="contained"
              onClick={loadMore}
            >
              {loading ? "Loading..." : "Load More"}
            </Button>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default MeetingsList;
