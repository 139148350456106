import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  Breadcrumbs,
  Button,
  Card,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tooltip,
} from "@mui/material";
import HttpService from "../../../services/HttpService";
import React from "react";
import { Delete, Edit, ToggleOff, ToggleOn } from "@mui/icons-material";
import PlaceHolder from "../../templates/PlaceHolder";
import CustomModal from "../../templates/CustomModal";
import ConfirmModal from "../../templates/ConfirmModal";

const CasesList = () => {
  const [cases, setCases] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    getcases();
  }, []);
  const getcases = () => {
    setLoading(true);
    setLoaded(false);
    HttpService.post({}, "all_cases")
      .then(
        (res) => {
          console.log(res);
          if (res.status === 1) {
            setCases(res.data);
          }
        },
        (err) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  const togView = (index: number, state: boolean) => {
    console.log(index, state);
    const mutd = [...cases];
    const item = (mutd[index]["is_togged"] = !mutd[index]["is_togged"]);
    setCases(mutd);
  };

  let navigate = useNavigate();
  const togEdit = (i: any) => {
    return navigate(`/admin/cases/e/${i.id}`);
  };

  const launchNew = (i: any) => {
    return navigate(`/admin/cases/a/new`);
  };

  const delete_case = (item: any) => {
    setLoading(true);
    setLoaded(false);
    setDelModal({
      ...del_modal,
      loading: true,
      message: null,
      loading_message: "Working...",
    });
    HttpService.post({ id: item.id }, "remove_case")
      .then(
        (resp) => {
          if (resp.status === 1) {
            setModal({
              ...modal,
              onopen: true,
              severity: "success",
              message: resp.message,
            });
          }
          setTimeout(() => {
            setDelModal({
              ...del_modal,
              onopen: false,
              onclose: closeDelModal,
            });
            setModal({ ...modal, onopen: false, onclose: closeModal });
          }, 3000);
        },
        (error) => {
          setModal({
            ...modal,
            onopen: true,
            severity: "error",
            message: error.message,
          });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  };

  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
    title: "Edit case",
    loading_message: null,
    loading: false,
  });

  const activate_case = (item: any) => {
    setLoading(true);
    setLoaded(false);
    setDelModal({
      ...del_modal,
      loading: true,
      message: null,
      loading_message: "Working...",
    });
    HttpService.post(item, "activate_case")
      .then(
        (resp) => {
          if (resp.status === 1) {
            setModal({
              ...modal,
              onopen: true,
              severity: "success",
              message: resp.message,
            });
          } else {
            setModal({
              ...modal,
              onopen: true,
              severity: "error",
              message: resp.message,
            });
          }
          setTimeout(() => {
            setDelModal({
              ...del_modal,
              onopen: false,
              onclose: closeDelModal,
            });
            setModal({ ...modal, onopen: false, onclose: closeModal });
          }, 3000);
        },
        (error) => {
          setModal({
            ...modal,
            onopen: true,
            severity: "error",
            message: error.message,
          });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  };

  const closeDelModal = () => {
    setDelModal({ ...del_modal, onopen: false });
  };
  const [del_modal, setDelModal] = React.useState<any>({
    onopen: false,
    onclose: closeDelModal,
    title: "Delete case",
  });
  const launchDelete = (item: any) => {
    setDelModal({
      ...del_modal,
      id: item.id,
      loading_message: null,
      loading: false,
      message:
        "Are you sure you want to <strong><u>remove</u></strong> this case?",
      onopen: true,
      onclose: closeDelModal,
      onaccept: delete_case,
    });
  };
  const launchActivate = (item: any) => {
    const act = item.is_activated == "1" ? "deactivate" : "activate";
    setDelModal({
      ...del_modal,
      id: item.id,
      action: act,
      title: act + " case",
      message: `Are you sure you want to <strong><u>${act}</u></strong> this case?`,
      onopen: true,
      loading_message: null,
      loading: false,
      onclose: closeDelModal,
      onaccept: () => activate_case({ id: item.id, action: act }),
    });
  };
  return (
    <div className="account-page-container">
      <Card>
        <div className="page-topper">
          <Breadcrumbs>
            <Link to="/admin">Dashboard</Link>
            <span>Cases</span>
          </Breadcrumbs>
          <h1> All Cases</h1>
        </div>
        <section className="page-detail-container">
          <div className="flex flex-row border-bottom align-items-center pxy10">
            <span className="spacer"></span>
            <span>
              <Button variant="outlined" size="small" onClick={launchNew}>
                New
              </Button>
            </span>
          </div>

          {cases.map((item: any, index: number) => (
            <ListItem
              disablePadding
              key={index}
              divider={index < cases.length - 1 ? true : false}
            >
              <ListItemButton sx={{ py: "1px" }}>
                <ListItemAvatar>
                  <Avatar
                    sx={{ height: "26px", width: "26px" }}
                    variant="circular"
                    src={process.env.REACT_APP_SERVER_ROOT + item.thumb}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <div className="flex flex-row align-items-center">
                      <div className="spacer" style={{ lineHeight: "1" }}>
                        <Link to={`/admin/cases/e/${item.id}`}>
                          {item?.title}
                        </Link>
                      </div>
                      <span>
                        <Tooltip title="Edit case">
                          <IconButton
                            sx={{ p: "1" }}
                            size="small"
                            onClick={() => togEdit(item)}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Remove case">
                          <IconButton
                            sx={{ p: "1" }}
                            color="warning"
                            size="small"
                            onClick={() => launchDelete(item)}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title={
                            item.is_activated == "1"
                              ? "Deactivate case"
                              : "Activate case"
                          }
                        >
                          <IconButton
                            color={
                              item.is_active == "1" ? "default" : "primary"
                            }
                            size="small"
                            sx={{ p: "1" }}
                            onClick={() => launchActivate(item)}
                          >
                            {item.is_active == "1" ? (
                              <ToggleOff />
                            ) : (
                              <ToggleOn />
                            )}
                          </IconButton>
                        </Tooltip>
                      </span>
                    </div>
                  }
                ></ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
          {loading && <PlaceHolder type="horizontal_list" />}
        </section>
      </Card>
      <CustomModal data={modal} />
      <ConfirmModal data={del_modal} loading={loading} />
    </div>
  );
};

export default CasesList;
