import React from "react";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { DefaultEditor } from "react-simple-wysiwyg";
import Button from "@mui/material/Button";
import HttpService from "../../services/HttpService";
import { Close, GroupAddOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import CustomToast from "./CustomToast";

const CreateGroup = (props: any) => {
  const modal = props.data;
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [meta, setMeta] = React.useState<any>({
    name: "",
    group_id: modal?.group_id,
    platform: modal?.platform,
  });
  const [description, setDescription] = React.useState<string>("");
  const cueHttp = (mode: string) => {
    setLoading(mode === "start" ? true : false);
    setLoaded(mode === "start" ? false : true);
  };
  const onToastClose = () => {
    setToast({ onopen: false, onclose: onToastClose });
  };
  const [toast, setToast] = React.useState<any>({
    onopen: false,
    onclose: onToastClose,
    message: "",
  });
  const create_new = () => {
    cueHttp("start");
    if (!meta.title || description === "") {
      setToast({
        ...toast,
        onopen: true,
        onclose: onToastClose,
        severity: "error",
        message: "Please supply group name and description..",
      });
      return;
    }
    const load = { ...meta, description: description };
    console.log(load);
    HttpService.postHeader("create_group", load)
      .then(
        (res) => {
          console.log(res);
          const sever = res.status === 1 ? "success" : "error";
          setToast({
            ...toast,
            onopen: true,
            onclose: onToastClose,
            severity: sever,
            message: res.message,
          });
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        cueHttp("end");
      });
  };

  const onHtmlChange = (e: any) => {
    setDescription(e.target.value);
    console.log(description);
  };
  const handleInputChange = React.useCallback(
    (event: any) => {
      const targ = event.target;
      const name = targ.name;
      const value = targ.value;
      console.log(name, value);
      setMeta({ ...meta, [name]: value });
      console.log(meta);
    },
    [meta]
  );

  const [show_form, setShow] = React.useState<boolean>(false);

  const togForm = React.useCallback((): void => {
    setShow(!show_form);
  }, [show_form]);
  return (
    <React.Fragment>
      <Dialog open={modal.onopen}>
        <div className="dialog-header">
          <span className="title-head">
            <GroupAddOutlined />
            <span className="spacer pl5">New Group</span>
          </span>
          <span className="spacer"></span>
          <Link onClick={modal.onclose} to={`#`}>
            <Close />
          </Link>
        </div>
        <DialogContent sx={{ paddingTop: "0" }}>
          <div className={`alert-container`}>
            <div className="mb10 mt0">
              <div className={`input`}>
                <label>Group name</label>
                <input
                  type="text"
                  disabled={loading}
                  className="form-control"
                  name="title"
                  onChange={handleInputChange}
                  placeholder={"Enter Group name..."}
                />
              </div>
              <div className="mb0">
                <DefaultEditor
                  disabled={loading}
                  className="form-control"
                  value={description}
                  placeholder="Description"
                  onChange={onHtmlChange}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="dialog-actions border-top">
          <div className="flex align-items-center">
            <span className="px10 spacer">
              <Button
                type="submit"
                size="medium"
                variant="contained"
                disabled={loading || meta.title === "" || description === ""}
                onClick={create_new}
              >
                {loading ? "Working..." : " Create Group "}
              </Button>
            </span>
          </div>
        </DialogActions>
      </Dialog>
      {toast.onopen && <CustomToast data={toast} />}
    </React.Fragment>
  );
};

export default CreateGroup;
