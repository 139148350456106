import React from "react";
const DatePipe = (props: any) => {
  const theDate = props.value;
  const show_time = props.show_time;
  let nowUnix = new Date();
  let refUnix = new Date(parseInt(theDate));
  ///
  var a = new Date(parseInt(theDate));
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const date = a.getDate();
  const hour = a.getHours();
  const min = a.getMinutes() < 10 ? "0" + a.getMinutes() : a.getMinutes();
  const ampm = hour >= 12 ? "pm" : "am";
  const time_str = a.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  ///
  let tma: string = "";
  if (
    nowUnix.getDate() === refUnix.getDate() &&
    refUnix.getMonth() === nowUnix.getMonth() &&
    refUnix.getFullYear() === nowUnix.getFullYear()
  ) {
    tma = time_str;
  } else if (
    refUnix.getFullYear() === nowUnix.getFullYear() &&
    refUnix.getMonth() !== nowUnix.getMonth()
  ) {
    tma = show_time ? month + " " + date + ", " + time_str : month + " " + date;
  } else if (
    refUnix.getFullYear() === nowUnix.getFullYear() &&
    refUnix.getMonth() === nowUnix.getMonth() &&
    nowUnix.getDate() !== refUnix.getDate()
  ) {
    tma = show_time ? month + " " + date + ", " + time_str : month + " " + date;
  } else if (refUnix.getFullYear() !== nowUnix.getFullYear()) {
    tma = month + " " + date + ", " + year;
  } else {
    tma = "00-00-0000";
  }

  return <React.Fragment>{tma}</React.Fragment>;
};

export default React.memo(DatePipe);
