import { Breadcrumbs, Card, Divider } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import EditPage from "./EditPage";
import AddMember from "../../templates/AddMember";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useFetchGroup from "../../../hooks/useFetchGroup";
import MemberListTemplate from "../../templates/MemberListTemplate";
const Nze = () => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleExpand =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const { group, loading_group, loaded_group } = useFetchGroup({
    slug: "nze",
  });
  return (
    <React.Fragment>
      <div className="account-page-container">
        <Card>
          <div className="page-topper">
            <div className="flex flex-row-resp">
              <div className="spacer">
                <Breadcrumbs>
                  <Link to="/admin">Dashboard</Link>
                  <Link to="/admin/about">About</Link>
                  <span>Ndi Nze</span>
                </Breadcrumbs>
                <h1>Ndi Nze</h1>
              </div>
              <span className="px20">-</span>
            </div>
          </div>
          <section className="page-detail-container">
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleExpand("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography component="div">
                  <h3>Ndi Nze List</h3>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="flex flex-col">
                  <div className="flex py5">
                    <span className="spacer"></span>
                    <span className="pl10">
                      <AddMember
                        platform="nze"
                        button_text="Add More Nze Members"
                      />
                    </span>
                  </div>
                  <Divider />
                  <section className="py20">
                    {loaded_group && (
                      <MemberListTemplate
                        loading={loading_group}
                        loaded={loaded_group}
                        members={group}
                        max_grid={3}
                        list_page="position"
                      />
                    )}
                  </section>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleExpand("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography component="div">
                  <h3>Introduction</h3>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <EditPage slug="nze" />
              </AccordionDetails>
            </Accordion>
          </section>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default Nze;
