import React from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import HttpService from "../../../services/HttpService";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CommentOutlined from "@mui/icons-material/CommentOutlined";
import ReplyAll from "@mui/icons-material/ReplyAll";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import DatePipe from "../../../pipes/DatePipe";
import PlaceHolder from "../../templates/PlaceHolder";
import * as processHtml from "../../../services/processHtml";
import AuthService from "../../../services/AuthService";
import Typography from "@mui/material/Typography";
import MessagesLayout from "./MessagesLayout";
interface ld {
  subject: string;
  id: number;
  pid: number;
  message: string;
  receiver: any;
  parent: any;
  replies: any[];
  created_at: string;
  updated_at: string;
}

export default function MessagesDetails() {
  const { decodeHtml, truncateWord } = processHtml;
  const usr = AuthService.getCurrentUser();
  let params = useParams();
  let ini: ld = {
    id: 0,
    pid: 0,
    subject: "",
    message: "",
    created_at: "",
    updated_at: "",
    receiver: {},
    parent: {},
    replies: [],
  };
  const [isParam, setParam] = React.useState(false);
  const [message, setMessage] = React.useState<any>({ replies: [] });
  const [loaded, setLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [show_reply, setShowReply] = React.useState(false);
  const [reply_modal_open, setReplyModalOpen] = React.useState(false);
  const navigate = useNavigate();
  const launchReplyModal = () => {
    return navigate(`/account/messages/r/${message.id}`);
  };
  const goHome = () => {
    return navigate(`/account/messages`);
  };
  const replyModalClose = (data: any = false) => {
    setReplyModalOpen(false);
    if (data) {
      message.replies.unshift(data);
    }
  };

  React.useEffect(() => {
    const isParam = params.mId ? true : false;
    setParam(isParam);
    if (isParam) {
      fetchDetails(params.mId);
    }
  }, []);

  const fetchDetails = (id: any) => {
    const endPoint = "message_details";
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader(endPoint, { mid: id })
      .then((response) => {
        console.log(response);
        if (response.status === 1) {
          setMessage(response);
          if (response.message_status === 0) {
            updateRead(response?.id);
          }
        }
      })
      .catch((error) => setError(error.message))
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  }; //fetChdetails;

  const updateRead = (id: any) => {
    const endPoint = "update_message_read";
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader(endPoint, { pid: id })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => console.log(error.message))
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  const top_reply = (data: any) => {
    let new_arr = [...message.replies];
    new_arr.push(data);
    setMessage({ ...message, replies: new_arr });
  };
  return (
    <MessagesLayout
      page_data={{
        is_param: true,
        id: message?.id,
        is_thread: true,
        title: message?.subject,
        parent: {
          id: message?.id,
          pid: message?.id,
          subject: message?.subject,
        },
        receiver: {
          id:
            message?.sender_id === usr.id
              ? message?.receiver_id
              : message?.sender_id,
          email:
            message?.sender_email === usr.email
              ? message?.receiver_email
              : message?.sender_email,
        },
        feed_back: top_reply,
      }}
    >
      <>
        <div className="pxy10">
          <div className="flex flex-row align-items-center px20 mb10">
            <span className="date-span">
              <i className="fas fa-user"></i> {message?.sender_name}
            </span>
            <span className="spacer text-right">
              <span className="date-span">
                <i className="fas fa-clock"></i>{" "}
                <DatePipe value={message?.send_date * 1000} />
              </span>
            </span>
          </div>
          <div className="main-message-content border-top">
            <div
              dangerouslySetInnerHTML={{
                __html: decodeHtml(message?.message),
              }}
            ></div>
          </div>
          <div className="px10">
            <Card sx={{ backgroundColor: "#fcfcfc" }}>
              <h3 className="pxy10">
                <CommentOutlined /> REPLIES [{message?.replies.length}]
              </h3>
              <Divider />
              <div className="mb10"></div>
              {message?.replies.map((item: any, index: number) => (
                <div className="mb0" key={index}>
                  <div
                    className={
                      item.me_send ? "me reply-content" : "they reply-content"
                    }
                  >
                    <span className="t-arrow"></span>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: decodeHtml(item?.message),
                      }}
                    ></p>
                    <div className="date-span">
                      <i className="fas fa-clock pr5"></i>
                      <DatePipe value={item.send_date * 1000} />
                    </div>
                  </div>
                  {/*  <Divider /> */}
                </div>
              ))}
            </Card>
          </div>
        </div>
      </>
    </MessagesLayout>
  );
}
