import React from "react";
import { Link, NavLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import EventShareTemplate from "./EventShareTemplate";
import DurationPipe from "../../pipes/DurationPipe";
import * as processHtml from "../../services/processHtml";
import {
  ArrowCircleDownOutlined,
  ArrowDownwardOutlined,
  ArrowDropDownCircleOutlined,
  CommentOutlined,
  Favorite,
  FavoriteBorderOutlined,
  Grid4x4,
  ListAltOutlined,
} from "@mui/icons-material";
import { Avatar, Card, Collapse, Tooltip } from "@mui/material";
import PlaceHolder from "./PlaceHolder";
import LikeTemplate from "./LikeTemplate";
import ShareTemplate from "./ShareTemplate";

const MemberListTemplate = (props: any) => {
  const { members, loading, loaded, max_grid, list_page } = props;
  const [list, setList] = React.useState<any[]>(members);
  const base_url = props.base_url ? "/" + props.base_url : "/";
  const { decodeHtml } = processHtml;
  const togView = (index: number, state: boolean) => {
    console.log(index, state);
    const mutd = [...list];
    const item = (mutd[index]["is_togged"] = !mutd[index]["is_togged"]);
    setList(mutd);
  };

  const ucfirst = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  return (
    <React.Fragment>
      <div className="">
        {loaded && Array.isArray(list) && (
          <>
            <Grid container spacing={2}>
              {/**<Grid item xs={12} sm={6} md={3} key={item.id}> */}
              {list.map((item: any, index: number) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={max_grid ? max_grid : 3}
                  key={item.id}
                >
                  <Card>
                    <div className={"news-card member-card"}>
                      <div className="thumb_pane">
                        <Link to={`${base_url}members/e/${item.id}`}>
                          <img
                            src={
                              item.is_thumb
                                ? process.env.REACT_APP_SERVER_ROOT + item.thumb
                                : "/images/icon.png"
                            }
                            alt={item.name}
                          />
                        </Link>
                        {!list_page && (
                          <span className="cat-span">
                            <Link
                              className="date-space spacer bolder"
                              to={`${base_url}kindreds/${item.kindred_slug}`}
                            >
                              {item.kindred_name}
                            </Link>
                          </span>
                        )}
                      </div>

                      <div className="news_content_space">
                        <h3>
                          <Link to={`${base_url}members/e/${item.id}`}>
                            <i className="fas txt-xsm fa-user-tie pr5"></i>{" "}
                            {item.name}
                          </Link>
                        </h3>
                        {list_page && list_page === "position" && (
                          <div className="date-span border-top mt10 pt5">
                            <i className="fas txt-xsm fa-flag pr5"></i>{" "}
                            <strong>{item.position}</strong> (
                            {`${item.start}-${item.end}`})
                          </div>
                        )}

                        {/*       <div className="flex flex-row align-items-center mt5">
                          <Link
                            className="date-space spacer bolder"
                            to={`${base_url}kindreds/${item.kindred_slug}`}
                          >
                            {item.kindred_name}
                          </Link>

                          <span className="txt-xsm"> {item.quarter_name}</span>
                        </div> 
                        <div className="news-summary">
                          <div
                            className="my5"
                            dangerouslySetInnerHTML={{
                              __html: decodeHtml(item.bio),
                            }}
                          ></div>
                          <Link to={`${base_url}members/e/${item.id}`}>
                            View Profile &raquo;
                          </Link>
                        </div>*/}
                        <Collapse in={item.is_togged}> </Collapse>
                      </div>
                      {!list_page && (
                        <div className="react-span px10 border-top">
                          <span className="spacer">
                            <IconButton>
                              <CommentOutlined />
                            </IconButton>
                            {item.comment_num}{" "}
                          </span>
                          <span className="pr5">
                            <LikeTemplate item={item} item_label={"profile"} />
                          </span>
                          <span>
                            <ShareTemplate item={item} item_label={"profile"} />
                          </span>
                        </div>
                      )}
                    </div>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        )}

        {loading && <PlaceHolder type="articles" />}
      </div>
    </React.Fragment>
  );
};

export default React.memo(MemberListTemplate);
