import React from "react";
import Header from "../../Header/Header";

import { Link } from "react-router-dom";
import Footer from "../../Footer/Footer";
import SeoModule from "../../../services/SeoModule";
import useFetchKindreds from "../../../hooks/useFetchKindreds";
const MembersLayout = (props: any) => {
  const { children, page_data } = props;
  const { kindreds, loading_kindreds, loaded_kindreds } = useFetchKindreds();

  let nave = [{ name: "All", slug: "" }, ...kindreds];
  console.log("nave::", nave);
  const [default_subject, setDefSub] = React.useState("");
  const [cur_tab, setTab] = React.useState<any>("");
  React.useEffect(() => {
    setDefSub(page_data?.title);
    setTab(page_data?.page_slug);
  }, [page_data]);
  console.log("page data::", page_data);

  return (
    <React.Fragment>
      <section className="py0 px20">
        {kindreds && (
          <div className="mb20">
            <ul className="category-tab">
              {nave.map((item, index) => (
                <li key={item.id + item.slug}>
                  <Link
                    to={`/account/members/${item.slug}`}
                    onClick={() => setTab(item.slug)}
                    className={
                      item.slug === page_data.page_slug ? "active" : ""
                    }
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className="p0">{children}</div>
      </section>
    </React.Fragment>
  );
};

export default MembersLayout;
