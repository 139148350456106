import React from "react";
import { DefaultEditor } from "react-simple-wysiwyg";
import Button from "@mui/material/Button";
import HttpService from "../../services/HttpService";
import * as processHtml from "../../services/processHtml";
import AuthService from "../../services/AuthService";
import CustomModal from "./CustomModal";
import PromptLogin from "./PromptLogin";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  Close,
  MailOutlined,
  MailOutlineOutlined,
  ReplyAll,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import FindUser from "./FindUser";
import CustomToast from "./CustomToast";

const SendMessage = (props: any) => {
  const { receiver, feed_back, parent, mode } = props;
  const prep_pid = (parent: any) => {
    if (!parent) return "0";
    if (parent?.pid && typeof parent?.pid !== "string") {
      return JSON.stringify(parent?.pid);
    }
    if (parent?.pid && typeof parent?.pid === "string") {
      return parent?.pid;
    } else {
      return "0";
    }
  };
  console.log("send_message props::", props);
  const cur_user = AuthService.getCurrentUser();

  const [user_logged, setUserLogged] = React.useState(false);
  React.useEffect(() => {
    if (cur_user) {
      setUserLogged(true);
    }
  }, [cur_user]);
  React.useEffect(() => {
    if (parent?.subject) {
      setMeta({ ...meta, subject: parent?.subject });
    }
  }, [parent?.subject]);

  const { decodeHtml, truncateWord } = processHtml;

  const onToastClose = () => {
    setToast({ onopen: false, onclose: onToastClose });
  };
  const [toast, setToast] = React.useState<any>({
    onopen: false,
    onclose: onToastClose,
    message: "",
  });
  const [receipient, setReceipient] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<any>("");
  const [meta, setMeta] = React.useState<any>({});
  const handleInputChange = React.useCallback(
    (event: any) => {
      const targ = event.target;
      const name = targ.name;
      const value = targ.value;
      console.log(name, value);
      setMeta({ ...meta, [name]: value });
      console.log(meta);
    },
    [meta]
  );

  React.useEffect(() => {
    if (meta.receipient) {
      setReceipient(meta.receipient);
    }
  }, [meta]);
  React.useEffect(() => {
    if (receiver) {
      setReceipient(receiver);
    }
  }, [receiver]);

  const searchReceiver = (event: any) => {};
  const onHtmlChange = (e: any) => {
    setMessage(e.target.value);
    console.log(message);
  };

  const handleSubmit = (e: any) => {
    console.log("meta::", meta);
    console.log("receipient::", receipient);
    console.log("receiver::", receiver);
    e.preventDefault();
    if (!message || message === "") {
      setToast({
        ...toast,
        onopen: true,
        onclose: onToastClose,
        severity: "error",
        message: "Please type your message..",
      });
      return;
    }
    const formData = new FormData();

    const xname = cur_user["name"];
    formData.append("sender_email", cur_user["email"]);
    formData.append("subject", meta.subject);
    formData.append("message", message);
    formData.append("receiver_id", receipient?.id);
    formData.append("pid", prep_pid(parent));
    formData.append("receiver_email", receipient?.email);
    //formData.append("receiver_email", meta.rever);
    console.log(receiver, prep_pid(parent), message);
    setLoading(true);
    setLoaded(false);

    HttpService.postFormHeader("send_message", formData)
      .then(
        (resp) => {
          console.log(resp);
          if (resp.status === 1) {
            togForm();
            setTimeout(() => {
              setToast({ ...toast, onopen: false });
              feed_back(resp.last_message);
            }, 3000);
          }
          setToast({ ...toast, onopen: true, message: resp.message });
        },
        (error) => {
          setToast({ ...toast, onopen: true, message: error.message });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //postTicket
  };

  const [show_form, setShow] = React.useState<boolean>(false);

  const togForm = (): void => {
    setShow(!show_form);
  };
  const add_receipient = (data: any) => {
    setMeta({ ...meta, receipient: data });
  };
  return (
    <React.Fragment>
      {user_logged ? (
        <>
          {mode === "reply" ? (
            <Tooltip title="Reply Message">
              <IconButton onClick={togForm}>
                <ReplyAll />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Send New Message">
              <IconButton onClick={togForm} color="primary">
                <MailOutlineOutlined />
              </IconButton>
            </Tooltip>
          )}
        </>
      ) : (
        <PromptLogin button_text="Add Comment" return_url="" />
      )}

      {cur_user && user_logged && show_form && (
        <Dialog open={show_form}>
          <div className="dialog-header">
            <div className="title-head">
              <MailOutlined />
              <span className="pl5">
                {mode === "reply" ? "Reply Message" : "Send Message"}
              </span>
            </div>
            <span className="spacer"></span>
            <Link onClick={togForm} to={`#`}>
              <Close />
            </Link>
          </div>
          <DialogContent sx={{ p: "0" }}>
            <div className={`alert-container`}>
              <div className="flex flex-col px20">
                {!receipient && (
                  <div className="px10 pb20 pt10">
                    <FindUser
                      feed_back={add_receipient}
                      class_style={{ width: "100%", minWidth: "350px" }}
                      button_text="Click to add receipient"
                      input_text_placeholder="Enter receipient name or email address"
                    />
                  </div>
                )}
                {receipient && (
                  <div className="mb10">
                    <div className={`input iconed`}>
                      <label>Receipient</label>
                      <input
                        type="text"
                        disabled={loading}
                        className="form-control"
                        defaultValue={receipient?.email}
                        name="rever"
                        onChange={handleInputChange}
                        placeholder={"Receipient"}
                      />
                    </div>
                    <div className={`input`}>
                      <label>Subject</label>
                      <input
                        type="text"
                        disabled={loading}
                        className="form-control"
                        defaultValue={meta.subject}
                        name="subject"
                        onChange={handleInputChange}
                        placeholder={"Subject"}
                      />
                    </div>
                    <div className="mb10">
                      <DefaultEditor
                        disabled={loading}
                        className="form-control"
                        value={message}
                        placeholder="Your Message"
                        onChange={onHtmlChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </DialogContent>
          {receipient && (
            <DialogActions className="dialog-actions">
              <div className="flex align-items-center">
                <span className="px10">
                  <Button
                    type="submit"
                    size="medium"
                    variant="contained"
                    disabled={loading}
                    onClick={handleSubmit}
                  >
                    {loading ? "Working..." : " Send Message "}
                  </Button>
                </span>
                <span className="spacer"></span>
                <span className="px20"></span>
              </div>
            </DialogActions>
          )}
        </Dialog>
      )}
      {toast.onopen && <CustomToast data={toast} />}
    </React.Fragment>
  );
};

export default React.memo(SendMessage);
