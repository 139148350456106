import React from "react";
import { Outlet, useLocation, useParams, NavLink } from "react-router-dom";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import MailOutline from "@mui/icons-material/MailOutline";
import PlaceHolder from "../../templates/PlaceHolder";
import HttpService from "../../../services/HttpService";
import DatePipe from "../../../pipes/DatePipe";
import Loading from "../../templates/Loading";
import MessagesLayout from "./MessagesLayout";
import AuthService from "../../../services/AuthService";
import {
  MarkEmailRead,
  MarkEmailReadOutlined,
  MarkEmailReadSharp,
  MarkEmailUnread,
} from "@mui/icons-material";

const MessagesList = (props: any) => {
  const usr = AuthService.getCurrentUser();
  let val = props.data;
  const [messages, setMessages] = React.useState<any[]>([]);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");
  let params = useParams();
  const location = useLocation();
  const pageName = location.pathname;
  const [isParam, setParam] = React.useState(false);
  const [ticket_modal_open, setNewModalOpen] = React.useState(false);
  const parts = location.pathname.split("/");
  const base = "/" + parts[1] + "/";
  React.useEffect(() => {
    const isParam = params.ticketId ? true : false;
    console.log("IS PARAM::: ", isParam);
    console.log("pageName::: ", pageName);
    setParam(isParam);
    if (
      (!isParam && pageName === "/account/messages") ||
      (!isParam && pageName === "/admin/messages")
    ) {
      doAjax();
    }
  }, [params]); //componentDidMount

  const doAjax = () => {
    setLoading(true);
    setLoaded(false);
    HttpService.getHeader("all_messages")
      .then(
        (result) => {
          console.log(result);
          if (Array.isArray(result.data)) {
            setMessages(result.data);
          }
        },
        (error) => {
          setError(error.message);
        }
      )
      .finally(() => {
        setLoaded(true);
        setLoading(false);
      }); //fetch
  }; //doAjax

  const launchNewModal = () => {
    setNewModalOpen(true);
  };

  const newModalClose = (data: any = false) => {
    setNewModalOpen(false);
    if (data) {
      messages.unshift(data);
    }
  };

  return (
    <React.Fragment>
      <MessagesLayout page_data={{ is_param: false, title: "All Messages" }}>
        {loaded && (
          <List
            sx={{
              p: "0",
              m: "0",
              // selected and (selected + hover) states
              "&& .Mui-selected, && .Mui-selected:hover": {
                bgcolor: "#000000",
                "&, & .MuiListItemIcon-root": {
                  color: "#ffffff",
                },
              },
              // hover states
              "& .MuiListItemButton-root:hover": {
                bgcolor: "#dedede",
                "&, & .MuiListItemIcon-root": {
                  color: "#000000",
                },
              },
            }}
          >
            {messages.map((item: any, index: number) => (
              <ListItem
                disablePadding
                button
                key={item.id}
                divider={true}
                component={NavLink}
                to={`${base}messages/p/${item.id}`}
                secondaryAction={
                  <span className="date-span">
                    <DatePipe
                      /*                  value={new Date(item.created_at).getTime() * 1000} */
                      value={
                        item.updated
                          ? item.updated * 1000
                          : item.send_date * 1000
                      }
                    />
                  </span>
                }
              >
                <ListItemButton sx={{ py: "4px" }}>
                  <ListItemIcon sx={{ minWidth: "20px", mr: "7px" }}>
                    {item.message_status === 0 ? (
                      <MarkEmailUnread
                        sx={{ fontSize: "20px", color: "#000000" }}
                      />
                    ) : (
                      <MarkEmailReadOutlined sx={{ fontSize: "20px" }} />
                    )}
                  </ListItemIcon>
                  <ListItemText>
                    <div className="flex align-items-center">
                      <Typography
                        sx={{
                          textOverflow: "ellipsis !Important",
                          width: "auto",
                          whiteSpace: "nowrap",
                          pr: "8px",
                          fontWeight:
                            item.message_status === 0 ? "boldest" : "",
                        }}
                        component={"em"}
                        className="dotted-underlined date-class "
                      >
                        {item?.sender_email === usr.email
                          ? item?.receiver_name
                          : item?.sender_name || "Annonymous"}
                      </Typography>

                      <Typography
                        sx={{
                          textOverflow: "ellipsis !Important",
                          width: "auto",
                          whiteSpace: "nowrap",
                          pr: "8px",
                          fontWeight:
                            item.message_status === 0 ? "900 !important" : "",
                        }}
                        component={"span"}
                      >
                        &raquo;
                      </Typography>

                      <Typography
                        sx={{
                          textOverflow: "ellipsis !Important",
                          width: "100%",
                          whiteSpace: "nowrap",
                          pr: "80px",
                          fontWeight:
                            item.message_status === 0 ? "900 !important" : "",
                        }}
                        component={"h2"}
                      >
                        {item.subject}
                      </Typography>
                    </div>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
        {loaded && messages.length === 0 && (
          <div className="result-error">
            <span>
              <i className="fas fa-exclamation-triangle"></i>
            </span>
            <h3> No messages found!</h3>
          </div>
        )}

        {loading && <Loading loading_text="Loading messages..." />}
      </MessagesLayout>
    </React.Fragment>
  );
};

export default MessagesList;
