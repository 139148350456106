import React from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import * as processHtml from "../../services/processHtml";
import { CommentOutlined } from "@mui/icons-material";
import { Card, Collapse, Tooltip } from "@mui/material";
import PlaceHolder from "./PlaceHolder";
import LikeTemplate from "./LikeTemplate";
import ShareTemplate from "./ShareTemplate";
import DatePipe from "../../pipes/DatePipe";
import AuthService from "../../services/AuthService";

const ArticlesListTemplate = (props: any) => {
  let usr = AuthService.getCurrentUser();
  const { articles, loading, loaded, max_grid, base_url } = props;
  const [list, setList] = React.useState<any[]>(articles);
  const base = props.base_url ? "/" + props.base_url : "/";
  const { decodeHtml } = processHtml;
  const togView = (index: number, state: boolean) => {
    console.log(index, state);
    const mutd = [...list];
    const item = (mutd[index]["is_togged"] = !mutd[index]["is_togged"]);
    setList(mutd);
  };

  const ucfirst = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  return (
    <React.Fragment>
      {loaded && (
        <>
          <Grid container spacing={2}>
            {list.map((item: any, index: number) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={max_grid ? max_grid : 3}
                key={item.id}
              >
                <Card>
                  <div className={"news-card"}>
                    <div className="thumb_pane">
                      <Link to={`${base}/articles/p/${item.url}`}>
                        {item.is_thumb && (
                          <img
                            src={process.env.REACT_APP_SERVER_ROOT + item.thumb}
                            alt={item.title}
                          />
                        )}
                      </Link>
                      <span className="cat-span">
                        <Link to={`${base}/articles/${item.category_url}`}>
                          {item.category_title}{" "}
                        </Link>
                      </span>
                      {(base_url === "account" || base_url === "admin") &&
                        usr?.id === item.author_id && (
                          <span className="edit-span">
                            <Tooltip title="Edit Article">
                            <Link to={`${base}/articles/e/${item.id}`}>
                              <span className="fas fa-edit"></span>
                            </Link></Tooltip>
                          </span>
                        )}
                    </div>

                    <div className="news_content_space">
                      <div className="flex flex-row align-items-center">
                        <div className="date-space spacer align-items-center">
                          <i className="fas txt-sm fa-calendar"></i>{" "}
                          <DatePipe value={item.post_date * 1000} />
                        </div>

                        <span className="texr">
                          {!item.is_togged ? (
                            <Tooltip title="View Article Theme">
                              <IconButton
                                size="small"
                                onClick={() => togView(index, true)}
                              >
                                <i className="fas fa-chevron-down"></i>
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Hide  Article Theme">
                              <IconButton
                                size="small"
                                onClick={() => togView(index, false)}
                              >
                                <i className="fas fa-chevron-up"></i>
                              </IconButton>
                            </Tooltip>
                          )}
                        </span>
                      </div>
                      <h3>
                        <Link to={`${base}/articles/p/${item.url}`}>
                          {item.title}
                        </Link>
                      </h3>
                      <Collapse in={item.is_togged}>
                        <div className="news-summary">
                          <div
                            className="my5"
                            dangerouslySetInnerHTML={{
                              __html: decodeHtml(item.content),
                            }}
                          ></div>
                          <Link
                            className="rdm"
                            to={`${base_url}/articles/p/${item.url}`}
                          >
                            Read More &raquo;
                          </Link>
                        </div>
                      </Collapse>
                    </div>

                    <div className="react-span px10">
                      <span className="spacer">
                        <Tooltip
                          arrow
                          placement="top-start"
                          title={`${item.comment_num} comments`}
                        >
                          <IconButton className="react-button">
                            <CommentOutlined className="react-icon" />
                          </IconButton>
                        </Tooltip>
                        <span className="count-span">{item.comment_num}</span>
                      </span>
                      <span className="pr5">
                        <LikeTemplate item={item} item_label={"article"} />
                      </span>
                      <span>
                        <ShareTemplate item={item} item_label={"article"} />
                      </span>
                    </div>
                  </div>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}

      {loading && <PlaceHolder type="articles" />}
    </React.Fragment>
  );
};

export default React.memo(ArticlesListTemplate);
