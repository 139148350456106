import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Breadcrumbs, Card, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { DefaultEditor } from "react-simple-wysiwyg";
import HttpService from "../../../services/HttpService";
import CustomModal from "../../templates/CustomModal";
import useFetchArticleCategories from "../../../hooks/useFetchArticleCategories";

const CreateArticle = () => {
  let navigate = useNavigate();
  let [loading, setLoading] = React.useState<any>(false);
  let [loaded, setLoaded] = React.useState<any>(false);
  let [article, setArticle] = React.useState<any>({});
  let [description, setContent] = React.useState<any>("");
  const closeOpts = () => setOptModal({ ...opts, onopen: false });
  const [opts, setOptModal] = React.useState<any>({
    onopen: false,
    onclose: closeOpts,
  });

  const handleInputChange = (e: any) => {
    const name = e.target.name;
    setArticle({ ...article, [name]: e.target.value });
    console.log(name, e.target.value);
  };

  const [file, setFile] = React.useState<any>(null);
  const [new_file, setNewFile] = React.useState<any>(0);
  const [preview_image, setPreview] = React.useState<any>(null);
  const handleFileChange = (e: any) => {
    const formData = new FormData();
    let fname = e.target.name;
    let flx = e.target.files[0];
    formData.append("file", flx);
    console.log(flx, formData);
    setFile(flx);
    console.log(flx);
    setPreview(URL.createObjectURL(flx)); // Would see a path?
    setNewFile(1);
  };
  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
    title: "New article",
  });
  const onHtmlChange = (e: any) => {
    setContent(e.target.value);
    console.log(description);
  };

  const handleSubmit = () => {
    console.log("SUBMITTING:: ", article);

    setLoading(true);
    setLoaded(false);

    const formData = new FormData();
    formData.append("page", "admin");
    formData.append("title", article?.title);
    formData.append("category_id", article?.category_id);
    formData.append("banner", file);
    formData.append("content", description);
    HttpService.postFormHeader("create_article", formData)
      .then((resp) => {
        console.log(resp);
        const severity =
          resp.status === 1
            ? "success"
            : resp.status === 0
            ? "error"
            : resp.status === 44
            ? "error"
            : "info";
        setModal({
          ...modal,
          onopen: true,
          message: resp.message,
          severity: severity,
        });
        if (resp.status === 1) {
          return navigate(`/admin/articles`);
        }
      })
      .catch((err) => {
        console.log(err);
        setModal({
          ...modal,
          onopen: true,
          severity: "error",
          message: err.message,
        });
      })
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  const {
    article_categories,
    loading_article_categories,
    loaded_article_categories,
  } = useFetchArticleCategories();

  return (
    <div className="account-page-container">
      <Card>
        <div className="page-topper">
          <Breadcrumbs>
            <Link to="/admin">Dashboard</Link>
            <Link to="/admin/articles">Articles</Link>
            <span>New article</span>
          </Breadcrumbs>
          <h1>New article</h1>
        </div>
        <section className="page-detail-container">
          <div className="py30 px20">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <div className={`input mb0 spacer ${loading ? "iconed" : ""}`}>
                  <label>Article Title</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="title"
                    onChange={handleInputChange}
                    placeholder={"Article Title "}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <div className={`input mb0 spacer`}>
                  <label className="textfeld-control">
                    {"Article Category"}
                    <sup
                      className="boldest red"
                      title="This field is required!"
                    >
                      *
                    </sup>
                  </label>
                  <select
                    className={" input-form-control "}
                    name={"category_id"}
                    required
                    placeholder="Article Category"
                    onChange={handleInputChange}
                  >
                    <option value="">Select Article Category...</option>
                    {article_categories.map((item) => (
                      <option value={item.id} key={item.title}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            </Grid>
            <div className="banner-section">
              {preview_image && (
                <div className="image_preview">
                  <img className="" src={preview_image} alt="preview Image" />
                </div>
              )}
              <div
                className={
                  loading
                    ? " input mb0 iconed mt20 pt10"
                    : " input mb0 mt20 pt10"
                }
              >
                <label>Attach article's Banner</label>
                <input
                  type="file"
                  className="form-control"
                  name="file"
                  onChange={handleFileChange}
                  placeholder={"picture"}
                />
              </div>
            </div>

            <div className="mb10">
              <DefaultEditor
                className="form-control"
                placeholder="Article's full content"
                value={description}
                onChange={onHtmlChange}
              />
            </div>
            <Button
              type="submit"
              size="large"
              variant="contained"
              disabled={loading}
              onClick={handleSubmit}
            >
              {loading ? "Working..." : " Add article"}
            </Button>
          </div>
        </section>
      </Card>
      {modal.onopen && <CustomModal data={modal} />}
    </div>
  );
};

export default CreateArticle;
