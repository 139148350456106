import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Contact from "./Contact/Contact";
import Home from "./Home/Home";
import Login from "./Login/Login";
import Register from "./Register/Register";
import FAQ from "./FAQ/FAQ";
import Privacy from "./Privacy/Privacy";
import About from "./About/About";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ResetPassword from "./ResetPassword/ResetPassword";
import VerifyRegistration from "./VerifyRegistration/VerifyRegistration";
import Terms from "./Terms/Terms";
import Four0Four from "./Four0Four/Four0Four";
import Events from "./Events/Events";
import Gallery from "./Gallery/Gallery";
import Meetings from "./Meetings/Meetings";
import Media from "./Media/Media";
import Kindreds from "./Kindreds/Kindreds";
import Live from "./Live/Live";
import "./Public.css";
import Members from "./Members/Members";
import EventsCalendar from "./Calendar";
import Articles from "./Articles/Articles";
import Executives from "./Resources/Executives";
import IchieEboani from "./Resources/IchieEboani";
import IchieDimechem from "./Resources/IchieDimechem";
import Nze from "./Resources/Nze";
import PastLeaders from "./Resources/PastLeaders";
import Committees from "./Resources/Committees";

const Public = () => {
  const page = useLocation()["pathname"];
  const pgarr = page.split("/");
  console.log("Page is::: ", page, "pgarr is: ", pgarr);
  return (
    <React.Fragment>
      <Routes>
        <Route path="" element={<Home />}></Route>
        <Route path="/" element={<Home />}></Route>
        <Route path="/calendar" element={<EventsCalendar />}></Route>
        <Route path="/live//*" element={<Live />}></Route>
        <Route path="/about//*" element={<About />}></Route>
        <Route path="/kindreds//*" element={<Kindreds />}></Route>
        <Route path="/about-us//*" element={<About />}></Route>
        <Route path="/meetings//*" element={<Meetings />}></Route>
        <Route path="/articles//*" element={<Articles />}></Route>
        <Route path="/members//*" element={<Members />}></Route>
        <Route path="/media//*" element={<Media />}></Route>
        <Route path="/gallery//*" element={<Gallery />}></Route>
        <Route path="/executives" element={<Executives />}></Route>
        <Route path="/ebo-ani" element={<IchieEboani />}></Route>
        <Route path="/ichie-dimechem" element={<IchieDimechem />}></Route>
        <Route path="/nze" element={<Nze />}></Route>
        <Route path="/past-leaders" element={<PastLeaders />}></Route>
        <Route path="/committees" element={<Committees />}></Route>

        <Route path="/contact-us" element={<Contact />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/forgot-password" element={<ForgotPassword />}></Route>
        <Route
          path="/reset-password/:id/:token"
          element={<ResetPassword />}
        ></Route>
        <Route
          path="/verify-registration/:id/:token"
          element={<VerifyRegistration />}
        ></Route>
        <Route path="/events//*" element={<Events />}></Route>
        <Route path="/faq" element={<FAQ />}></Route>
        <Route path="/privacy-policy" element={<Privacy />}></Route>
        <Route path="/terms" element={<Terms />}></Route>
        <Route
          path="*"
          element={
            <>
              <Four0Four />
            </>
          }
        />
      </Routes>
    </React.Fragment>
  );
};

export default Public;
