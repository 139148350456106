import React from "react";
import { Link, useParams } from "react-router-dom";
import { Breadcrumbs, Card, Grid, Stack, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { DefaultEditor } from "react-simple-wysiwyg";
import HttpService from "../../../services/HttpService";
import CustomModal from "../../templates/CustomModal";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import useFetchLevyCategories from "../../../hooks/useFetchLevyCategories";
import useFetchMemberGroups from "../../../hooks/useFetchMemberGroups";

const EditLevy = () => {
  const params = useParams();
  const isParam = params.mId ? true : false;
  let [loading, setLoading] = React.useState<any>(false);
  let [loaded, setLoaded] = React.useState<any>(false);
  let [levy, setLevy] = React.useState<any>({ designation: "0" });
  const { member_groups, loading_member_groups, loaded_member_groups } =
    useFetchMemberGroups();

  const [title, setTitle] = React.useState<any>("");
  const [amount, setAmount] = React.useState<any>("");
  const [due_date, setDueDate] = React.useState<any>("");
  const [description, setDesc] = React.useState<any>("");
  const [pay_group, setPayGroup] = React.useState<any>("");
  let [id, setId] = React.useState<any>(0);
  let [category_id, setCategoryId] = React.useState<any>("");

  React.useEffect(() => {
    getlevy(params.mId);
  }, [params?.mId]);

  const getlevy = (id: any) => {
    setLoading(true);
    setLoaded(false);
    HttpService.post({ id: id }, "levy_details")
      .then(
        (result) => {
          console.log(result);
          const rx = result.data;
          if (result.status === 1) {
            setLevy(result.data);
            setCategoryId(rx.category_id);
            setTitle(rx.title);
            setAmount(rx.amount);
            setPayGroup(rx.pay_group_id);
            setDueDate(new Date(rx.due_date * 1000).toUTCString());
            setDesc(rx.description);
            setId(rx.id);
            setPreview(process.env.REACT_APP_SERVER_ROOT + rx.picture);
          }
        },
        (error) => {
          console.log("error:::", error);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const { levy_categories, loading_levy_categories, loaded_levy_categories } =
    useFetchLevyCategories();

  const onHtmlChange = (e: any) => {
    setDesc(e.target.value);
  };

  const handleInputChange = React.useCallback(
    (e: any) => {
      console.log(e.target);
      const name = e.target.title;
      setLevy({ ...levy, [name]: e.target.value });
    },
    [levy]
  );

  const [file, setFile] = React.useState<any>(null);
  const [new_file, setNewFile] = React.useState<any>(0);
  const [preview_image, setPreview] = React.useState<any>(null);
  const handleFileChange = (e: any) => {
    const formData = new FormData();
    let fname = e.target.title;
    let flx = e.target.files[0];
    formData.append("file", flx);
    console.log(flx, formData);
    setFile(flx);
    console.log(flx);
    setPreview(URL.createObjectURL(flx)); // Would see a path?
    setNewFile(1);
  };
  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
    title: "New levy",
  });

  const handleSubmit = () => {
    console.log("Submitting...");
    setLoading(true);
    setLoaded(false);
    const formData = new FormData();
    formData.append("page", "admin");
    formData.append("id", id);
    formData.append("category_id", category_id);
    formData.append("pay_group", pay_group);
    formData.append("title", title);
    formData.append("amount", amount);
    formData.append("due_date", due_date);
    formData.append("banner", file);
    formData.append("description", description);
    console.log(formData);
    HttpService.postFormHeader("edit_levy", formData)
      .then((resp) => {
        console.log(resp);
        const severity =
          resp.status === 1
            ? "success"
            : resp.status === 0
            ? "error"
            : resp.status === 44
            ? "error"
            : "info";
        setModal({
          ...modal,
          onopen: true,
          message: resp.message,
          severity: severity,
        });
        if (resp.status === 1) {
        }
      })
      .catch((err) => {
        console.log(err);
        setModal({
          ...modal,
          onopen: true,
          severity: "error",
          message: err.message,
        });
      })
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };
  return (
    <div className="account-page-container">
      <Card>
        <div className="page-topper">
          <Breadcrumbs>
            <Link to="/admin">Dashboard</Link>
            <Link to="/admin/levies">Levies</Link>
            <Link to={`/admin/levies/e/${levy?.id}`}>{levy?.title}</Link>
          </Breadcrumbs>
          <h1>Edit {levy?.title}</h1>
        </div>
        <section className="page-detail-container">
          <div className="py30 px20">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <div
                  className={`input mb0 pr5 spacer ${loading ? "iconed" : ""}`}
                >
                  <label>Levy Title</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder={"Levy Title"}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer ${loading ? "iconed " : ""}`}>
                  <label>Levy Amount</label>
                  <input
                    type="number"
                    className="input-form-control"
                    name="amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder={"Levy Amount "}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <div
                  className={`input mb0 pr5 spacer ${loading ? "iconed" : ""}`}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3} sx={{ width: "100%" }}>
                      <DateTimePicker
                        label="Due Date"
                        value={due_date}
                        onChange={(e) => setDueDate(e?.toISOString())}
                        renderInput={(params: any) => (
                          <TextField className="flex spacer" {...params} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer`}>
                  <label className="textfeld-control">
                    {"Levy Category"}
                    <sup
                      className="boldest red"
                      title="This field is required!"
                    >
                      *
                    </sup>
                  </label>
                  <select
                    className={" input-form-control "}
                    name={"category_id"}
                    required
                    placeholder="Levy Category"
                    value={category_id}
                    onChange={(e) => setCategoryId(e.target.value)}
                  >
                    <option value="">Select Levy Category...</option>
                    {levy_categories.map((item) => (
                      <option value={item.id} key={item.title}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer`}>
                  <label className="textfeld-control">
                    {"Pay Group"}
                    <sup
                      className="boldest red"
                      title="This field is required!"
                    >
                      *
                    </sup>
                  </label>
                  <select
                    className={" input-form-control "}
                    name={"pay_group"}
                    required
                    placeholder="Pay Group"
                    value={pay_group}
                    onChange={(e) => setPayGroup(e.target.value)}
                  >
                    <option value="">Select Pay Group...</option>
                    {member_groups.map((item) => (
                      <option value={item.id} key={item.title}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                ==
              </Grid>
            </Grid>

            <div className="mb10">
              <DefaultEditor
                className="form-control"
                placeholder="levy's descriptiongraphy"
                value={description}
                onChange={onHtmlChange}
              />
            </div>
            <Button
              type="submit"
              size="large"
              variant="contained"
              disabled={loading}
              onClick={handleSubmit}
            >
              {loading ? "Working..." : " Modify Levy"}
            </Button>
          </div>
        </section>
      </Card>
      {modal.onopen && <CustomModal data={modal} />}
    </div>
  );
};

export default EditLevy;
