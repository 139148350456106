import React, { useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { DefaultEditor } from "react-simple-wysiwyg";
import Card from "@mui/material/Card";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import HttpService from "../../../services/HttpService";
import CustomModal from "../../templates/CustomModal";
import MessagesLayout from "./MessagesLayout";

const MessagesReply = (props: any) => {
  document.title = "Reva Bank - Dashboard - Reply Ticket";
  let params = useParams();
  const [isParam, setParam] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [reply_data, setData] = useState({
    id: "",
    pid: "",
    message: "",
    subject: "",
  });

  React.useEffect(() => {
    const isParam = params.mId ? true : false;
    setParam(isParam);
    if (isParam) {
      fetchDetails(params.mId);
    }
  }, []);

  const fetchDetails = (id: any) => {
    const endPoint = "message_details";
    HttpService.postHeader("message_details", { mid: id })
      .then(
        (response) => {
          console.log(response);
          setLoading(false);
          setData({
            ...reply_data,
            subject: response.subject,
            id: response.id,
            pid: response.id,
          });
          setLoaded(true);
        },
        (error) => {
          setError(error.message);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  }; //fetChdetails;

  const [response, setResponse] = useState({});
  const [error, setError] = useState("");

  const onToastClose = () => {
    setSnack({ onopen: false });
  };
  const [snack, setSnack] = useState<any>({
    onopen: false,
    onclose: onToastClose,
    message: "",
  });

  const handleInputChange = (event: any) => {
    const targ = event.target;
    const name = targ.name;
    const value = targ.value;
    setData({ ...reply_data, [name]: value });
    console.log(reply_data);
  };
  const onHtmlChange = (e: any) => {
    setMessage(e.target.value);
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log(reply_data);
    const obj: any = { ...reply_data, message: message };
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("send_message", { ...obj })
      .then(
        (resp) => {
          console.log(resp);
          setSnack({
            ...snack,
            onopen: true,
            message: resp.message,
            onclose: onToastClose,
          });
          if (resp.status === 1) {
            setTimeout(() => {
              setSnack({ ...snack, onopen: false, onclose: onToastClose });
              return navigate(`/account/messages/p/${params.mId}`);
            }, 3000);
          }
        },
        (error) => {
          setSnack({
            ...snack,
            onopen: true,
            message: error.message,
            onclose: onToastClose,
          });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //postTicket
  };

  const navigate = useNavigate();
  const goDetail = () => {
    return navigate(`/account/messages/p/${params.mId}`);
  };
  const goHome = () => {
    return navigate(`/account/ms`);
  };

  return (
    <MessagesLayout
      page_data={{
        is_param: true,
        is_compose: false,
        is_reply: true,
        id: reply_data?.id,
        title: reply_data?.subject,
      }}
    >
      <form onSubmit={handleSubmit}>
        <div style={{ padding: "20px" }}>
          <div className="relative">
            <TextField
              name="subject"
              value={"Re: " + reply_data.subject}
              onChange={handleInputChange}
              fullWidth
              sx={{ marginBottom: "4px" }}
              placeholder={"Subject"}
              multiline={false}
            />
          </div>

          <div className="relative py10">
            <DefaultEditor value={message} onChange={onHtmlChange} />
          </div>

          <Button type="submit" variant="contained">
            Send Reply
          </Button>
        </div>
      </form>

      <CustomModal data={snack} />
    </MessagesLayout>
  );
};

export default MessagesReply;
