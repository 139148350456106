import React from "react";
import { Link } from "react-router-dom";
import PlaceHolder from "../../templates/PlaceHolder";
import Button from "@mui/material/Button";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HttpService from "../../../services/HttpService";
import * as processHtml from "../../../services/processHtml";
import EventsListTemplate from "../../templates/EventsListTemplate";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import { Card } from "@mui/material";
import EventsLayout from "./EventsLayout";

const EventList = (props: any) => {
  const [offset, setOffset] = React.useState<number>(0);
  const [events, setEvent] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [page_data, setPageDate] = React.useState<any>({});

  React.useEffect(() => {
    setPageDate({
      ...page_data,
      page_slug: "",
      page_title: "All Events",
      page_summary: "All Dianaokwu  Events",
    });
    listEvent(offset);
  }, []);

  const listEvent = (offset: number) => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("all_events", {
      offset: offset,
      limit: 6,
      mode: "all",
    })
      .then(
        (result) => {
          console.log("list_e::", result);
          if (result.status === 1) {
            let newRes = [...events, ...result.data];
            setEvent(newRes);
          }
        },
        (error) => {
          console.log("err::", error);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const loadMore = () => {
    const newOffset = offset + 6;
    setOffset(newOffset);
    listEvent(newOffset);
  };

  return (
    <React.Fragment>
      <section className="account-page-container">
        <Card>
          <div className="page-topper">
            <Breadcrumbs>
              <Link to="/account">Dashboard</Link>
              <span>Events</span>
            </Breadcrumbs>
            <h1>All Events</h1>
          </div>

          <section className="py20">
            <EventsLayout page_data={page_data}>
              {loaded && (
                <EventsListTemplate
                  loading={loading}
                  loaded={loaded}
                  events={events}
                  max_grid={3}
                />
              )}
              {loading && <PlaceHolder type="articles" max_grid={3} />}
              <div className="flex justify-content-center align-items-center py20">
                <button disabled={loading} className="more" onClick={loadMore}>
                  {loading ? "Loading..." : "Load More"}
                </button>
              </div>
            </EventsLayout>
          </section>
        </Card>
      </section>
    </React.Fragment>
  );
};

export default EventList;
