import React from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import HttpService from "../../../services/HttpService";
import * as processHtml from "../../../services/processHtml";
import ArticlesListTemplate from "../../templates/ArticlesListTemplate";
import PlaceHolder from "../../templates/PlaceHolder";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Header from "../../Header/Header";
import PageIntro from "../PageIntro/PageIntro";
import SeoModule from "../../../services/SeoModule";
import { Button, LinearProgress } from "@mui/material";
import ArticlesLayout from "./ArticlesLayout";
const ArticleCategory = (props: any) => {
  const params = useParams();
  const [loading_articles, setLoading] = React.useState(true);
  const [offset, setOffset] = React.useState<number>(0);
  const [articles, setArticle] = React.useState<any>({ data: [] });
  const [list, setList] = React.useState<any[]>([]);
  const [loaded_articles, setLoaded] = React.useState<boolean>(false);
  const [page_data, setPageDate] = React.useState<any>({
    page_title: "",
    page_slug: "",
  });
  const [isParam, setParam] = React.useState(false);
  const [catUrl, setCatUrl] = React.useState<any>(null);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    const isParam = params.catUrl ? true : false;
    setParam(isParam);
    if (isParam) {
      setCatUrl(params.catUrl);
      listArticle(params.catUrl, 0);
    }
  }, [catUrl, params]);

  const listArticle = (cat_url: any, offset: number) => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("articles_by_category", {
      offset: offset,
      limit: 120,
      cat_url: cat_url,
    })
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          if (result.status === 1) {
            setPageDate({
              ...page_data,
              page_title: result.title,
              page_slug: result.slug,
              page_summary: result.title,
            });
            setArticle(result);
          } else {
          }
        },
        (error) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const loadMore = () => {
    const newOffset = offset + 20;
    setOffset(newOffset);
    listArticle(catUrl, newOffset);
  };

  return (
    <React.Fragment>
      <ArticlesLayout page_data={page_data}>
        {loaded_articles && (
          <ArticlesListTemplate
            loaded={loaded_articles}
            loading={loading_articles}
            articles={articles.data}
            max_grid={4}
          />
        )}
        {loading_articles && <PlaceHolder type="articles" max_grid={4} />}
        {loaded_articles && articles?.data?.length > 0 && (
          <div className="flex justify-content-center align-items-center py20">
            <button
              disabled={loading_articles}
              className="more"
              onClick={loadMore}
            >
              {loading_articles ? "Loading..." : "Load More"}
            </button>
          </div>
        )}
        {loaded_articles && articles?.data?.length === 0 && (
          <div className="empty-list">
            <span className="empty-icon">
              <i className="fas fa-exclamation-triangle"></i>{" "}
            </span>
            <span className="empty-text"> No Articles Found!</span>
          </div>
        )}
      </ArticlesLayout>
    </React.Fragment>
  );
};

export default ArticleCategory;
