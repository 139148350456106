import React from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "../../Footer/Footer";
import ArticleList from "./ArticleList";
import ArticleDetail from "./ArticleDetail";
import ArticleCategory from "./ArticleCategory";
import CreateArticle from "./CreateArticle";
import EditArticle from "./EditArticle";
const Articles = () => {
  return (
    <React.Fragment>
      <section className="bg-diff">
        <Routes>
          <Route path="" element={<ArticleList />} />
          <Route path="/" element={<ArticleList />} />
          <Route path="/p/:url" element={<ArticleDetail />} />
          <Route path="/e/:mId" element={<EditArticle />} />
          <Route path="/:catUrl" element={<ArticleCategory />} />
          <Route path="/new" element={<CreateArticle />} />
        </Routes>
      </section>
    </React.Fragment>
  );
};

export default Articles;
