import React from "react";
import { Link, useParams } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import CustomModal from "../../templates/CustomModal";
import DatePipe from "../../../pipes/DatePipe";
import Currency from "../../../pipes/Currency";
import {
  AttachFileOutlined,
  BrowseGalleryOutlined,
  CommentOutlined,
  Delete,
  DirectionsOutlined,
  Edit,
  Image,
  InfoOutlined,
  MoneyOutlined,
  StreamOutlined,
  TimerOutlined,
  VideoCameraFrontOutlined,
} from "@mui/icons-material";

import HttpService from "../../../services/HttpService";
import * as processHtml from "../../../services/processHtml";
import SeoModule from "../../../services/SeoModule";
import AuthService from "../../../services/AuthService";

import PlaceHolder from "../../templates/PlaceHolder";
import Header from "../../Header/Header";
import {
  Button,
  ButtonGroup,
  Dialog,
  IconButton,
  LinearProgress,
  Paper,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PostCommentTemplate from "../../templates/PostCommentTemplate";
import DurationPipe from "../../../pipes/DurationPipe";
import Comments from "../../templates/Comments";
import PopulateGallery from "../../templates/PopulateGallery";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const MeetingDetail = () => {
  let params: any = useParams();
  const { decodeHtml } = processHtml;
  const usr = AuthService.getCurrentUser();
  const cur_user = AuthService.getCurrentUser();
  const [meeting, setMeeting] = React.useState<any>({ related_meeting: [] });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<any>("");
  const [isParam, setParam] = React.useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    console.log("::params::", params);
    const isParam = params.url ? true : false;
    setParam(isParam);
    if (isParam) {
      fetchMeeting(params.url);
    }
  }, [params]);

  const fetchMeeting = (url: string) => {
    setLoading(true);
    setLoaded(false);
    HttpService.post({ id: url }, "meeting_details")
      .then(
        (result) => {
          console.log(result);
          if (result.status === 1) {
            setMeeting(result.data);
          } else {
            const now = new Date().getTime() / 1000;
            document.title = "Meeting not found";
            setMeeting({
              id: 1,
              title: "Meeting does not exist",
              content: `The event you are looking for does not exist. 
              It might not be published yet, deleted or you typed a wrong meeting url.`,
              create_date: now,
              related_meeting: [],
            });
          }
        },
        (error) => {
          setMeeting({ related_meeting: [] });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const onToastClose = () => {
    setToast({ onopen: false, onclose: onToastClose });
  };
  const [toast, setToast] = React.useState<any>({
    onopen: false,
    onclose: onToastClose,
    message: "",
  });

  const [meta, setMeta] = React.useState<any>({});
  const handleInputChange = React.useCallback(
    (event: any) => {
      const targ = event.target;
      const name = targ.name;
      const value = targ.value;
      console.log(name, value);
      setMeta({ ...meta, [name]: value });
      console.log(meta);
    },
    [meta]
  );

  const onHtmlChange = (e: any) => {
    setMessage(e.target.value);
    console.log(message);
  };

  const [value, setValue] = React.useState<any>(0);
  const theme = useTheme();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };
  const update_coms = (load: any) => {
    if (load) {
      let new_arr = [...meeting.comments];
      new_arr.unshift(load);
      setMeeting({ ...meeting, comments: new_arr });
    } else {
      console.log("Empty Com::", load);
    }
  };

  const goLink = (link: string) => {};
  const launchDel = (id: any) => {};

  const closeLiveData = () => setLiveData({ ...live_data, onopen: false });
  const [live_data, setLiveData] = React.useState<any>({
    onopen: false,
    onclose: closeLiveData,
  });
  const launchLive = () => {
    setLiveData({ ...live_data, onopen: true });
  };

  const sendLive = (roomId: string) => {
    setLoading(true);
    setLoaded(false);
    let stream_id = roomId;
    const formData = new FormData();
    formData.append("is_event", "0");
    formData.append("is_meeting", "1");
    formData.append("live_id", meeting?.id);
    formData.append("stream_id", stream_id);
    formData.append("event_id", "0");
    formData.append("meeting_id", meeting?.id);
    formData.append("category_id", "0");
    HttpService.postFormHeader("create_live", formData)
      .then(
        (res) => {
          console.log("local_saved_live: ", res);
        },
        (error) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  const startLive = () => {
    setLoading(true);
    setLoaded(false);
    const token = process.env.REACT_APP_VIDEOSDK_TOKEN;
    HttpService.startLive(token)
      .then(
        (res) => {
          console.log("remote live::", res);
          const { roomId } = res;
          sendLive(roomId);
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  const addFiles = () => {};
  return (
    <React.Fragment>
      <div className="account-page-container">
        <Card>
          <div className="page-topper">
            <Breadcrumbs>
              <Link to="/admin">Dashboard</Link>
              <Link to="/admin/meetings">Meetings</Link>
              <span>Meetings</span>
            </Breadcrumbs>
            <h1>{meeting?.title}</h1>
          </div>
          <section className="page-detail-container">
            <section className="container py20">
              <Card sx={{ borderRadius: "0" }}>
                {loaded && (
                  <Card sx={{ borderRadius: "0" }}>
                    <div className="page-head bga">
                      <div className="flex flex-row-resp align-items-center">
                        <div className="inline-block pxy20 spacer">
                          <Breadcrumbs
                            aria-label="breadcrumb"
                            sx={{
                              width: "100%",
                            }}
                          >
                            <Link to="/">Home</Link>
                            <Link to="/meetings">Meetings</Link>
                          </Breadcrumbs>
                          <h2 className="mt10">{meeting.title}</h2>
                          <div>
                            <i className="fas fa-timer"></i>
                            <DatePipe value={meeting.start_time * 1000} />
                          </div>
                        </div>

                        <span className="px20">
                          <ButtonGroup>
                            <Tooltip title="Edit Meeting">
                              <IconButton
                                onClick={() => goLink("edit")}
                                color="primary"
                              >
                                <Edit />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete Meeting">
                              <IconButton
                                onClick={() => launchDel("edit")}
                                color="error"
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Add Meeting files">
                              <IconButton onClick={addFiles} color="success">
                                <AttachFileOutlined />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Start Live Stream">
                              <IconButton onClick={launchLive} color="success">
                                <StreamOutlined />
                              </IconButton>
                            </Tooltip>
                            <PopulateGallery
                              item={{ id: meeting?.id, title: meeting?.title }}
                              is_meeting={true}
                              gallery_id={meeting?.gallery_id}
                            />
                          </ButtonGroup>
                        </span>
                      </div>
                    </div>

                    <div className="pxy0 bg-differ">
                      <div className=" pxy15">
                        <div className="relative">
                          <div className="highlighted-container">
                            <div className="highlight-banner">
                              <img
                                src={
                                  process.env.REACT_APP_SERVER_ROOT +
                                  meeting.picture
                                }
                                alt="Meeting banner"
                              />
                            </div>
                          </div>
                          <Divider />

                          <div className="highlight-details">
                            <div className="h2 flex align-items-center">
                              <i className="fas fa-map-marker-alt mr10"></i>
                              {` ${meeting.venue}`}
                            </div>
                            <div className="flex meta-span align-items-center">
                              <span>
                                <i className="fas fa-calendar"></i>{" "}
                                <DatePipe value={meeting.start_time * 1000} />
                              </span>

                              <span className="txt px5"></span>
                              <span className="spacer"></span>
                              <span className="">
                                <ul className="reakticons">
                                  <li>
                                    <Link to="#" onClick={() => null}>
                                      <i className="fas fa-thumbs-up"></i>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#" onClick={() => null}>
                                      <i className="fas fa-share-alt"></i>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#" onClick={() => null}>
                                      <i className="fas fa-comment"></i>
                                    </Link>
                                  </li>
                                </ul>
                              </span>
                            </div>
                          </div>
                          {/**/}
                        </div>
                      </div>

                      <Divider />
                      <Card sx={{ m: "0", p: "0" }}>
                        <Grid
                          container
                          rowSpacing={0}
                          columnSpacing={{ xs: 0, sm: 2, md: 2 }}
                          sx={{
                            display: "flex",
                            backgroundColor: "#fafafa",
                          }}
                        >
                          <Grid item xs={12} sm={6} md={4}>
                            <ListItem divider>
                              <ListItemIcon>
                                <MoneyOutlined sx={{ fontSize: "40px" }} />
                              </ListItemIcon>
                              <ListItemText>
                                <div className="bolder">
                                  Physical Attendance
                                </div>
                                <div className="">
                                  <Currency value={meeting.physical_price} />
                                </div>
                              </ListItemText>
                            </ListItem>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <ListItem divider>
                              <ListItemIcon>
                                <DirectionsOutlined sx={{ fontSize: "40px" }} />
                              </ListItemIcon>
                              <ListItemText>
                                <div className="bolder">Venue Directions</div>
                                <div>
                                  <a href="/">Open Map</a>
                                </div>
                              </ListItemText>
                            </ListItem>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <ListItem divider>
                              <ListItemIcon>
                                <TimerOutlined sx={{ fontSize: "40px" }} />
                              </ListItemIcon>
                              <ListItemText>
                                <div className="bolder">Meeting Duration</div>
                                <div>
                                  <DurationPipe
                                    start={meeting.start_time * 1000}
                                    end={meeting.end_time * 1000}
                                  />
                                </div>
                              </ListItemText>
                            </ListItem>
                          </Grid>
                        </Grid>
                        <Divider />

                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <Tabs
                            onChange={handleChange}
                            value={value}
                            aria-label="lab API tabs example"
                            variant="scrollable"
                            scrollButtons="auto"
                          >
                            <Tab
                              {...a11yProps(0)}
                              label={
                                <span className="flex align-items-center">
                                  <InfoOutlined />
                                  <h3 className="pl5 boldest">Minutes</h3>
                                </span>
                              }
                              wrapped
                            />
                            <Tab
                              {...a11yProps(1)}
                              label={
                                <span className="flex align-items-center">
                                  <i className="fas fa-bullhorn"></i>
                                  <h3 className="pl5 boldest">
                                    Correspondence
                                  </h3>
                                </span>
                              }
                            />
                            <Tab
                              {...a11yProps(2)}
                              label={
                                <span className="flex align-items-center">
                                  <BrowseGalleryOutlined />
                                  <h3 className="pl5 boldest">
                                    Photos & Videos
                                  </h3>
                                </span>
                              }
                            />
                            <Tab
                              {...a11yProps(3)}
                              label={
                                <span className="flex align-items-center">
                                  <CommentOutlined />
                                  <h3 className="pl5 boldest">
                                    <span className=" sm-hide-inline-block">
                                      Comments
                                    </span>{" "}
                                    ({meeting?.comments?.length})
                                  </h3>
                                </span>
                              }
                            />
                          </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: decodeHtml(meeting.description),
                            }}
                          ></div>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                          Item One
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                          Item Two
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                          <PostCommentTemplate
                            item={meeting}
                            update_coms={update_coms}
                            id={meeting?.id}
                            index={meeting?.id}
                            item_label={"meeting"}
                          />
                          <Comments
                            data={meeting?.comments}
                            platform={"detail_page"}
                          />
                        </TabPanel>
                      </Card>
                    </div>
                  </Card>
                )}
                {loading && <PlaceHolder type="event-detail" />}
              </Card>
            </section>
          </section>
        </Card>
      </div>

      {live_data.onopen && (
        <Dialog
          onClose={live_data.onclose}
          open={live_data.onopen}
          aria-labelledby={"Me"}
        >
          <div className="alert-container">
            <div className={`head-area info-bg bg-info`}>
              <i className={`fas fa-info-circle`}></i>
            </div>
            <div className={`message-area color-info text-center`}>
              {loading && (
                <div className="pxy10">
                  <LinearProgress />
                </div>
              )}
              <Button
                onClick={startLive}
                size="large"
                variant="contained"
                disabled={loading}
              >
                {loading ? "Starting Live..." : "START LIVE STREAM"}
              </Button>
            </div>
            <div className="button-area">
              <div className="flex align-items-center">
                <span className="spacer">
                  <Button
                    fullWidth
                    size="large"
                    onClick={live_data.onclose}
                    sx={{ paddingY: "13px", color: "orange" }}
                  >
                    <span className="px10">CLOSE</span>
                  </Button>
                </span>
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default MeetingDetail;
