import React from "react";
import { Link, useParams } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import CustomModal from "../../templates/CustomModal";

import HttpService from "../../../services/HttpService";
import * as processHtml from "../../../services/processHtml";
import SeoModule from "../../../services/SeoModule";
import AuthService from "../../../services/AuthService";

import PlaceHolder from "../../templates/PlaceHolder";
import Header from "../../Header/Header";
import { IconButton, Tooltip, Typography } from "@mui/material";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import { useTheme } from "@mui/material/styles";
import PostCommentTemplate from "../../templates/PostCommentTemplate";
import Comments from "../../templates/Comments";
import ShareTemplate from "../../templates/ShareTemplate";
import LikeTemplate from "../../templates/LikeTemplate";
import DatePipe from "../../../pipes/DatePipe";
import GalleryCarousel from "../../templates/GalleryCarousel";

//
const GalleryDetail = () => {
  let params: any = useParams();
  const { decodeHtml } = processHtml;
  const cur_user = AuthService.getCurrentUser();
  const [gallery, setGallery] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<any>("");
  const [isParam, setParam] = React.useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    console.log("::params::", params);
    const isParam = params.gId ? true : false;
    setParam(isParam);
    if (isParam) {
      fetchGallery(params.gId);
    }
  }, [params]);

  const fetchGallery = (id: string | number) => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("gallery_details", { id: id })
      .then(
        (result) => {
          console.log(result);
          if (result.status === 1) {
            setGallery(result.data);
          } else {
            const now = new Date().getTime() / 1000;
            document.title = "Gallery not found";
            setGallery({
              id: 1,
              title: "Gallery does not exist",
              content: `The gallery you are looking for does not exist. 
              It might not be published yet, deleted or you typed a wrong gallery url.`,
              create_time: now,
              related_gallery: [],
            });
          }
        },
        (error) => {
          setGallery({ related_gallery: [] });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const onToastClose = () => {
    setToast({ onopen: false, onclose: onToastClose });
  };
  const [toast, setToast] = React.useState<any>({
    onopen: false,
    onclose: onToastClose,
    message: "",
  });

  const [meta, setMeta] = React.useState<any>({});
  const handleInputChange = React.useCallback(
    (e: any) => {
      const targ = e.target;
      const name = targ.name;
      const value = targ.value;
      console.log(name, value);
      setMeta({ ...meta, [name]: value });
      console.log(meta);
    },
    [meta]
  );

  const onHtmlChange = (e: any) => {
    setMessage(e.target.value);
    console.log(message);
  };

  const [value, setValue] = React.useState<any>(0);
  const theme = useTheme();
  const handleChange = (e: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };
  const update_coms = (load: any) => {
    if (load) {
      let new_arr = [...gallery?.comments];
      new_arr.unshift(load);
      setGallery({ ...gallery, comments: new_arr });
    } else {
      console.log("Empty Com::", load);
    }
  };
  return (
    <React.Fragment>
      <SeoModule
        title={gallery?.title}
        description={gallery?.summary}
        name={"Dianaokwu Uka Oye"}
        page_image={process.env.REACT_APP_SERVER_ROOT + gallery?.photo}
        page_url={process.env.REACT_APP_SERVER_ROOT + gallery?.url}
        type="Gallery"
      />

      <section className="page-main">
        <div className="home-overlay"></div>
        <Header />
      </section>
      <section className="container py20">
        {loaded && (
          <Card sx={{ borderRadius: "0" }}>
            <div className="page-head bga">
              <div className="flex flex-row-resp align-items-center">
                <div className="inline-block pxy20 spacer">
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Link to="/">Home</Link>
                    <Link to="/gallery">Gallery</Link>
                    <Link to={`/gallery/${gallery?.category_url}`}>
                      {gallery?.category_title}
                    </Link>
                  </Breadcrumbs>
                  <h2 className="mt10">{gallery?.caption}</h2>
                  <span className="date-class">
                    <i className="fas fa-calendar pr5"></i>
                    <DatePipe value={gallery?.create_date * 1000} />
                  </span>
                </div>

                <span className="px20 sm-hide-inline">
                  <Avatar
                    sx={{ width: "50px", height: "50px" }}
                    variant="circular"
                    alt={`${gallery?.caption} `}
                    src={process.env.REACT_APP_SERVER_ROOT + gallery?.src}
                  />
                </span>
              </div>
            </div>

            <div className="gal-con">
              {loaded && <GalleryCarousel items={gallery?.photos} />}
            </div>
            <Divider />
            <Card sx={{ m: "0", p: "0", maxWidth: "100%" }}>
              <Grid
                container
                rowSpacing={0}
                columnSpacing={{ xs: 0, sm: 2, md: 2 }}
                sx={{
                  display: "flex",
                  backgroundColor: "#fafafa",
                }}
              ></Grid>
            </Card>

            <div className="gallery-details-cover">
              <div
                className="article-details-container mb20"
                dangerouslySetInnerHTML={{
                  __html: decodeHtml(gallery?.description),
                }}
              ></div>
              <div className="mb20">
                <Divider />
              </div>
              <PostCommentTemplate
                item={gallery}
                update_coms={update_coms}
                id={gallery?.id}
                index={gallery?.id}
                item_label={"gallery"}
              />
              <Comments data={gallery?.comments} platform={"detail_page"} />
            </div>
          </Card>
        )}
      </section>

      {toast.onopen && <CustomModal data={toast} />}
    </React.Fragment>
  );
};

export default GalleryDetail;
