import React from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import {
  Breadcrumbs,
  Card,
  Divider,
  Grid,
  Icon,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import Button from "@mui/material/Button";
import { DefaultEditor } from "react-simple-wysiwyg";
import HttpService from "../../../../services/HttpService";
import CustomModal from "../../../templates/CustomModal";
import useFetchPage from "../../../../hooks/useFetchPage";
import { Delete, Edit, Settings } from "@mui/icons-material";
import useFetchKindreds from "../../../../hooks/useFetchKindreds";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditPage from "../EditPage";

const ListKindreds = () => {
  let navigate = useNavigate();
  const params = useParams();
  console.log(params);
  const isParam = params.pageId ? true : false;
  let [loading, setLoading] = React.useState<any>(false);
  let [loaded, setLoaded] = React.useState<any>(false);
  const { page, loading_page, loaded_page } = useFetchPage({
    slug: "kindreds",
  });
  const { kindreds, loading_kindreds, loaded_kindreds } = useFetchKindreds();

  let [desc, setDesc] = React.useState("");
  let [title, setTitle] = React.useState("");
  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };

  React.useEffect(() => {
    setTitle(page.title);
    setDesc(page.description);
  }, [page]);
  const onHtmlChange = (e: any) => {
    setDesc(e.target.value);
  };

  const handleSubmit = () => {
    setLoading(true);
    setLoaded(false);
    console.log("SUBMITTING");
    HttpService.post(
      {
        title: title,
        description: desc,
        id: page?.id,
      },
      "edit_page"
    )
      .then((resp) => {
        console.log(resp);
        if (resp.status === 1) {
          setModal({
            ...modal,
            onopen: true,
            severity: "success",
            message: resp.message,
          });
        } else {
          setModal({
            ...modal,
            onopen: true,
            severity: "error",
            message: resp.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setModal({
          ...modal,
          onopen: true,
          severity: "error",
          message: err.message,
        });
      })
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  const togEdit = (i: any) => {
    return navigate(`/admin/about/kindreds/e/${i.id}`);
  };

  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
    title: "Edit event",
    loading_message: null,
    loading: false,
  });

  const closeDelModal = () => {
    setDelModal({ ...del_modal, onopen: false });
  };
  const [del_modal, setDelModal] = React.useState<any>({
    onopen: false,
    onclose: closeDelModal,
    title: "Delete event",
  });
  const launchDelete = (item: any) => {
    setDelModal({
      ...del_modal,
      id: item.id,
      loading_message: null,
      loading: false,
      message:
        "Are you sure you want to <strong><u>remove</u></strong> this event?",
      onopen: true,
      onclose: closeDelModal,
      onaccept: delete_event,
    });
  };
  const delete_event = (item: any) => {};

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleExpand =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div className="account-page-container">
      <Card>
        <div className="page-topper">
          <Breadcrumbs>
            <Link to="/admin">Dashboard</Link>
            <Link to="/admin/about">About</Link>
            <span>Kindreds</span>
          </Breadcrumbs>
          <h1> Kindreds</h1>
        </div>

        <section className="pxy20">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleExpand("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="div">
                <h3>Kindred List</h3>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="border-right border-top">
                <Grid container spacing={0}>
                  {kindreds.map((item) => (
                    <Grid item xs={12} sm={6} md={4} key={item.id}>
                      <div className="border-left">
                        <ListItem
                          divider
                          secondaryAction={
                            <span>
                              <Tooltip title="Edit event">
                                <IconButton onClick={() => togEdit(item)}>
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Remove event">
                                <IconButton
                                  color="warning"
                                  onClick={() => launchDelete(item)}
                                >
                                  <Delete />
                                </IconButton>
                              </Tooltip>
                            </span>
                          }
                        >
                          <ListItemIcon>
                            <Settings />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <h2 style={{ lineHeight: "1.2" }}>
                                <Link to={`/admin/about/kindreds/p/${item.id}`}>
                                  {item.name}
                                </Link>
                              </h2>
                            }
                          ></ListItemText>
                        </ListItem>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleExpand("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography component="div">
                <h3>Introduction</h3>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <section className="page-detail-container">
                <EditPage slug="kindreds" />
              </section>
            </AccordionDetails>
          </Accordion>
        </section>
      </Card>
      {modal.onopen && <CustomModal data={modal} />}
    </div>
  );
};

export default ListKindreds;
