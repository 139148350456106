import React from "react";
import { Link } from "react-router-dom";
import useFetchPage from "../../../hooks/useFetchPage";
import * as processHtml from "../../../services/processHtml";
import { Divider, LinearProgress } from "@mui/material";
import ResourcesLayout from "./ResourcesLayout";
import useFetchGroup from "../../../hooks/useFetchGroup";
import MemberListTemplate from "../../templates/MemberListTemplate";
import Loading from "../../templates/Loading";

const Committees = () => {
  const { decodeHtml, truncateWord } = processHtml;
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { page, loaded_page, loading_page } = useFetchPage({
    slug: "committees",
  });

  const { group, loading_group, loaded_group } = useFetchGroup({
    slug: "committees",
    has_members: true,
  });
  const [page_data, setPageDate] = React.useState<any>({});

  React.useEffect(() => {
    setPageDate({
      ...page_data,
      page_slug: "",
      page_title: "Committees",
      page_summary: "Dianaokwu  Committees",
    });
  }, []);
  return (
    <React.Fragment>
      <ResourcesLayout page_data={page_data}>
        {/*  <div className="bga pxy20">
          <h2>{page?.page_title || "Committees"}</h2>
        </div> */}
        <div className="pxy20">
          <h2>{page?.page_title || "Committees"}</h2>
          <div className="pt20">
            {loading_page && <Loading loading_text="Fetching committees..." />}
            {!loading_page && (
              <div
                className="pb20"
                dangerouslySetInnerHTML={{
                  __html: decodeHtml(page.description),
                }}
              ></div>
            )}

            <Divider />
            <section className="pt20">
              {loaded_group && (
                <MemberListTemplate
                  loading={loading_group}
                  loaded={loaded_group}
                  members={group}
                  max_grid={4}
                  list_page="position"
                />
              )}
            </section>
          </div>
        </div>
      </ResourcesLayout>
    </React.Fragment>
  );
};

export default Committees;
