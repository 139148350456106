import React from "react";
import HttpService from "../services/HttpService";

const useFetchGroup = (props: any) => {
  const {
    slug,
    group_id,
    has_members,
  }: { slug: string; group_id?: number | string; has_members?: boolean } =
    props;
  const [group, setGroup] = React.useState<any>(null);
  const [loading_group, setLoading] = React.useState(false);
  const [loaded_group, setLoaded] = React.useState(false);

  React.useEffect(() => {
    listgroup();
  }, []);

  const listgroup = () => {
    setLoading(true);
    setLoaded(false);
    const sub_id = has_members && has_members === true ? group_id : 0;
    HttpService.postHeader("get_grouped_listing", {
      slug: slug,
      group_id: sub_id,
      has_members: has_members,
    })
      .then(
        (result) => {
          console.log("group::", result);
          if (result.status === 1) {
            setGroup(result.data);
          }
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  return { group, loading_group, loaded_group };
};

export default useFetchGroup;
