import React from "react";
import { Link, useParams } from "react-router-dom";
import HttpService from "../../../services/HttpService";
import MemberListTemplate from "../../templates/MemberListTemplate";
import PlaceHolder from "../../templates/PlaceHolder";
import Card from "@mui/material/Card";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import MembersLayout from "./MembersLayout";

//

const MembersKindred = (props: any) => {
  const params = useParams();
  const [loading_members, setLoading] = React.useState(true);
  const [offset, setOffset] = React.useState<number>(0);
  const [members, setMembers] = React.useState<any>({ data: [] });
  const [list, setList] = React.useState<any[]>([]);
  const [loaded_members, setLoaded] = React.useState<boolean>(false);
  const [page_data, setPageDate] = React.useState<any>({
    page_title: "",
    page_slug: "",
  });
  const [isParam, setParam] = React.useState(false);
  const [kId, setKid] = React.useState<any>(null);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    const isParam = params.kId ? true : false;
    setParam(isParam);
    if (isParam) {
      setKid(params.kId);
      listMembers(params.kId, 0);
    }
  }, [kId, params]);

  const listMembers = (slug: any, offset: number) => {
    setLoading(true);
    setLoaded(false);
    const load = {
      offset: offset,
      limit: 120,
      slug: slug,
    };
    console.log(load);
    HttpService.postHeader("members_by_kindred", load)
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          if (result.status === 1) {
            setPageDate({
              ...page_data,
              page_title: result.name,
              page_slug: result.slug,
              page_summary: result.name,
            });
            setMembers(result);
          } else {
          }
        },
        (error) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const loadMore = () => {
    const newOffset = offset + 20;
    setOffset(newOffset);
    listMembers(kId, newOffset);
  };

  return (
    <React.Fragment>
      <section className="account-page-container">
        <Card>
          <div className="page-topper">
            <Breadcrumbs>
              <Link to="/account">Dashboard</Link>
              <Link to="/account/members">Members</Link>
              <span>Members</span>
            </Breadcrumbs>
            <h1>{page_data.page_title}</h1>
          </div>

          <section className="py20">
            <MembersLayout page_data={page_data}>
              {loaded_members && (
                <MemberListTemplate
                  loaded={loaded_members}
                  loading={loading_members}
                  members={members.data}
                  max_grid={3}
                />
              )}
              {loading_members && <PlaceHolder type="articles" />}
              {loaded_members && members?.data?.length > 0 && (
                <div className="flex justify-content-center align-items-center py20">
                  <button
                    disabled={loading_members}
                    className="more"
                    onClick={loadMore}
                  >
                    {loading_members ? "Loading..." : "Load More"}
                  </button>
                </div>
              )}
              {loaded_members && members?.data?.length === 0 && (
                <div className="empty-list">
                  <span className="empty-icon">
                    <i className="fas fa-exclamation-triangle"></i>{" "}
                  </span>
                  <span className="empty-text"> No Members Found!</span>
                </div>
              )}
            </MembersLayout>
          </section>
        </Card>
      </section>
    </React.Fragment>
  );
};

export default MembersKindred;
