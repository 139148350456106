import React from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { DefaultEditor } from "react-simple-wysiwyg";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import HttpService from "../../services/HttpService";
import * as processHtml from "../../services/processHtml";
import AuthService from "../../services/AuthService";
import CustomModal from "./CustomModal";
import PromptLogin from "./PromptLogin";
import { Divider } from "@mui/material";
import CustomToast from "./CustomToast";

const PostCommentTemplate = (props: any) => {
  const { item, update_coms, id, index, item_label } = props;
  console.log("props::", props);
  const cur_user = AuthService.getCurrentUser();

  const [user_logged, setUserLogged] = React.useState(false);
  React.useEffect(() => {
    if (cur_user) {
      setUserLogged(true);
    }
  }, [cur_user]);
  const [com_num, setNum] = React.useState(
    item.comments ? item.comments.length : 0
  );
  const base_url = props.base_url ? "/" + props.base_url : "";
  const { decodeHtml, truncateWord } = processHtml;

  const onToastClose = () => {
    setToast({ onopen: false, onclose: onToastClose });
  };
  const [toast, setToast] = React.useState<any>({
    onopen: false,
    onclose: onToastClose,
    message: "",
  });

  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<any>("");
  const [meta, setMeta] = React.useState<any>({});
  const handleInputChange = React.useCallback(
    (event: any) => {
      const targ = event.target;
      const name = targ.name;
      const value = targ.value;
      console.log(name, value);
      setMeta({ ...meta, [name]: value });
      console.log(meta);
    },
    [meta]
  );

  const onHtmlChange = (e: any) => {
    setMessage(e.target.value);
    console.log(message);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!message || message === "") {
      setToast({
        ...toast,
        onopen: true,
        onclose: onToastClose,
        severity: "error",
        message: "Please type your comment..",
      });
      return;
    }
    const formData = new FormData();
    if (!user_logged) {
      formData.append("email", meta.email);
      formData.append("name", meta.name);
    } else {
      const xname = cur_user["name"];
      formData.append("email", cur_user["email"]);
      formData.append("name", xname);
    }
    formData.append("comment", message);
    formData.append("item_id", id);
    formData.append("item_label", item_label);
    console.log(formData);
    setLoading(true);
    setLoaded(false);
    HttpService.postFormHeader("post_comment", formData)
      .then(
        (resp) => {
          console.log(resp);
          setToast({ ...toast, onopen: true, message: resp.message });
          if (resp.status === 1) {
            togComment();
            update_coms(resp.data, index);
            setTimeout(() => {
              setNum(com_num + 1);
              setMessage("");
              setToast({ ...toast, onopen: false });
            }, 3000);
          }
        },
        (error) => {
          setToast({ ...toast, onopen: true, message: error.message });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //postTicket
  };

  const [show_comment, setShow] = React.useState<boolean>(false);

  const togComment = (): void => {
    setShow(!show_comment);
  };

  return (
    <React.Fragment>
      <section className="article-comment">
        <>
          <div className="mb20 flex flex-row align-items-center">
            <h3>
              <i className="fas fa-comments"></i>
              <span className="sm-hide-inline"> Comments </span> ({com_num})
            </h3>
            <span className="spacer"></span>
            <span>
              {user_logged ? (
                <Button variant="contained" size="small" onClick={togComment}>
                  <i className="fa fa-edit pr5"></i>
                  <span className="sm-hide-inline">&nbsp;Add&nbsp;Comment</span>
                  &nbsp;
                  {show_comment ? (
                    <i className="fas fa-chevron-up"></i>
                  ) : (
                    <i className="fas fa-chevron-down"></i>
                  )}
                </Button>
              ) : (
                <PromptLogin button_text="Add Comment" return_url="" />
              )}
            </span>
          </div>
          {cur_user && user_logged && (
            <>
              <Collapse in={show_comment}>
                <div className={`comment-form`}>
                  <div className="mb10">
                    {!cur_user && (
                      <>
                        <div className={"input"}>
                          <label>Your name</label>
                          <input
                            type="text"
                            disabled={loading}
                            className="form-control"
                            name="name"
                            onChange={handleInputChange}
                            placeholder={"Your name "}
                          />
                        </div>
                        <div className={"input"}>
                          <label>Your Email Address</label>
                          <input
                            disabled={loading}
                            type="text"
                            className="form-control"
                            name="email"
                            onChange={handleInputChange}
                            placeholder={"Your email address "}
                          />
                        </div>
                      </>
                    )}

                    <div className="mb10">
                      <DefaultEditor
                        disabled={loading}
                        className="form-control"
                        value={message}
                        placeholder="Your Comment"
                        onChange={onHtmlChange}
                      />
                    </div>
                    <Button
                      type="submit"
                      size="small"
                      variant="contained"
                      disabled={loading}
                      onClick={handleSubmit}
                    >
                      {loading ? "Working..." : " Submit Comment "}
                    </Button>
                  </div>
                </div>
              </Collapse>
            </>
          )}
          {/**Comment-form ends */}
        </>
      </section>
      <CustomToast data={toast} />
    </React.Fragment>
  );
};

export default React.memo(PostCommentTemplate);
