import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import HttpService from "../../services/HttpService";
import CustomModal from "./CustomModal";

const TransitionUp = React.forwardRef(function Transition(
  props: any,
  ref: any
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditGalleryItem = (props: any) => {
  console.log(props);
  const { modal, type, caption, id, gallery_id, src, thumb } = props.data;
  const tmx = new Date().getTime();

  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [field_value, setValue] = React.useState<any>("");
  const setField = (e: any, name: string) => {
    setValue(e.target.value);
    console.log(field_value);
  };

  const [gallery, setGallery] = React.useState<any>(null);
  const [cap, setCap] = React.useState<string>("");
  React.useEffect(() => {
    if (props.data) {
      setGallery({ ...gallery, id: id, caption: caption });
      setCap(caption);
      setPreview(process.env.REACT_APP_PUBLIC_DOMAIN + src);
    }
  }, []);

  const handleInputChange = React.useCallback(
    (e: any) => {
      console.log(e.target);
      const name = e.target.name;
      setGallery({ ...gallery, [name]: e.target.value });
    },
    [gallery]
  );

  const [preview_image, setPreview] = React.useState<any>(null);
  const [file, setFile] = React.useState<any>(null);
  const [new_file, setNewFile] = React.useState<any>(0);

  const handleFileChange = (e: any) => {
    const formData = new FormData();
    let fname = e.target.name;
    let flx = e.target.files[0];
    formData.append("file", flx);
    console.log(flx, formData);
    setFile(flx);
    console.log(flx);
    setPreview(URL.createObjectURL(flx)); // Would see a path?
    setNewFile(1);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("caption", gallery.caption);
    formData.append("mode", "edit");
    formData.append("banner", file);
    formData.append("is_new_file", new_file);
    formData.append("id", id);
    formData.append("gallery_id", gallery_id);
    setLoading(true);
    setLoaded(false);
    setToast({
      ...toast,
      onopen: true,
      onclose: onToastClose,
      message: "Updating gallery. Please wait...",
    });
    HttpService.postFormHeader("edit_gallery_item", formData)
      .then(
        (resp) => {
          console.log(resp);
          setToast({
            ...toast,
            onopen: true,
            onclose: onToastClose,
            message: resp.message,
          });
          if (resp.status === 1) {
            setTimeout(() => {
              setToast({ ...toast, onopen: false });
            }, 3000);
          }
        },
        (error) => {
          setToast({ ...toast, onopen: true, message: error.message });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //postTicket
  };

  const onToastClose = () => {
    setToast({ onopen: false, onclose: onToastClose });
  };
  const [toast, setToast] = React.useState<any>({
    onopen: false,
    onclose: onToastClose,
    message: "",
  });

  return (
    <>
      <Dialog
        open={modal.onopen}
        aria-labelledby={"Me"}
        id={"md-" + tmx}
        TransitionComponent={TransitionUp}
      >
        <Toolbar
          sx={{ p: "0 10px 0 20px !important", minHeight: "60px !important" }}
        >
          <IconButton edge="start" color="inherit" aria-label="close">
            <InfoOutlined />
          </IconButton>
          <Typography sx={{ ml: "0px", flex: 1 }} variant="h6" component="div">
            {modal.title || "Info"}
          </Typography>
        </Toolbar>
        <DialogContent sx={{ p: "0 20px 20px 20px", m: "0" }}>
          <section className="modal-width">
            <div style={{ padding: "20px" }}>
              <div className={loading ? " input iconed " : " input "}>
                <label>Caption</label>
                <input
                  type="text"
                  className="input-form-control"
                  name="caption"
                  disabled={loading}
                  value={cap}
                  onChange={handleInputChange}
                  placeholder={"Caption "}
                />
                {loading && (
                  <span className="input-icon">
                    <i className="fas fa-refresh fa-spin"></i>
                  </span>
                )}
              </div>
              <input
                type="hidden"
                className="input-form-control"
                name="id"
                value={id}
                onChange={handleInputChange}
              />
              <input
                type="hidden"
                className="input-form-control"
                name="gallery_id"
                value={gallery_id}
                onChange={handleInputChange}
              />
              <input
                type="hidden"
                className="input-form-control"
                name="id"
                value={id}
                onChange={handleInputChange}
              />
              <div className="banner-section">
                {preview_image && (
                  <div className="image_preview">
                    <img className="" src={preview_image} alt="preview Image" />
                  </div>
                )}
                <div className={loading ? " input iconed " : " input "}>
                  <label>Change Banner Image</label>
                  <input
                    type="file"
                    className="input-form-control"
                    name="file"
                    onChange={handleFileChange}
                  />
                </div>
              </div>
              <Button
                type="submit"
                size="large"
                variant="contained"
                disabled={loading}
                onClick={handleSubmit}
              >
                {loading ? "Working..." : " Save "}
              </Button>
              {/**/}{" "}
            </div>
          </section>
        </DialogContent>

        <DialogActions sx={{ display: "flex", padding: "0 20px" }}>
          <Button onClick={modal.onclose} color="warning">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <CustomModal data={toast} />
    </>
  );
};
export default EditGalleryItem;
