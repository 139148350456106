import React from "react";
import { NavLink } from "react-router-dom";
import AuthService from "../../../services/AuthService";
import Grid from "@mui/material/Grid";
import {
  Article,
  BrowseGalleryOutlined,
  CabinOutlined,
  CardMembershipOutlined,
  Event,
  FolderCopyOutlined,
  HomeOutlined,
  InfoOutlined,
  Leaderboard,
  MeetingRoomOutlined,
  MessageSharp,
  MultipleStopOutlined,
  PaymentRounded,
  PaymentsOutlined,
  Settings,
  StopCircleOutlined,
  VerifiedUserTwoTone,
} from "@mui/icons-material";
import { Icon, ListItem, ListItemIcon, ListItemText } from "@mui/material";

const usr = AuthService.getCurrentUser();

const Dashboard = () => {
  const pages: any[] = [
    {
      path: "homepage",
      title: "Home Page",
      icon: <HomeOutlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "about",
      title: "About Dianokwu",
      icon: <InfoOutlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "gallery",
      title: "Gallery",
      icon: <BrowseGalleryOutlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "members",
      title: "Members",
      icon: <CardMembershipOutlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "meetings",
      title: "Meetings",
      icon: <MeetingRoomOutlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "events",
      title: "Events",
      icon: <Event />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "articles",
      title: "Articles",
      icon: <Article />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "levies",
      title: "Levies",
      icon: <PaymentRounded />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "penalties",
      title: "Penalties",
      icon: <StopCircleOutlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "non-indigenes",
      title: "Non Indigenes",
      icon: <VerifiedUserTwoTone />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "transactions",
      title: "Transactions",
      icon: <PaymentsOutlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "messages",
      title: "Messages",
      icon: <MessageSharp />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "cases",
      title: "Cases",
      icon: <FolderCopyOutlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "records",
      title: "Records",
      icon: <CabinOutlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },

    {
      path: "settings",
      title: "Site Settings",
      icon: <Settings />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
  ];

  return (
    <React.Fragment>
      <section className="dashboard-pane">
        <div className="z-high">
          <div className="home-get-grid z-high">
            <Grid container spacing={1}>
              {pages.map((item) => (
                <Grid item xs={12} sm={6} md={4} key={item.id}>
                  <div className="bga ">
                    <ListItem
                      className="grid-card"
                      component={NavLink}
                      to={`/admin/${item.path}`}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText
                        primary={
                          <h3 style={{ lineHeight: "1" }}>{item.title}</h3>
                        }
                        /*  secondary={<div style={{ lineHeight: "1" }}>---</div>} */
                      ></ListItemText>
                    </ListItem>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Dashboard;
