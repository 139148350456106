import React from "react";
import { Link, useNavigate } from "react-router-dom";
import PlaceHolder from "../../templates/PlaceHolder";
import Button from "@mui/material/Button";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HttpService from "../../../services/HttpService";
import { Card } from "@mui/material";
import MemberListTemplate from "../../templates/MemberListTemplate";
import MembersLayout from "./MembersLayout";
import FindUser from "../../templates/FindUser";
import { DataArrayTwoTone } from "@mui/icons-material";

const MembersList = (props: any) => {
  const [offset, setOffset] = React.useState<number>(0);
  const [members, setMembers] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [page_data, setPageDate] = React.useState<any>({});

  React.useEffect(() => {
    setPageDate({
      ...page_data,
      page_slug: "",
      page_title: "All Members",
      page_summary: "All Dianaokwu  Members",
    });
    listEvent(offset);
  }, []);

  const listEvent = (offset: number) => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("all_members", {
      offset: offset,
      limit: 24,
      mode: "all",
    })
      .then(
        (result) => {
          console.log(result);
          if (result.status === 1) {
            let newRes = [...members, ...result.data];
            setMembers(result.data);
          }
        },
        (error) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const loadMore = () => {
    const newOffset = offset + 20;
    setOffset(newOffset);
    listEvent(newOffset);
  };

  const [loading_search, setLoadingS] = React.useState<boolean>(false);
  const [loaded_search, setLoadedS] = React.useState<boolean>(false);

  const navigate = useNavigate();

  const showSearch = (data: any) => {
    console.log("selected:", data);
    if (data) {
      setLoadedS(true);
      return navigate(`/account/members/e/${data.id}`);
    }
  };

  return (
    <React.Fragment>
      <section className="account-page-container">
        <Card>
          <div className="page-topper">
            <div className="flex">
              <div className="spacer">
                <Breadcrumbs>
                  <Link to="/account">Dashboard</Link>
                  <span>Members</span>
                </Breadcrumbs>
                <h1>All Members</h1>
              </div>

              <span className="px20">
                <FindUser
                  feed_back={showSearch}
                  class_style={{
                    color: "#000000",
                    minWidth: "20px",
                    padding: "5px 6px",
                  }}
                />
              </span>
            </div>
          </div>

          <section className="py20">
            <MembersLayout page_data={page_data}>
              {loaded && (
                <MemberListTemplate
                  loading={loading}
                  loaded={loaded}
                  members={members}
                  max_grid={3}
                />
              )}
              {loading && <PlaceHolder type="articles" />}
              <div className="flex justify-content-center align-items-center py20">
                <button disabled={loading} className="more" onClick={loadMore}>
                  {loading ? "Loading..." : "Load More"}
                </button>
              </div>
            </MembersLayout>
          </section>
        </Card>
      </section>
    </React.Fragment>
  );
};

export default MembersList;
