import React from "react";
import { Link } from "react-router-dom";
import AuthService from "../../../services/AuthService";
import Grid from "@mui/material/Grid";
import {
  AccountBox,
  Article,
  CardMembershipOutlined,
  CategoryOutlined,
  ClearAllOutlined,
  CommentOutlined,
  DashboardOutlined,
  Event,
  FolderRounded,
  Group,
  GroupWorkSharp,
  ListOutlined,
  LiveTv,
  MailLockOutlined,
  MailOutlineOutlined,
  MeetingRoomOutlined,
  MessageSharp,
  Money,
  PageviewOutlined,
  PaymentsOutlined,
} from "@mui/icons-material";
import { Divider } from "@mui/material";
import HttpService from "../../../services/HttpService";
import { FolderIcon } from "@heroicons/react/outline";

const usr = AuthService.getCurrentUser();

const Dashboard = () => {
  const [clearance, setClearance] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    getclearance();
    console.log(usr);
  }, []);
  const getclearance = () => {
    setLoading(true);
    setLoaded(false);
    let dta = { uid: usr?.id };
    HttpService.postHeader("show_clearance", dta)
      .then(
        (res) => {
          console.log("clr::", res);
          if (res.status === 1) {
            setClearance(res.data);
          }
        },
        (err) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  const pages: any[] = [
    {
      path: "village-accounts",
      title: "Village Accounts",
      icon: <Money />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "clearance",
      title: "Clearance",
      icon: <ClearAllOutlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "events",
      title: "My Events",
      icon: <Event />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "articles",
      title: "My Articles",
      icon: <Article />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "transactions",
      title: "Transactions",
      icon: <PaymentsOutlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "messages",
      title: "Messages",
      icon: <MailOutlineOutlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "cases",
      title: "Cases",
      icon: <FolderRounded />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "non-indigenes",
      title: "Non Indigenes",
      icon: <Group />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "settings",
      title: "Account Settings",
      icon: <AccountBox />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
  ];

  return (
    <React.Fragment>
      <section className="dashboard-pane">
        {/*     <Grid item xs={12} sm={6} md={4} key={item.path}>
                  <Link className="grid-card g1" to={`/account/${item.path}`}>
                    <div className="icon-pane">
                      {item.icon}
                    </div>
                    <div className="pane-title">{item.title}</div>
                  </Link>
                </Grid> */}
        <div className="z-high">
          <div className="home-get-grid z-high">
            <Grid container spacing={1}>
              {pages.map((item) => (
                <Grid item xs={12} sm={6} md={4} key={item.path}>
                  <Link
                    className="grid-card bga flex flex-col"
                    to={`/account/${item.path}`}
                  >
                    <div className="flex flex-row align-items-center">
                      <span className="time-icon">{item.icon}</span>
                      <h2 className="spacer text-right ucap lh-1">
                        {item.title}
                      </h2>
                    </div>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Dashboard;
