import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import CustomModal from "../../templates/CustomModal";
import DurationPipe from "../../../pipes/DurationPipe";
import Currency from "../../../pipes/Currency";
import {
  CommentOutlined,
  Delete,
  DeleteOutlined,
  DirectionsOutlined,
  Edit,
  Image,
  InfoOutlined,
  MoneyOutlined,
  TimerOutlined,
  ToggleOff,
  ToggleOn,
  VideoCameraFrontOutlined,
} from "@mui/icons-material";

import HttpService from "../../../services/HttpService";
import * as processHtml from "../../../services/processHtml";
import SeoModule from "../../../services/SeoModule";
import AuthService from "../../../services/AuthService";

import PlaceHolder from "../../templates/PlaceHolder";
import Header from "../../Header/Header";
import {
  Button,
  ButtonGroup,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import { useTheme } from "@mui/material/styles";
import PostCommentTemplate from "../../templates/PostCommentTemplate";
import Comments from "../../templates/Comments";
import ShareTemplate from "../../templates/ShareTemplate";
import LikeTemplate from "../../templates/LikeTemplate";
import DatePipe from "../../../pipes/DatePipe";
import Loading from "../../templates/Loading";
import ConfirmModal from "../../templates/ConfirmModal";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}
const ArticleDetail = () => {
  let params: any = useParams();
  const { decodeHtml } = processHtml;
  const cur_user = AuthService.getCurrentUser();
  const [articles, setArticle] = React.useState<any>({ related_articles: [] });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<any>("");
  const [isParam, setParam] = React.useState(false);
  const [publish_mode, setPublish] = React.useState("publish");

  React.useEffect(() => {
    window.scrollTo(0, 0);
    console.log("::params::", params);
    const isParam = params.url ? true : false;
    setParam(isParam);
    if (isParam) {
      fetchArticle(params.url);
    }
  }, [params]);

  const fetchArticle = (url: string) => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("article_details", { url: url })
      .then(
        (result) => {
          console.log(result);
          if (result.status === 1) {
            setArticle(result.data);
            const pub_mode =
              result.data.is_published === 1 ? "unpublish" : "publish";
            console.log("[o]", pub_mode);
            setPublish(pub_mode);
          } else {
            const now = new Date().getTime() / 1000;
            document.title = "Article not found";
            setArticle({
              id: 1,
              title: "Article does not exist",
              content: `The article you are looking for does not exist. 
              It might not be published yet, deleted or you typed a wrong articles url.`,
              create_time: now,
              related_articles: [],
            });
          }
        },
        (error) => {
          setArticle({ related_articles: [] });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const onToastClose = () => {
    setToast({ onopen: false, onclose: onToastClose });
  };
  const [toast, setToast] = React.useState<any>({
    onopen: false,
    onclose: onToastClose,
    message: "",
  });

  const [meta, setMeta] = React.useState<any>({});
  const handleInputChange = React.useCallback(
    (article: any) => {
      const targ = article.target;
      const name = targ.name;
      const value = targ.value;
      console.log(name, value);
      setMeta({ ...meta, [name]: value });
      console.log(meta);
    },
    [meta]
  );

  const onHtmlChange = (e: any) => {
    setMessage(e.target.value);
    console.log(message);
  };

  const handleSubmit = (e: any) => {
    e.prarticleDefault();
    const formData = new FormData();
    if (!cur_user["isLogged"]) {
      formData.append("email", meta.email);
      formData.append("name", meta.name);
    } else {
      const xname = cur_user["firstname"] + " " + cur_user["surname"];
      formData.append("email", cur_user["email"]);
      formData.append("name", xname);
    }
    formData.append("comment", message);
    formData.append("table", "articles");
    formData.append("article_id", articles.id);
    formData.append("mode", "save-comment");
    console.log(formData);
    setLoading(true);
    setLoaded(false);
    HttpService.postFormHeader("post_comment", formData)
      .then(
        (resp) => {
          console.log(resp);
          setToast({ ...toast, onopen: true, message: resp.message });
          if (resp.status === 1) {
            setTimeout(() => {
              setToast({ ...toast, onopen: false });
            }, 3000);
          }
        },
        (error) => {
          setToast({ ...toast, onopen: true, message: error.message });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //postTicket
  };

  const [show_comment, setShow] = React.useState<boolean>(false);

  const togComment = (): void => {
    setShow(!show_comment);
  };

  const closeBook = () => {
    setBook({ ...book, onopen: false });
  };
  const [book, setBook] = React.useState<any>({
    onopen: false,
    onclose: closeBook,
    message: "",
  });

  const goLink = (link: string) => {};
  const launchDel = (id: any) => {};

  const navigate = useNavigate();

  const launchEdit = () => {
    return navigate(`/admin/articles/e/${articles.id}`);
  };

  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
    title: "Article",
  });

  const doPublish = (data: any) => {
    setLoading(true);
    setLoaded(false);
    setConfirm({
      ...confirm,
      onopen: true,
      id: data.id,
      title: `${data.action} article`,
      message: `${data.action}ing...`,
      onclose: closeConfirm,
    });
    HttpService.postHeader("publish_article", data)
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          setConfirm({
            ...confirm,
            onopen: true,
            message: result.message,
            onclose: closeConfirm,
          });
          if (result.status === 1) {
            setArticle({ ...articles, is_published: result.col });
            const pub_mode = result.col === 1 ? "unpublish" : "publish";
            console.log("[o]", pub_mode);
            setPublish(pub_mode);
          }
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  };
  const closeConfirm = () => {
    setConfirm({ ...confirm, onopen: false });
  };
  const [confirm, setConfirm] = React.useState<any>({
    onopen: false,
    onaccept: doPublish,
    onclose: closeConfirm,
    message: "",
    title: "",
    id: "",
    action: "",
  });
  const launchAction = (data: any) => {
    setConfirm({
      ...confirm,
      onopen: true,
      id: data.id,
      action: data.action,
      title: data.action + " article",
      message: `Are you sure you want to ${data.action} this article?`,
    });
  };

  const update_coms = (load: any) => {
    if (load) {
      let new_arr = [...articles.comments];
      new_arr.unshift(load);
      setArticle({ ...articles, comments: new_arr });
    } else {
      console.log("Empty Com::", load);
    }
  };
  return (
    <React.Fragment>
      <section className="container py20">
        <Card sx={{ borderRadius: "0" }}>
          {loaded && (
            <Card sx={{ borderRadius: "0" }}>
              <div className="page-head bga">
                <div className="flex flex-row-resp align-items-center">
                  <div className="inline-block pxy20 spacer">
                    <Breadcrumbs
                      aria-label="breadcrumb"
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Link to="/">Home</Link>
                      <Link to="/admin/articles">Articles</Link>
                      <Link to={`/admin/articles/${articles.category_url}`}>
                        {articles.category_title}
                      </Link>
                    </Breadcrumbs>
                    <h2 className="mt10">{articles.title}</h2>
                    <span className="date-class">
                      <i className="fas fa-calendar pr5"></i>
                      <DatePipe value={articles.post_date * 1000} />
                    </span>
                  </div>

                  <span className="pxy20">
                    <ButtonGroup>
                      <Tooltip title="Edit Article">
                        <IconButton onClick={launchEdit} color="primary">
                          <Edit />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={`${publish_mode} Article`}>
                        <IconButton
                          size="medium"
                          color={
                            publish_mode === "unpublish" ? "warning" : "primary"
                          }
                          onClick={() =>
                            launchAction({
                              id: articles?.id,
                              action: publish_mode,
                            })
                          }
                        >
                          {publish_mode === "unpublish" ? (
                            <ToggleOff />
                          ) : (
                            <ToggleOn />
                          )}
                        </IconButton>
                      </Tooltip>

                      <Tooltip title={`Delete article`}>
                        <IconButton
                          size="small"
                          color="error"
                          onClick={() =>
                            launchAction({ id: articles?.id, action: "delete" })
                          }
                        >
                          <DeleteOutlined />
                        </IconButton>
                      </Tooltip>
                    </ButtonGroup>
                  </span>
                </div>
              </div>

              <div className="pxy0 bg-differ">
                <div className=" pxy15">
                  <div className="relative">
                    <div className="highlighted-container">
                      <div className="highlight-banner">
                        <img
                          src={
                            process.env.REACT_APP_SERVER_ROOT + articles.picture
                          }
                          alt="articles banner"
                        />
                      </div>
                    </div>
                    <Divider />
                    <div className="highlight-details">
                      <div className="h2 flex align-items-center">
                        <i className="fas fa-map-marker-alt mr5"></i>
                        {articles.location}
                      </div>
                      <div className="flex meta-span align-items-center">
                        <span className="spacer">
                          <Tooltip
                            arrow
                            placement="top-start"
                            title={`${articles.comment_num} comments`}
                          >
                            <IconButton className="react-button">
                              <CommentOutlined
                                className="react-icon"
                                sx={{ color: "#fff" }}
                              />
                            </IconButton>
                          </Tooltip>
                          <span className="count-span white-color">
                            {articles.comment_num}
                          </span>
                        </span>

                        <span className="txt px5"></span>
                        <span className="spacer"></span>
                        <span className="flex align-items-center">
                          <span>
                            <LikeTemplate
                              item={articles}
                              item_label={"article"}
                              is_white={true}
                            />
                          </span>
                          <span>
                            <ShareTemplate
                              item={articles}
                              item_label={"article"}
                              is_white={true}
                            />
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <Divider />
                <div className="pxy20">
                  <div
                    className="article-details-container mb20"
                    dangerouslySetInnerHTML={{
                      __html: decodeHtml(articles.content),
                    }}
                  ></div>
                  <Divider />
                  <div className="mt20">
                    <PostCommentTemplate
                      item={articles}
                      update_coms={update_coms}
                      id={articles?.id}
                      index={articles?.id}
                      item_label={"article"}
                    />
                    <Comments
                      data={articles?.comments}
                      platform={"detail_page"}
                    />
                  </div>
                </div>
              </div>
            </Card>
          )}
          {loading && <Loading loading_text="Loading Article" />}
        </Card>
      </section>
      {confirm.onopen && <ConfirmModal data={confirm} />}
      {toast.onopen && <CustomModal data={toast} />}
    </React.Fragment>
  );
};

export default ArticleDetail;
