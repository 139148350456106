import React from "react";
import { Link, useParams } from "react-router-dom";
import { Breadcrumbs, Card, Grid, Stack, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { DefaultEditor } from "react-simple-wysiwyg";
import HttpService from "../../../services/HttpService";
import CustomModal from "../../templates/CustomModal";
import useFetchArticleCategories from "../../../hooks/useFetchArticleCategories";

const EditArticle = () => {
  const params = useParams();
  const isParam = params.mId ? true : false;
  let [loading, setLoading] = React.useState<any>(false);
  let [loaded, setLoaded] = React.useState<any>(false);
  let [article, setArticle] = React.useState<any>({ designation: "0" });

  const [title, setTitle] = React.useState<any>("");
  const [description, setDesc] = React.useState<any>("");
  let [id, setId] = React.useState<any>(0);
  let [category_id, setCategoryId] = React.useState<any>("");

  React.useEffect(() => {
    getarticle(params.mId);
  }, [params?.mId]);

  const getarticle = (id: any) => {
    setLoading(true);
    setLoaded(false);
    HttpService.post({ id: id }, "article_details")
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          if (result.status === 1) {
            setArticle(result.data);
            setCategoryId(result.data.category_id);
            setTitle(result.data.title);
            setDesc(result.data.content);
            setId(result.data.id);
            setPreview(process.env.REACT_APP_SERVER_ROOT + result.data.picture);
          } else {
            setArticle({});
          }
        },
        (error) => {
          setArticle({});
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const {
    article_categories,
    loading_article_categories,
    loaded_article_categories,
  } = useFetchArticleCategories();

  const onHtmlChange = (e: any) => {
    setDesc(e.target.value);
  };

  const handleInputChange = React.useCallback(
    (e: any) => {
      console.log(e.target);
      const name = e.target.title;
      setArticle({ ...article, [name]: e.target.value });
    },
    [article]
  );

  const [file, setFile] = React.useState<any>(null);
  const [new_file, setNewFile] = React.useState<any>(0);
  const [preview_image, setPreview] = React.useState<any>(null);
  const handleFileChange = (e: any) => {
    const formData = new FormData();
    let fname = e.target.title;
    let flx = e.target.files[0];
    formData.append("file", flx);
    console.log(flx, formData);
    setFile(flx);
    console.log(flx);
    setPreview(URL.createObjectURL(flx)); // Would see a path?
    setNewFile(1);
  };
  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
    title: "Edit article",
  });

  const handleSubmit = () => {
    console.log("SUBMITTING");
    setLoading(true);
    setLoaded(false);

    const formData = new FormData();
    formData.append("page", "admin");
    formData.append("id", id);
    formData.append("category_id", category_id);
    formData.append("title", title);
    formData.append("banner", file);
    formData.append("content", description);
    console.log(formData);
    HttpService.postFormHeader("edit_article", formData)
      .then((resp) => {
        console.log(resp);
        const severity =
          resp.status === 1
            ? "success"
            : resp.status === 0
            ? "error"
            : resp.status === 44
            ? "error"
            : "info";
        setModal({
          ...modal,
          onopen: true,
          message: resp.message,
          severity: severity,
        });
        if (resp.status === 1) {
        }
      })
      .catch((err) => {
        console.log(err);
        setModal({
          ...modal,
          onopen: true,
          severity: "error",
          message: err.message,
        });
      })
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };
  return (
    <div className="account-page-container">
      <Card>
        <div className="page-topper">
          <Breadcrumbs>
            <Link to="/admin">Dashboard</Link>
            <Link to="/admin/articles">Articles</Link>
            <Link to={`/admin/articles/p/${article?.url}`}>
              {article?.title}
            </Link>
          </Breadcrumbs>
          <h1>Edit {article?.title}</h1>
        </div>
        <section className="page-detail-container">
          <div className="py30 px20">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <div className={`input mb0 spacer ${loading ? "iconed" : ""}`}>
                  <label>Article Title</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder={"Article Title"}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <div className={`input mb0 spacer`}>
                  <label className="textfeld-control">
                    {"Article Category"}
                    <sup
                      className="boldest red"
                      title="This field is required!"
                    >
                      *
                    </sup>
                  </label>
                  <select
                    className={" input-form-control "}
                    name={"category_id"}
                    required
                    placeholder="Article Category"
                    value={category_id}
                    onChange={(e) => setCategoryId(e.target.value)}
                  >
                    <option value="">Select Article Category...</option>
                    {article_categories.map((item) => (
                      <option value={item.id} key={item.title}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            </Grid>

            <div className="banner-section">
              {preview_image && (
                <div className="image_preview">
                  <img className="" src={preview_image} alt="preview Image" />
                </div>
              )}
              <div
                className={
                  loading
                    ? " input mb0 iconed mt20 pt10"
                    : " input mb0 mt20 pt10"
                }
              >
                <label>Attach article's Banner</label>
                <input
                  type="file"
                  className="form-control"
                  name="file"
                  onChange={handleFileChange}
                  placeholder={"picture"}
                />
              </div>
            </div>

            <div className="mb10">
              <DefaultEditor
                className="form-control"
                placeholder="article's content"
                value={description}
                onChange={onHtmlChange}
              />
            </div>
            <Button
              type="submit"
              size="large"
              variant="contained"
              disabled={loading}
              onClick={handleSubmit}
            >
              {loading ? "Working..." : " Edit article"}
            </Button>
          </div>
        </section>
      </Card>
      {modal.onopen && <CustomModal data={modal} />}
    </div>
  );
};

export default EditArticle;
